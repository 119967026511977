import { Skeleton } from "../Skeleton";

const ContinueLearningSkeleton = () => {
  return (
    <div className="flex flex-col px-4 md:px-9 lg:px-20 gap-4 lg:gap-6">
      <Skeleton className="h-[35px] w-full max-w-[320px] md:max-w-[400px] rounded-2xl bg-brown-100" />
      <div className="flex gap-6">
        <Skeleton className="h-[425px] w-full max-w-[398px] md:h-[520px] md:max-w-[576px] lg:h-[540px] lg:max-w-[628px] rounded-2xl bg-brown-100 shrink-0" />
        <div className="flex flex-col gap-y-[49px]">
          <div className="flex gap-6">
            {[...Array(3)].map((_, index) => (
              <Skeleton
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={index}
                className="h-[233px] w-[302px] rounded-2xl bg-brown-100"
              />
            ))}
          </div>
          <div className="lg:flex flex-col gap-3 hidden">
            <Skeleton className="h-[28px] w-[320px] rounded-2xl bg-brown-100" />
            <div className="flex flex-col gap-1">
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={index}
                  className="h-[16px] w-[628px] rounded-2xl bg-brown-100"
                />
              ))}
            </div>
            <Skeleton className="h-[35px] w-[200px] rounded-2xl bg-brown-100" />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 lg:hidden">
        <Skeleton className="h-[28px] w-full max-w-[320px] rounded-2xl bg-brown-100" />
        <div className="flex flex-col gap-1">
          {[...Array(3)].map((_, index) => (
            <Skeleton
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              className="h-[16px] w-full md:w-[696px] rounded-2xl bg-brown-100"
            />
          ))}
        </div>
        <Skeleton className="h-[35px] w-full md:w-[200px] rounded-2xl bg-brown-100" />
      </div>
    </div>
  );
};
export default ContinueLearningSkeleton;
