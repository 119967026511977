import { Tooltip } from "components/Tooltip";
import Icons from "components/icons/Icons";
import useQuestConsumption from "hooks/useQuestConsumption";
import { cn } from "libs/classMerger";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { QuestLandingPage } from "tabs/Quest/QuestLanding/QuestLandingPage";
import type { ICategoryNode, IQuest } from "types/interfaces";
import { getFormattedDate } from "utils/getFormattedDate";

export const QuestHoverCard = ({
  questData,
  category,
  questAssignmentEndDate,
}: {
  questData: IQuest;
  category?: ICategoryNode;
  questAssignmentEndDate?: string;
}) => {
  const [muted, setMuted] = useState(true);
  const assignment = questData.currentAssignment?.endDate || questAssignmentEndDate;
  const isOverdue = assignment && new Date() > new Date(assignment);
  const trailerAsset = questData.trailerAsset?.url;
  const navigate = useNavigate();
  const { enrollUser } = useQuestConsumption().enrollUser;
  const releaseId = questData?.releases ? questData?.releases[0]?.id : "";
  const urlPath = new URL(questData.url).pathname;

  const handleStartProgram = async () => {
    try {
      const { data } = await enrollUser({
        variables: {
          input: {
            releaseId,
          },
        },
      });

      if (!data || !data.enrollUser.successful) {
        throw data.enrollUser.messages[0]?.code;
      }
      navigate(urlPath);
    } catch (error) {
      console.error("Error while starting program", error);
    }
  };

  return (
    <div className="rounded-2xl shadow-md">
      <div className="rounded-2xl -my-10 w-full bg-white shadow flex-col justify-start items-center inline-flex">
        <div className="relative bg-black flex-col justify-end items-start flex rounded-t-2xl">
          <div className="HoverCardTrailer1 w-full h-auto">
            <video
              src={trailerAsset}
              poster={questData.coverAsset?.url}
              muted={muted}
              loop={true}
              controls={false}
              className={cn("w-full h-full object-cover rounded-t-2xl")}
              autoPlay={true}
              controlsList="nodownload"
            >
              Your browser does not support the video element.
              <source src={trailerAsset} type={"video/mp4"} />
            </video>
          </div>
          <div
            className="absolute bottom-0 w-full"
            style={{
              background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%)",
            }}
          >
            <div className="px-4 py-4 justify-between items-end flex w-full">
              <div className="text-white flex-col justify-start items-start flex-inline">
                <img alt={questData.name} className="Union  w-full h-[40px]" src={questData.wordmarkAsset?.url ?? ""} />
              </div>
              <button
                onClick={() => setMuted(!muted)}
                type="button"
                className="rounded-3xl backdrop-blur-md bg-transparent justify-center items-center flex flex-row"
              >
                <div className="rounded-3xl p-2 border border-white/25 justify-start items-center flex">
                  <div className="w-5 justify-center items-center flex">
                    {<Icons name={muted ? "volume-x-filled" : "volume-2-filled"} height={20} width={20} fill="white" />}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        {assignment && (
          <div
            className={cn(
              "backdrop-blur self-stretch px-4 py-2 justify-start items-start inline-flex",
              isOverdue ? "bg-red-900" : "bg-brown-900",
            )}
          >
            <div className="grow shrink basis-0 h-4 justify-between items-center flex">
              <div className="justify-start items-center gap-1.5 flex">
                <div className="justify-center items-center flex">
                  <Icons
                    name="calendar-reminder-outlined"
                    width={12}
                    height={12}
                    fill={isOverdue ? "#FFADA7" : "white"}
                  />
                </div>

                <div className={cn("title-9", isOverdue ? "text-red-200" : "text-white")}>Assigned to you</div>
              </div>
              <div className={cn("caption-disclaimer", isOverdue ? "text-red-200" : "text-white")}>
                {isOverdue ? "Overdue" : "Complete by"} {getFormattedDate(assignment ?? "")}
              </div>
            </div>
          </div>
        )}
        <div className="self-stretch px-4 pt-4 pb-6 rounded-2xl flex-col justify-start items-center gap-2.5 flex">
          <div className="self-stretch flex-col justify-start items-start gap-3 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-start items-center gap-1 flex">
                {questData.userProgress?.completed ? (
                  <button type="button" className={"bg-cool-grey-700 rounded-[100px] button-text-medium"}>
                    <div className="NeutralButton flex-col justify-start items-start inline-flex ">
                      <div className="Basebutton h-9 px-3 py-1.5 justify-center items-center inline-flex">
                        <div className="PastReplay w-5 h-5 pl-[0.42px] pr-[0.62px] py-[0.62px] justify-center items-center flex">
                          <Icons name="past-replay-filled" height={20} width={20} fill="white" />
                        </div>
                        <div className="Label px-1 justify-center items-center flex">
                          <div className="Button text-center text-white">Restart program</div>
                        </div>
                      </div>
                    </div>
                  </button>
                ) : questData.userProgress?.started ? (
                  <Link to={`/quests/${questData.language}/${questData.slug}`} className={"w-full"}>
                    <button type="button" className={"bg-red-600 rounded-[100px] button-text-medium"}>
                      <div className="NeutralButton flex-col justify-start items-start inline-flex ">
                        <div className="Basebutton h-9 px-3 py-1.5 justify-center items-center inline-flex">
                          <div className="PastReplay w-5 h-5 pl-[0.42px] pr-[0.62px] py-[0.62px] justify-center items-center flex">
                            <Icons name="play-filled" width={20} height={21} fill="white" />
                          </div>
                          <div className="Label px-1 justify-center items-center flex">
                            <div className="Button text-center text-white">
                              {`Continue to lesson ${questData.userProgress.resumePage?.position}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </Link>
                ) : (
                  <button
                    type="button"
                    className={"bg-red-600 rounded-[100px] button-text-medium"}
                    onClick={handleStartProgram}
                  >
                    <div className="NeutralButton flex-col justify-start items-start inline-flex ">
                      <div className="Basebutton h-9 px-3 py-1.5 justify-center items-center inline-flex">
                        <div className="PastReplay w-5 h-5 pl-[0.42px] pr-[0.62px] py-[0.62px] justify-center items-center flex">
                          <Icons name="play-filled" width={20} height={21} fill="white" />
                        </div>
                        <div className="Label px-1 justify-center items-center flex">
                          <div className="Button text-center text-white">Start program</div>
                        </div>
                      </div>
                    </div>
                  </button>
                )}
                <QuestLandingPage
                  questData={questData}
                  trigger={
                    <button type="button" className="rounded-lg">
                      <Tooltip tooltipContent={<p className="caption-disclaimer">Learn more</p>}>
                        <div className="p-2 rounded-[128px] border border-black/10 justify-start items-center gap-2.5 inline-flex">
                          <div className="justify-center items-center flex">
                            <Icons name="list-filled" width={20} height={21} fill="#595E67" />
                          </div>
                        </div>
                      </Tooltip>
                    </button>
                  }
                />
              </div>
              {/* <button type="button" className="rounded-lg">
                <Tooltip
                  tooltipContent={
                    <p className="caption-disclaimer">Add to my list</p>
                  }
                >
                  <div className="p-2 h-9 w-9 rounded-full border border-black/10 justify-start items-center gap-2.5 inline-flex">
                    <div className="justify-center items-center flex">
                      <Icons
                        name="plus-filled"
                        width={20}
                        height={20}
                        fill="#595E67"
                      />
                    </div>
                  </div>
                </Tooltip>
              </button> */}
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-1 flex">
              {category && (
                <div className={cn(category.name, "heading-9")}>
                  <span>{category.name.toLowerCase()}</span>
                </div>
              )}

              <div className="self-stretch justify-start items-center gap-1 inline-flex">
                <div className="heading-7">{questData.name}</div>
              </div>
              <div className="text-content-quaternary text-sm body-small leading-tight tracking-tight">
                {questData.authors[0].name}
              </div>
            </div>
            <div className="self-stretch text-secondary body-small text-sm leading-tight tracking-tight line-clamp-4">
              {questData.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
