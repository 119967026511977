import { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../ui/dialog";
import { Slider } from "../ui/slider";
import { cn } from "libs/classMerger";
import useEmblaCarousel from "embla-carousel-react";
import Icons from "../icons/Icons";
import { Button } from "../Button";
import { Tooltip } from "../ui/tooltip";
import { useMediaPlayerContext } from "context/MediaPlayerV2Context";

export const mixers = [
  {
    id: "mixer_0",
    title: "No background sound",
  },
  {
    id: "mixer_1",
    title: "Second Nature",
    image: "/img/featured-sound-1.jpeg",
    sound:
      "https://assets.mindvalley.com/api/v1/assets/556fe068-755a-4667-947c-b932fb99535d.wav?token=04c091da2b9844ba23ecd2c6dbdc393b.eMufvLH2kn4-5Bort5XAva6P2vLN.a345425a0e0405d0174ffe272732c1df684a0c586ef4fad22452e71c2ceb2f55",
  },
  {
    id: "mixer_2",
    title: "Second Nature",
    image: "/img/featured-sound-2.jpeg",
    sound: "/mixers/sounds/bg_sound_2.wav.mp3",
  },
  {
    id: "mixer_3",
    title: "Second Nature",
    image: "/img/featured-sound-3.jpeg",
    sound: "/mixers/sounds/bg_sound_3.wav.mp3",
  },
  {
    id: "mixer_4",
    title: "Second Nature",
    image: "/img/featured-sound-4.jpeg",
    sound: "/mixers/sounds/bg_sound_4.wav.mp3",
  },
  {
    id: "mixer_5",
    title: "Second Nature",
    image: "/img/featured-sound-1.jpeg",
    sound: "/mixers/sounds/bg_sound_5.wav.mp3",
  },
  {
    id: "mixer_6",
    title: "Second Nature",
    image: "/img/featured-sound-1.jpeg",
    sound: "/mixers/sounds/bg_sound_6.wav.mp3",
  },
  {
    id: "mixer_7",
    title: "Second Nature",
    image: "/img/featured-sound-2.jpeg",
    sound: "/mixers/sounds/bg_sound_7.wav.mp3",
  },
];

interface MediaPlayerV2MixerProps {
  fullScreenMode: boolean;
}

const MediaPlayerV2Mixer = ({ fullScreenMode }: MediaPlayerV2MixerProps) => {
  const {
    mixerAudioPlayerRef,
    setMixerTrack,
    mixerVolume,
    setMixerVolume,
    isMuted,
    toggleMute,
  } = useMediaPlayerContext();

  // const selectedIndex = 0;
  const minSoundVolume = 0;
  const maxSoundVolume = 100;
  const stepSound = 1;

  const handleMixingVolumeChange = (value: number[]) => {
    console.log(value);
    setMixerVolume(value[0]);
  };

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    slidesToScroll: 1,
    containScroll: "trimSnaps",
  });

  const handleSelectMixer = (mixer: any) => {
    // @ts-ignore
    setMixerTrack({
      id: mixer.id,
      imagePath: mixer.image,
      title: mixer.title,
      author: "",
      file: mixer.sound,
    });

    mixerAudioPlayerRef.current?.play();
  };

  useEffect(() => {
    if (mixerAudioPlayerRef?.current) {
      mixerAudioPlayerRef.current.volume = mixerVolume / 100;
    }
  }, [mixerVolume, mixerAudioPlayerRef]);

  const onSliderValueChange = (value: number[]) => {
    const newVolume = value[50];
    setMixerVolume(newVolume);

    if (mixerAudioPlayerRef?.current) {
      mixerAudioPlayerRef.current.volume = newVolume / 100;
    }

    if (newVolume > 0 && isMuted) {
      toggleMute();
    } else if (newVolume === 50 && !isMuted) {
      toggleMute();
    }
  };

  const selectedMixer = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollTo(selectedIndex);
  }, [emblaApi]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!emblaApi) return;
    const onSelect = () => setSelectedIndex(emblaApi.selectedScrollSnap());
    emblaApi.on("select", onSelect);
    onSelect();
  }, [emblaApi]);

  return (
    <Dialog>
      {/* <DialogOverlay className={"bg-black/70 backdrop-blur-[6px]"}/> */}
      <DialogTrigger
        className={`${fullScreenMode && "w-full flex items-start"}`}
      >
        {fullScreenMode ? (
          <div
            className={cn(
              "h-[19px] px-1 py-0.5 group bg-black/25 hover:bg-black/35 rounded backdrop-blur-md justify-start items-center gap-1 inline-flex",
              // @ts-ignore I'm quite unsure what this for, need to ask previous engineer who work on it. But for now got no time
              !{ DialogContent } &&
                "group:bg-opacity-0 group-hover:bg-opacity-0"
            )}
          >
            <Icons
              name="sliders-outlined"
              width={12}
              height={12}
              fill="#6ddbda"
              className="svg-icon-mixer-available"
            />
            {mixers.length > 0 ? (
              <div
                className={cn(
                  "text-[#6ddbda] body-2-xs leading-[15px] tracking-wide px-6 overflow-hidden max-w-[152px]",
                  // @ts-ignore I'm quite unsure what this for, need to ask previous engineer who work on it. But for now got no time
                  !{ DialogContent } && "max-w-full"
                )}
              >
                <p className="animate-[marquee-text_10s_linear_infinite] whitespace-nowrap">
                  <span>&emsp; Mixing with inner dimension &emsp;</span>
                  <span>&emsp; Mixing with inner dimension &emsp;</span>
                  <span>&emsp; Mixing with inner dimension &emsp;</span>
                </p>
              </div>
            ) : (
              <div className="text-[#6ddbda] body-2-xs leading-[15px] tracking-wide">
                Mixer available
              </div>
            )}
          </div>
        ) : (
          <Tooltip
            delayDuration={0}
            tooltipContent={<p className="caption-disclaimer">Mixer</p>}
          >
            <Button
              variant="icon"
              size="icon"
              className="text-white/70 hover:text-white"
            >
              <Icons name="sliders-outlined" width={20} height={20} />
            </Button>
          </Tooltip>
        )}
      </DialogTrigger>

      <DialogContent className="bg-black/80 max-w-[748px] gap-y-6 backdrop-blur border-0 z-[999] px-6 py-4">
        <DialogHeader className="relative">
          <DialogTitle className="!heading-9 text-white text-center">
            Background mixer
          </DialogTitle>
          <DialogClose asChild>
            <Button
              variant="icon"
              size="icon"
              className="text-white/70 hover:text-white absolute right-0 top-0 bottom-0 !mt-0"
            >
              <Icons name="x-outlined" width={16} height={16} />
            </Button>
          </DialogClose>
        </DialogHeader>
        <div className="relative">
          <div
            ref={emblaRef}
            className="flex items-center overflow-x-hidden relative max-w-[700px] h-[148px]"
          >
            <div className="flex items-center gap-3 h-full touch-pan-y touch-pinch-zoom relative">
              {mixers.map((mixer, index) => (
                <button
                  type="button"
                  key={mixer.id}
                  onClick={() => {
                    selectedMixer();
                    handleSelectMixer(mixer);
                  }}
                  className={cn(
                    "shrink-0 self-end",
                    index === mixers.length - 1 && "mr-3",
                    index === selectedIndex && "self-center mx-5"
                  )}
                >
                  {!(mixer.title === "No background sound") ? (
                    <img
                      alt={`Mixer option ${mixer.title}`}
                      className={cn(
                        "size-[110px] object-cover rounded-full transform transition-all ease-in-out duration-700 ring-opacity-0 ring-red-500 scale-100",
                        index === selectedIndex &&
                          "ring-4 ring-opacity-100  ring-red-500 scale-125"
                      )}
                      src={mixer.image}
                    />
                  ) : (
                    <div
                      className={cn(
                        "size-[110px] bg-black/70 object-cover rounded-full transform transition-all ease-in-out duration-700 flex justify-center items-center ring-opacity-0 ring-red-500 scale-100",
                        index === selectedIndex &&
                          "ring-4 ring-opacity-100 ring-red-500 scale-125"
                      )}
                    >
                      <p className="text-white heading-9 px-6">
                        No Background sound
                      </p>
                    </div>
                  )}
                </button>
              ))}
            </div>
          </div>

          <Button
            variant="icon"
            size="icon"
            className="absolute left-[-12px] bottom-0 text-white/70 bg-brown-12a hover:bg-brown-24a rounded-full p-2"
            onClick={() => {
              emblaApi?.scrollPrev();
            }}
          >
            <Icons
              name="chevron-left-outlined"
              width={20}
              height={20}
              fill="#fff"
            />
          </Button>
          <Button
            variant="icon"
            size="icon"
            className="absolute right-[-12px] bottom-0 text-white/70 bg-brown-12a hover:bg-brown-24a rounded-full p-2"
            onClick={() => {
              emblaApi?.scrollNext();
            }}
          >
            <Icons
              name="chevron-right-outlined"
              width={20}
              height={20}
              fill="#fff"
            />
          </Button>
        </div>

        <div className={cn("flex items-center gap-4 group/volume h-7")}>
          <div className="text-white/60 group-hover/volume:text-white-90a caption-disclaimer">
            sound
          </div>
          <Slider
            showThumb={true}
            defaultValue={[mixerVolume]}
            min={minSoundVolume}
            max={maxSoundVolume}
            step={stepSound}
            onValueChange={handleMixingVolumeChange}
            value={[mixerVolume]}
            thumbClassName="flex justify-center items-center w-7 h-7 bg-cool-grey-250 border group-hover/volume:ring-4 group-hover/volume:ring-offset-gray-250 border-cool-grey-250"
            rangeClassName="h-[3px]"
            trackClassName="h-[3px]"
            className={cn(
              "w-full h-[3px] bg-white/60 group-hover/volume:bg-white-90a"
            )}
            thumbElement={
              <Icons
                name="volume-2-filled"
                width={14}
                height={14}
                fill="bg-cool-grey-700"
                className="svg-icon-volume"
              />
            }
          />

          <div className="text-white/60 group-hover/volume:text-white-90a   caption-disclaimer">
            vocal
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MediaPlayerV2Mixer;
