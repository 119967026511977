import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import QuestsSkeleton from "components/loading/QuestsSkeleton";
import { QuestCard } from "components/quest/QuestCard";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import useRecommendedQuests from "hooks/useRecommendedQuests";
import useTrendingGloballyQuests from "hooks/useTrendingGloballyQuests";
import { useState } from "react";
import type { IQuest } from "types/interfaces";

export default function RecommendedForYou() {
  const { userAccessState, isAuthenticated } = useUserState();
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();

  // Fetching API
  const { trendingGloballyQuestsData, loading: fetchingTrendingGloballyQuestsData } = useTrendingGloballyQuests();
  const { recommendedQuestsData, loading: fetchingRecommendedQuestsData } = useRecommendedQuests();

  if (fetchingTrendingGloballyQuestsData || fetchingRecommendedQuestsData) return <QuestsSkeleton />;

  const quests =
    isAuthenticated && userAccessState === UserAccessStateEnum.Premium
      ? recommendedQuestsData
      : trendingGloballyQuestsData;
  if (!quests?.length) return <QuestsSkeleton infoHeader="No Quest available" />;

  return (
    <section className="bg-[#F9F8F4]">
      <div className="flex flex-col gap-y-4 w-full px-5 py-6 gap-6 container mx-auto">
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-start gap-0.5">
            <div className="heading-7">
              {userAccessState === UserAccessStateEnum.Premium ? "Recommended" : "Trending globally"}
            </div>
            {userAccessState === UserAccessStateEnum.Premium && (
              <div className="hidden lg:flex text-cool-grey-450 mb-4">Quests based on your interest</div>
            )}
          </div>
          <div className="hidden sm:flex lg:hidden items-start space-x-2">
            <CarouselPrevious onClick={() => carouselApi?.scrollPrev()} />
            <CarouselNext onClick={() => carouselApi?.scrollNext()} />
          </div>
        </div>

        <Carousel
          opts={{
            align: "start",
            containScroll: "trimSnaps",
            active: false,
          }}
          orientation="horizontal"
          className="w-full"
          setApi={setCarouselApi}
        >
          <CarouselContent>
            {quests.map((quest: IQuest) => (
              <CarouselItem
                key={quest?.id}
                className="py-4 lg:py-6 w-sm md:w-md lg:w-lg xl:w-xl basis-full md:basis-1/2 lg:basis-1/4 xl:basis-1/5"
              >
                <QuestCard
                  quest={quest}
                  canHover={userAccessState === UserAccessStateEnum.Premium}
                  userAccessState={userAccessState}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </section>
  );
}
