import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import QuestsSkeleton from "components/loading/QuestsSkeleton";
import { QuestCard, cardVariantEnum } from "components/quest/QuestCard";
import { useUserState } from "context/userContext";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import type { IAssignedQuestNode } from "types/interfaces";

export default function AssignedToYouSection({
  questsAssigned,
  loading,
}: {
  questsAssigned: IAssignedQuestNode[];
  loading?: boolean;
}) {
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();

  const [overdueQuests, setOverdueQuests] = useState(false);
  const { isAuthenticated, userAccessState } = useUserState();

  const unEnrolledAssignedQuests = questsAssigned.filter(
    (assignment) => assignment.quest.userProgress?.started === false,
  );

  useEffect(() => {
    if (!questsAssigned || questsAssigned.length === 0) return;
    const overdueQuests = questsAssigned.filter((quest) => quest.endDate && new Date() > new Date(quest.endDate));
    if (overdueQuests.length > 0) {
      setOverdueQuests(true);
    }
  }, [questsAssigned]);

  if (loading) return <QuestsSkeleton />;

  // if (!questsAssigned?.length) return <></>;

  return (
    <section className="container">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2 md:flex-row">
          <div className="heading-6">Assigned to you</div>
          {overdueQuests && (
            <Link to={`${NavigationEnum.Library}/assigned`}>
              <div className="py-1 px-2 flex items-center gap-2 rounded-[4px] bg-red-50 text-red-600 button-text-small">
                You have overdue Quests
              </div>
            </Link>
          )}
        </div>
        <div className="flex gap-2">
          <Link to={`${NavigationEnum.Library}/assigned`}>
            <button type="button" className="px-3 py-[6px] rounded-[100px] text-black-600 button-text-medium">
              See all
            </button>
          </Link>
          <div className="gap-1 hidden md:flex lg:hidden px-1">
            <CarouselPrevious onClick={() => carouselApi?.scrollPrev()} />
            <CarouselNext onClick={() => carouselApi?.scrollNext()} />
          </div>
        </div>
      </div>
      <Carousel
        opts={{
          align: "start",
          containScroll: "trimSnaps",
          active: false,
        }}
        orientation="horizontal"
        className="w-full"
        setApi={setCarouselApi}
      >
        <CarouselContent>
          {unEnrolledAssignedQuests
            .sort((questA, questB) => new Date(questA.endDate).getTime() - new Date(questB.endDate).getTime())
            .map((questNode: IAssignedQuestNode) => (
              <CarouselItem
                key={questNode.quest.id}
                className="py-4 lg:py-6  w-sm md:w-md lg:w-lg xl:w-xl basis-full md:basis-1/2 lg:basis-1/3 xl:basis-1/4"
              >
                <QuestCard
                  quest={questNode.quest}
                  cardVariant={cardVariantEnum.variant_2}
                  questAssignmentEndDate={questNode.endDate}
                  canHover={isAuthenticated}
                  userAccessState={userAccessState}
                />
              </CarouselItem>
            ))}
        </CarouselContent>
      </Carousel>
    </section>
  );
}
