import LockedModal from "components/LockedModal";
import Icons from "components/icons/Icons";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";

export interface CardProps {
  id: number;
  entity: string;
  subEntity?: string;
  text: string;
  link: string;
  label: string;
  activeCarousel?: number | null;
  onClickSlide?: () => void;
}

const iconsMap: Record<string, JSX.Element> = {
  "/programs": <Icons name="mountain-outlined" height={24} width={24} fill="#292D38" />,
  "/meditations": <Icons name="spiritual-outlined" height={24} width={24} fill="#292D38" />,
  "/soundscape": <Icons name="music-outlined" height={24} width={24} fill="#292D38" />,
};
function getIcon(entity: string) {
  return iconsMap[entity] || iconsMap["/programs"];
}

export function CarouselCard({ id, entity, subEntity, label, text, link, activeCarousel, onClickSlide }: CardProps) {
  const navigate = useNavigate();
  // const screenType = useScreenType();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userAccessState } = useUserState();
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleClickCarouselCard = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (userAccessState !== UserAccessStateEnum.Premium && entity !== NavigationEnum.Programs) {
      return setIsModalOpen(true);
    }
    return navigate(entity + (subEntity ? `#${subEntity}` : ""));
  };

  const autoPlay = activeCarousel === id;

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (autoPlay) {
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.error(`Autoplay failed for video ${id}:`, error);
          });
        }
      } else {
        video.pause();
      }
    }
  }, [autoPlay, id]);

  return (
    <>
      <div onClick={onClickSlide} onKeyDown={() => {}}>
        <div className="flex flex-col gap-3">
          <div className="rounded-3xl relative overflow-x-hidden cursor-pointer">
            <video ref={videoRef} autoPlay={autoPlay} muted playsInline loop preload="auto">
              <source src={link} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <div className="flex flex-col">
              <div className="absolute top-8 left-8">
                <div className="bg-white/70 flex flex-row gap-1 py-2 px-4 rounded-full text-cool-grey-600 items-center">
                  {getIcon(entity)}
                  <p className="heading-7">{label}</p>
                </div>
              </div>

              <div
                className="md:block absolute bottom-0 left-0 right-10 w-full flex flex-col md:flex-row items-center"
                style={{
                  background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)",
                }}
              >
                <div className="gap-10 px-10 pb-10 pt-14 flex items-center justify-between">
                  <div className="text-left text-white title-5 align-middle max-w-[636px]">{text}</div>
                  <button
                    type="button"
                    onClick={handleClickCarouselCard}
                    className="p-2 bg-white/90 rounded-[128px] justify-start items-start gap-[10px] flex"
                  >
                    <div className="ArrowDownRight relative">
                      <Icons name="arrow-down-right-filled" width={40} height={40} fill="black" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="px-2 flex md:hidden justify-between items-center">
            <div className="md:hidden text-left py-3 text-black title-8 align-middle">{text}</div>
            <div className="p-2 bg-white/90 rounded-[128px] justify-start items-start gap-[10px] flex">
              <div className="ArrowDownRight relative">
                <Icons name="arrow-down-right-filled" width={40} height={40} fill="black" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal onCancel={() => setIsModalOpen(false)} open={isModalOpen} userAccessState={userAccessState} />
      </div>
    </>
  );
}
