import { useQuery } from "@apollo/client";
import { GET_MEDITATION_OF_THE_DAY } from "services/graphql/queries/index";
import type { IResource } from "types/interfaces";

function useMeditationsOfTheDay() {
  const { data, loading, error } = useQuery(GET_MEDITATION_OF_THE_DAY, {
    variables: { limit: 4 },
  });
  const meditationsOfTheDay = data?.meditationOfTheDay as IResource[];
  return { meditationsOfTheDay, loading, error };
}

export default useMeditationsOfTheDay;
