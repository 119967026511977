import spriteUrl from "@mindvalley/design-system/dist/icons/sprites/ui-icons.svg";
import { ENV_ENUMS } from "config/teamsConfig";

interface IconProps {
  name: string;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

const iconPath = process.env.NODE_ENV === ENV_ENUMS.DEVELOPMENT ? "" : `${process.env.REACT_APP_TAB_ENDPOINT}/`;

const SvgIcons = ({ name, className, fill = "currentColor", width = 16, height = 16 }: IconProps) => (
  <svg className={className} width={width} height={height} fill={fill}>
    <title>{name}</title>
    <use href={`${iconPath}${spriteUrl}#${name}`} />
  </svg>
);
const withOverrideFill =
  (IconComponent: React.FC<IconProps>) =>
  ({ fill = "currentColor", ...props }: IconProps) => {
    return (
      <span style={{ color: fill }}>
        <IconComponent {...props} />
      </span>
    );
  };
const Icons = withOverrideFill(SvgIcons);
export default Icons;
