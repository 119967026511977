import { Button } from "components/Button";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "components/Carousel";
import { QuestCard } from "components/quest/QuestCard";
import { useUserState } from "context/userContext";
import useFreeQuests from "hooks/useFreeQuests";
import { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import FreeQuestSkeleton from "components/loading/FreeQuestSkeleton";

export const FreeQuestsSection = () => {
  const { userAccessState } = useUserState();
  const { questData, loading, error } = useFreeQuests();
  const carouselRef = useRef<any>(null);
  const setCarouselApi = useCallback((api: any | undefined) => {
    carouselRef.current = api || null;
  }, []);

  const [currentItem, setCurrentItem] = useState<number>(0);
  function nextPreviousArrowHandler(value: number) {
    console.log("nextPreviousArrowHandler", currentItem);
    const newIndex = Math.max(0, Math.min(currentItem + value, questData.length - 1)); // Ensure index stays within bounds

    if (value > 0 && newIndex > currentItem) {
      carouselRef.current?.scrollNext();
    } else if (value < 0 && newIndex < currentItem) {
      carouselRef.current?.scrollPrev();
    }

    setCurrentItem(newIndex);
  }
  if (loading || error)
    return <FreeQuestSkeleton />

  return (
    <div className="flex px-15 py-12 bg-free-quests h-auto md:h-[480px]">
      <div className="container flex gap-4 flex-col justify-end">
        {questData.length > 3 && (
          <div className="hidden md:flex gap-2 items-center justify-end">
            <CarouselPrevious disabled={currentItem === 0} onClick={() => nextPreviousArrowHandler(-1)} />
            <CarouselNext
              disabled={currentItem === questData.length - 1}
              onClick={() => nextPreviousArrowHandler(1)}
            />
          </div>
        )}
        <div className="flex flex-start gap-10 flex-col md:flex-row">
          <div className="gap-4 shrink-0 w-[480px] content-end">
            <div className="flex-col items-start gap-1 flex">
              <div className="px-3 py-1 bg-red-600 rounded-3xl">
                <div className="heading-9 text-white">Free Quests</div>
              </div>
              <div className="hidden md:block heading-2">Start your personal transformation journey here</div>
              <div className="heading-2 md:hidden">Start learning and begin exploring</div>
              <div className="hidden md:block body-small">Explore our free Quests today.</div>
              <div className="body-small md:hidden">
                Your adventure in learning begins today. Explore and enjoy our free Quests.
              </div>
            </div>
            <Link to={NavigationEnum.Programs} className="hidden md:flex mt-4">
              <Button variant={"neutral"}>Discover more</Button>
            </Link>
          </div>
          <div className="flex gap-4 flex-col">
            <Carousel
              opts={{
                align: "start",
                dragFree: false,
                active: false,
              }}
              setApi={setCarouselApi}
            >
              <div>
                <CarouselContent ref={carouselRef}>
                  {questData.map((quest, index) => (
                    <CarouselItem key={quest.id} className=" basis-auto pl-0">
                      {index > currentItem && <QuestCard quest={quest} canHover userAccessState={userAccessState} className="w-[382px] md:w-[411px] pl-4" />}
                    </CarouselItem>
                  ))}
                </CarouselContent>
              </div>
            </Carousel>
          </div>
          <Link to={NavigationEnum.Programs} className="col-span-12 md:hidden w-full">
            <Button variant={"neutral"} className="w-full">
              Discover more
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
