import { MyLibraryCompletedSkeleton } from "components/loading/MyLibraryListSkeleton";
import { QuestCard } from "components/quest/QuestCard";
import { useUserState } from "context/userContext";
import useLibrary from "hooks/useLibrary";
import { QuestStatus } from "types/interfaces";
import EmptyState from "../components/EmptyState";

const Completed = () => {
  const { userAccessState } = useUserState();
  const { questData, loading, error } = useLibrary({
    status: QuestStatus.Completed,
  });
  const completedQuests = questData;

  if (loading || error) return <MyLibraryCompletedSkeleton />;

  return (
    <div className="flex flex-col gap-8">
      <p className="heading-5 hidden lg:block">Completed</p>
      {completedQuests.length === 0 ? (
        <EmptyState section="completed" />
      ) : (
        <div className="space-y-2">
          <p className="heading-7">{`Quests (${completedQuests.length})`}</p>
          <div className="flex flex-col md:flex-row gap-5">
            {completedQuests.map((quest) => (
              <QuestCard
                quest={quest}
                key={quest.id}
                userAccessState={userAccessState}
                canHover
                className="basis-1/2 lg:basis-1/4"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Completed;
