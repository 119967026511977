import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import { getFormattedDate } from "tabs/Programs/programs-utils";
import type { IWorkwayQuestAssignment } from "types/interfaces";

export const AssignedBanner = ({
  assignment,
  className,
}: {
  assignment: IWorkwayQuestAssignment;
  className?: string;
}) => (
  <div
    className={cn(
      "flex justify-between sticky lg:absolute bottom-0 w-full text-white py-2 px-10",
      className
    )}
  >
    <div className="flex items-center gap-2">
      <Icons
        name="calendar-reminder-outlined"
        width={14}
        height={14}
        fill="white"
      />
      <p className="title-9">Assigned to you</p>
    </div>
    <p className="caption-disclaimer">
      Complete by {getFormattedDate(assignment.endDate)}
    </p>
  </div>
);
