import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../Dialog";
import { cn } from "libs/classMerger";
import { Button } from "../Button";
import { Tooltip } from "../ui/tooltip";
import Icons from "../icons/Icons";
import { useMediaPlayerContext } from "context/MediaPlayerV2Context";

interface MediaPlayerV2TimerModalProps {
  title?: string;
  fullScreenMode: boolean;
}

const MediaPlayerV2TimerModal = ({
  fullScreenMode,
}: MediaPlayerV2TimerModalProps) => {
  const [infinity, setInfinity] = useState(true);
  const [hoursDuration, SetHoursDuration] = useState(0);
  const [minuteDuration, SetMiutesDuration] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const { setIsTimerSet, setTime, setIsPlaying, audioPlayerRef } =
    useMediaPlayerContext();

  const progress = audioPlayerRef.current
    ? Math.floor(
        (audioPlayerRef.current.currentTime / audioPlayerRef.current.duration) *
          100
      )
    : 0;

  const handleStayForever = () => {
    console.log("infinity", infinity);
    if (infinity) {
      setIsOpen(false);
      return;
    }

    setNewTime();
    setIsTimerSet(true);

    // setInfinity((mode) => !mode);
  };

  const handleChangeDuration = () => {
    if (!infinity) {
      setInfinity(true);
      setIsOpen(false);
      return;
    }
    setInfinity(false);
  };

  const setNewTime = () => {
    // Calculate total duration in seconds
    const totalDurationInSeconds = hoursDuration * 3600 + minuteDuration * 60;

    // Set the time state
    setTime({
      progress: progress,
      currentTime: {
        second: audioPlayerRef.current
          ? Math.floor(audioPlayerRef.current.currentTime % 60)
          : 0,
        minute: audioPlayerRef.current
          ? Math.floor(audioPlayerRef.current.currentTime / 60)
          : 0,
      },
      totalTime: {
        second: minuteDuration,
        minute: hoursDuration,
      },
    });

    setIsTimerSet(true);

    // Start playing the audio if it isn't already
    if (audioPlayerRef.current?.paused) {
      audioPlayerRef.current.play();
    }

    // Clear any existing interval before creating a new one

    // Set up an interval to track and stop the audio
    const interval = setInterval(() => {
      const currentTimeInSeconds = audioPlayerRef.current
        ? audioPlayerRef.current.currentTime
        : 0;

      // If the current time exceeds or matches the set duration, stop the audio
      if (currentTimeInSeconds >= totalDurationInSeconds) {
        if (audioPlayerRef.current) {
          audioPlayerRef.current.pause();
          audioPlayerRef.current.currentTime = 0; // Reset the audio to the start
        }
        setIsPlaying(false);
        clearInterval(interval); // Clear the interval once the audio has stopped
      }
    }, 1000);
  };

  const onHoursUpClick = () => {
    SetHoursDuration((duration) => Math.min(duration + 1, 23));
  };

  const onHoursDownClick = () => {
    SetHoursDuration((duration) => Math.max(duration - 1, 0));
  };

  const onMinutesDownClick = () => {
    SetMiutesDuration((duration) => Math.max(duration - 1, 0));
  };

  const onMinutesUpClick = () => {
    SetMiutesDuration((duration) => Math.min(duration + 1, 59));
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        {fullScreenMode ? (
          <div
            className={cn(
              "h-[19px] px-1 py-0.5 group bg-black/25 hover:bg-black/35 rounded backdrop-blur-md justify-start items-center gap-1 inline-flex",
              "group:bg-opacity-0 group-hover:bg-opacity-0"
            )}
          >
            <Icons
              name="stopwatch-outlined"
              width={12}
              height={12}
              fill="#6ddbda"
              className="svg-icon-mixer-available"
            />
            <div className="text-[#6ddbda] body-2-xs leading-[15px] tracking-wide">
              Set duration
            </div>
          </div>
        ) : (
          <Tooltip
            delayDuration={0}
            tooltipContent={<p className="caption-disclaimer">Timer</p>}
          >
            <Button
              variant="icon"
              size="icon"
              className="text-white/70 hover:text-white"
            >
              <Icons name="stopwatch-outlined" width={20} height={20} />
            </Button>
          </Tooltip>
        )}
      </DialogTrigger>
      <DialogContent className="bg-black/80 backdrop-blur-[6px] max-w-[350px] md:max-w-[560px] gap-y-6 border-0 z-[999] px-6 py-4">
        <DialogHeader className="relative">
          <DialogTitle className="!heading-9 text-white text-center">
            How long do you want to listen?
          </DialogTitle>
          <DialogClose asChild>
            <Button
              variant="icon"
              size="icon"
              className="text-white/70 hover:text-white absolute right-0 top-0 bottom-0 !mt-0"
            >
              <Icons name="x-outlined" width={16} height={16} />
            </Button>
          </DialogClose>
        </DialogHeader>
        <div className="relative flex flex-col justify-center items-center h-[140px] gap-3">
          <div className="flex flex-col justify-center items-center z-20">
            <Button
              onClick={handleStayForever}
              variant="icon"
              size="icon"
              className="text-white/70 hover:text-white"
            >
              <Icons
                name="infinity-filled"
                width={40}
                height={40}
                className="scale-[1]"
              />
            </Button>
            {infinity ? (
              <div className="text-center text-white title-10 leading-[14px] tracking-tight">
                PLAYING FOREVER
              </div>
            ) : null}
          </div>
          {!infinity ? (
            <div className="flex flex-col justify-center items-center animate-fade-in">
              <div className="justify-start items-center gap-1 inline-flex">
                <div className="flex justify-center items-center gap-0.5 ">
                  <div className="inline-flex flex-col justify-center items-center gap-0.5 w-[68px] px-1">
                    <Button
                      onClick={onHoursUpClick}
                      className="flex justify-center items-center w-full bg-opacity-0 hover:bg-gradient-to-b from-white/0 to-white/0 h-auto px-3 py-0.5"
                    >
                      <Icons
                        name="chevron-up-outlined"
                        width={12}
                        height={12}
                      />
                    </Button>
                    <div className="flex flex-col justify-center items-center">
                      <div className="text-[#ebedef] text-[44px] font-normal font-['Sharp Grotesk Cyr Book'] leading-[52px] tracking-tight">
                        {String(hoursDuration).padStart(2, "0")}
                      </div>
                      <div className="self-stretch text-center text-white/60 text-[8px] font-normal font-['Sharp Grotesk Cyr Book'] leading-3 tracking-wide">
                        HOURS
                      </div>
                    </div>
                    <Button
                      onClick={onHoursDownClick}
                      className="flex justify-center items-center w-full bg-opacity-0 hover:bg-gradient-to-b from-white/0 to-white/0 h-auto px-3 py-0.5"
                    >
                      <Icons
                        name="chevron-down-outlined"
                        width={12}
                        height={12}
                      />
                    </Button>
                  </div>
                  <div className="text-white text-xl font-normal font-['Sharp Grotesk Cyr Book'] leading-[30px] tracking-tight">
                    :
                  </div>
                  <div className="inline-flex flex-col justify-center items-center gap-0.5 w-[68px] px-1">
                    <Button
                      onClick={onMinutesUpClick}
                      className="flex justify-center items-center w-full bg-opacity-0 hover:bg-gradient-to-b from-white/0 to-white/0 h-auto px-3 py-0.5"
                    >
                      <Icons
                        name="chevron-up-outlined"
                        width={12}
                        height={12}
                      />
                    </Button>
                    <div className="flex flex-col justify-center items-center">
                      <div className="text-[#ebedef] text-[44px] font-normal font-['Sharp Grotesk Cyr Book'] leading-[52px] tracking-tight">
                        {String(minuteDuration).padStart(2, "0")}
                      </div>
                      <div className="self-stretch text-center text-white/60 text-[8px] font-normal font-['Sharp Grotesk Cyr Book'] leading-3 tracking-wide">
                        MINS
                      </div>
                    </div>
                    <Button
                      onClick={onMinutesDownClick}
                      className="flex justify-center items-center w-full bg-opacity-0 hover:bg-gradient-to-b from-white/0 to-white/0 h-auto px-3 py-0.5"
                    >
                      <Icons
                        name="chevron-down-outlined"
                        width={12}
                        height={12}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="absolute flex flex-col justify-center items-center opacity-20 blur-[2px] animate-fade-in z-10">
              <div className="justify-start items-center gap-1 inline-flex">
                <div className="flex justify-center items-center gap-0.5 ">
                  <div className="inline-flex flex-col justify-center items-center gap-0.5 w-[68px] px-1">
                    <Button
                      onClick={onHoursUpClick}
                      className="flex justify-center items-center w-full bg-opacity-0 hover:bg-gradient-to-b from-white/0 to-white/0 h-auto px-3 py-0.5"
                    >
                      <Icons
                        name="chevron-up-outlined"
                        width={12}
                        height={12}
                      />
                    </Button>
                    <div className="flex flex-col justify-center items-center">
                      <div className="text-[#ebedef] text-[44px] font-normal font-['Sharp Grotesk Cyr Book'] leading-[52px] tracking-tight">
                        {String(hoursDuration).padStart(2, "0")}
                      </div>
                      <div className="self-stretch text-center text-white/60 text-[8px] font-normal font-['Sharp Grotesk Cyr Book'] leading-3 tracking-wide">
                        HOURS
                      </div>
                    </div>
                    <Button
                      onClick={onHoursDownClick}
                      className="flex justify-center items-center w-full bg-opacity-0 hover:bg-gradient-to-b from-white/0 to-white/0 h-auto px-3 py-0.5"
                    >
                      <Icons
                        name="chevron-down-outlined"
                        width={12}
                        height={12}
                      />
                    </Button>
                  </div>
                  <div className="text-white text-xl font-normal font-['Sharp Grotesk Cyr Book'] leading-[30px] tracking-tight">
                    :
                  </div>
                  <div className="inline-flex flex-col justify-center items-center gap-0.5 w-[68px] px-1">
                    <Button
                      onClick={onMinutesUpClick}
                      className="flex justify-center items-center w-full bg-opacity-0 hover:bg-gradient-to-b from-white/0 to-white/0 h-auto px-3 py-0.5"
                    >
                      <Icons
                        name="chevron-up-outlined"
                        width={12}
                        height={12}
                      />
                    </Button>
                    <div className="flex flex-col justify-center items-center">
                      <div className="text-[#ebedef] text-[44px] font-normal font-['Sharp Grotesk Cyr Book'] leading-[52px] tracking-tight">
                        {String(minuteDuration).padStart(2, "0")}
                      </div>
                      <div className="self-stretch text-center text-white/60 text-[8px] font-normal font-['Sharp Grotesk Cyr Book'] leading-3 tracking-wide">
                        MINS
                      </div>
                    </div>
                    <Button
                      onClick={onMinutesDownClick}
                      className="flex justify-center items-center w-full bg-opacity-0 hover:bg-gradient-to-b from-white/0 to-white/0 h-auto px-3 py-0.5"
                    >
                      <Icons
                        name="chevron-down-outlined"
                        width={12}
                        height={12}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2 justify-center items-center">
          <div className="flex-col justify-start items-start inline-flex">
            <Button
              onClick={handleStayForever}
              className="px-3.5 py-3 bg-white hover:bg-white/90 rounded-[100px] justify-center items-center inline-flex hover:bg-gradient-to-b from-white/0 to-white/0"
            >
              <div className="px-1.5 justify-start items-start flex">
                <div className="text-center text-[#0f131a] button-text-medium leading-[18px] tracking-tight">
                  {infinity ? "Stay forever" : "Set timer"}
                </div>
              </div>
            </Button>
          </div>
          <div className="h-7 justify-start items-start inline-flex">
            <Button
              onClick={handleChangeDuration}
              className="rounded-[100px] justify-center items-center flex bg-opacity-0 hover:bg-white/10 hover:bg-gradient-to-b from-white/0 to-white/0 px-[10px] py-1 h-[28px]"
            >
              <div className="justify-start items-start flex">
                <div className="text-center text-white button-text-small leading-none tracking-tight">
                  {infinity ? "Change duration" : "∞ I want it to play forever"}
                </div>
              </div>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MediaPlayerV2TimerModal;
