import { Skeleton } from "../Skeleton";

const QuestsSkeleton = ({ infoHeader }: { infoHeader?: string }) => {
  return (
    <div className="flex flex-col gap-6 w-full px-20 py-10 bg-brown-50">
      <div className="w-full">
        {infoHeader ? (
          <div className="heading-7">{infoHeader}</div>
        ) : (
          <Skeleton className="h-6 w-[285px] rounded-2xl bg-brown-100" />
        )}
      </div>
      <div className="flex w-full gap-x-6">
        {[...Array(4)].map((_, index) => (
          <div
            key={`quests-skeleton-loader-${
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              index
            }`}
            className="lex flex-col space-y-3"
          >
            <Skeleton className="h-[170px] w-[302px] rounded-xl bg-brown-100" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px] bg-brown-100" />
              <Skeleton className="h-4 w-[200px] bg-brown-100" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestsSkeleton;
