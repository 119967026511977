import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import { GET_CURRENT_PROFILE } from "services/graphql/queries/index";

function useUserProfile() {
  const { isAuthenticated } = useUserState();
  const { loading, data, error } = useQuery(GET_CURRENT_PROFILE, {
    skip: !isAuthenticated,
  });

  return {
    loading,
    data: data ?? null,
    error,
  };
}

export default useUserProfile;
