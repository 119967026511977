import { Skeleton } from "../Skeleton";

export const MyLibraryListSkeleton = () => {
  return (
    <div className="flex flex-col w-full  gap-y-8">
      <div className="flex flex-row justify-between w-full">
        <Skeleton className="w-40 min-w-10 h-6 bg-brown-100 rounded-2xl" />
        <Skeleton className="w-32 min-w-10 h-9 bg-brown-100 rounded-2xl" />
      </div>
      <div className="flex flex-col gap-y-8 w-full">
        {[...Array(3)].map((_, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <Skeleton className=" h-64 bg-brown-100 " key={index} />
        ))}
      </div>
    </div>
  );
};

export const MyLibraryAssignedSkeletion = () => {
  return (
    <div className="flex flex-col justify-start gap-y-5">
      <div className="w-full flex flex-col gap-y-1">
        <Skeleton className="rounded-full w-40 h-6 bg-brown-100" />
        <Skeleton className="h-4 w-full min-w-10 rounded-xl bg-brown-100" />
        <Skeleton className="h-4 w-3/4 min-w-10 rounded-xl bg-brown-100" />
      </div>
      <div>
        <Skeleton className=" h-64 bg-brown-100 " />
      </div>
      <div className="grid grid-cols-3 gap-6 w-full">
        {[...Array(6)].map((_, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div key={index} className="flex flex-col space-y-3">
            <Skeleton className="h-[170px] w-[302px] rounded-xl bg-brown-100" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px] bg-brown-100" />
              <Skeleton className="h-4 w-[200px] bg-brown-100" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const MyLibraryCompletedSkeleton = () => {
  return (
    <div className="flex flex-col justify-start gap-y-5">
      <div className="w-full flex flex-col gap-y-1">
        <Skeleton className="rounded-full w-40 h-6 bg-brown-100" />
      </div>
      <div className="grid grid-cols-5 gap-6 w-full">
        {[...Array(6)].map((_, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div key={index} className="flex flex-col space-y-3">
            <Skeleton className="h-[170px] w-full rounded-xl bg-brown-100" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px] bg-brown-100" />
              <Skeleton className="h-4 w-[200px] bg-brown-100" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
