import ProfileContentSkeleton from "components/loading/ProfileContentSkeleton";
import type { ICurrentProfile } from "types/interfaces";
import { AccountSection } from "../Profile/Profile";
import { ProfilePage } from "./ProfilePage";
import { SecuritySettings } from "./SecuritySettings";

export default function MyAccount({
  section,
  profileData,
  showEditProfilePage,
  loading,
}: {
  section: string;
  profileData?: ICurrentProfile;
  editProfilePageOpen?: boolean;
  showEditProfilePage: (show: boolean) => void;
  loading?: boolean;
}) {
  if (loading) return <ProfileContentSkeleton />;

  return (
    <>
      {section === AccountSection.Profile && (
        <ProfilePage onEdit={() => showEditProfilePage(true)} profileData={profileData} loading={loading} />
      )}
      {section === AccountSection.SecuritySettings && <SecuritySettings profileData={profileData} />}
    </>
  );
}
