import { useEffect, useRef, useState } from "react";
import { useMediaPlayerContext } from "context/MediaPlayerV2Context";
import { cn } from "libs/classMerger";
import MediaPlayerV2Header from "./MediaPlayerV2Header";
import MediaPlayerV2VideoController from "./MediaPlayerV2VideoController";
import MediaPlayerV2AudioController from "./MediaPlayerV2AudioController";
import MediaPlayerV2InfoBar from "./MediaPlayerV2InfoBar";
import MediaPlayerV2Controls from "./MediaPlayerV2Controls";
import MediaMiniPlayerV2Controls from "./MediaMiniPlayerV2Controls";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";

const MediaPlayerV2 = () => {
  const {
    show,
    isPlaying,
    track,
    fullScreenMode,
    time,
    isTimerSet,
    seekBarRef,
    seekBgRef,
    audioPlayerRef,
    mixerAudioPlayerRef,
    videoPlayerRef,
    mixerTrack,
    close,
    soundscapeMode,
    immersionEnabled,
    // toggleImmersion,
  } = useMediaPlayerContext();

  const [showPlayer, setShowPlayer] = useState<boolean | undefined>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean | undefined>(false);
  const [hideControls, setHideControls] = useState(false);

  const hideControlsTimer = useRef<NodeJS.Timeout | null>(null);

  const {
    play,
    pause,
    toggleFullScreen,
    handleForward,
    handleSeek,
    handleRewind,
    toggleImmersion,
  } = useMediaPlayerHook();

  // Sync player context updates
  useEffect(() => {
    setShowPlayer(show);
    setIsFullscreen(fullScreenMode);

    return () => {
      if (hideControlsTimer.current) clearTimeout(hideControlsTimer.current);
    };
  }, [show, fullScreenMode, track?.imagePath]);

  // Mouse movement logic
  const handleMouseMove = () => {
    setHideControls(false);

    if (hideControlsTimer.current) clearTimeout(hideControlsTimer.current);

    hideControlsTimer.current = setTimeout(() => {
      setHideControls(true);
    }, 5000); // 5 seconds
  };

  const handleMouseEnter = () => {
    if (hideControlsTimer.current) clearTimeout(hideControlsTimer.current);
    setHideControls(false);
  };

  // Player actions
  const onPlayPause = () => {
    isPlaying ? pause() : play();
  };

  const playerClass = cn(
    "group w-full z-[100] fixed bg-black/90 backdrop-blur-md flex-col items-center inline-flex",
    showPlayer ? "bottom-[0px]" : "bottom-[-90px]",
    isFullscreen
      ? "h-screen w-screen bg-black/90 animate-slideInBottom"
      : "animate-slideInBottom"
  );

  return (
    <div
      className={playerClass}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
    >
      {/* Audio Controllers */}
      <MediaPlayerV2AudioController // not controls -> player
        src={track?.file ?? ""}
        loop={isTimerSet}
        audioPlayerRef={audioPlayerRef}
      />
      <MediaPlayerV2AudioController
        src={mixerTrack?.file ?? ""}
        loop={isTimerSet}
        audioPlayerRef={mixerAudioPlayerRef}
      />

      {/* Fullscreen Header */}
      {isFullscreen && (
        <MediaPlayerV2Header
          onFullScreen={() => toggleFullScreen()}
          title="Now Playing"
          subtitle="Soundscape"
        />
      )}

      {/* Video Controller */}
      <div className="relative w-full h-full">
        {isFullscreen && (
          <MediaPlayerV2VideoController
            videoPlayerRef={videoPlayerRef}
            src={
              "https://assets.mindvalley.com/api/v1/assets/0defafbf-4cda-42ca-bf23-02da3d5e6a4c.mp3?token=3fd69bdef6d292014010bf3e35c36568.m9VEqnZPfxx6Er0Rt-O9Hs5EDOXS.9d060f35e207303d98960c58be024c5e5cb4f1fa00066e14b29d262ff5ed8e0f"
            }
            poster={track?.imagePath || ""}
            muted={true}
            loop={isTimerSet}
            autoPlay={false}
            mimeType={"video/mp4"}
            cssClass={`${
              isFullscreen
                ? "animate-slideInBottom"
                : "animate-slide-out-bottom"
            }`}
            onPlay={play}
            onPause={pause}
            onClick={onPlayPause}
            immersionSrc={
              "https://videos.pexels.com/video-files/1918465/1918465-uhd_2560_1440_24fps.mp4"
            }
          />
        )}
      </div>

      {/* Info Bar and Controls */}
      {showPlayer && isFullscreen && (
        <div
          className={cn(
            "w-full bg-gradient-to-t from-black/90 absolute h-[400px]",
            hideControls
              ? "bottom-[-200px] transform transition-all ease-in-out duration-700"
              : "bottom-[0px] transform transition-all ease-in-out duration-700"
          )}
        >
          <MediaPlayerV2InfoBar
            title={track?.title ?? ""}
            subtitle={track?.author ?? ""}
            imagePath={track?.imagePath ?? ""}
            soundscapeMode={soundscapeMode ?? false}
          />
          <MediaPlayerV2Controls
            fullScreenMode={fullScreenMode ?? false}
            soundscapeMode={soundscapeMode ?? false}
            trackState={time}
            onSeek={handleSeek}
            seekBarRef={seekBarRef}
            seekBgRef={seekBgRef}
            imagePath={track?.imagePath ?? ""}
            title={track?.title ?? ""}
            author={track?.author ?? ""}
            isPlaying={isPlaying ?? false}
            onPlayPause={onPlayPause}
            onFullScreen={() => toggleFullScreen()}
            onRewind={() => handleRewind()}
            onForward={() => handleForward()}
            immersionEnabled={immersionEnabled}
            toggleImmersion={toggleImmersion}
            audioPlayerRef={audioPlayerRef}
          />
        </div>
      )}

      {/* Mini Player Controls */}
      {showPlayer && !isFullscreen && (
        <div
          className={
            "w-full absolute bottom-[0px] h-[87px] md:h-[75px] lg:h-[83px] animate-slideInBottom"
          }
        >
          <MediaMiniPlayerV2Controls
            trackState={time}
            fullScreenMode={isFullscreen ?? false}
            soundscapeMode={soundscapeMode ?? false}
            seekBarRef={seekBarRef}
            onSeek={handleSeek}
            seekBgRef={seekBgRef}
            imagePath={track?.imagePath}
            title={track?.title ?? ""}
            author={track?.author ?? ""}
            isPlaying={isPlaying ?? false}
            onPlayPause={onPlayPause}
            onClose={() => close()}
            onFullScreen={() => toggleFullScreen()}
            onRewind={() => handleRewind()}
            onForward={() => handleForward()}
            audioPlayerRef={audioPlayerRef}
            onValueChange={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default MediaPlayerV2;
