import { DrawerClose } from "components/ui/drawer";
import { cn } from "libs/classMerger";
import type { IOption } from "types/interfaces";
import "../Programs.css";
import Icons from "components/icons/Icons";

export const Menu = ({
  currentOption,
  title,
  options,
  onSelectOption,
}: {
  title: string;
  options: IOption[];
  onSelectOption: (option: IOption) => void;
  currentOption?: IOption;
}) => {
  return (
    <div className="w-full bg-white justify-center align-center rounded-tr-lg rounded-tl-lg pb-40 md:pb-30">
      <div className="grid px-2 py-[6px] justify-items-center">
        <div className="w-[80px] h-[6px] rounded-sm bg-cool-grey-300"> </div>
      </div>
      <div className="flex justify-center py-3 px-9 heading-8">{title}</div>
      <div className="h-[1px] rounded-sm bg-cool-grey-300"> </div>
      <div className="w-full flex flex-col justify-start align-start gap-2 self-stretch px-7 py-3">
        {options.map((option: IOption) => (
          <DrawerClose key={option.value} asChild onClick={() => onSelectOption(option)}>
            <div className={cn("flex flex-row justify-between px-2 py-2 cursor-pointer", option.name)}>
              <span className="heading-6">{option.name}</span>
              <div className={cn(currentOption?.value === option.value ? "flex" : "hidden")}>
                <Icons name="check-filled" fill="#292D38" height={24} width={24} />
              </div>
            </div>
          </DrawerClose>
        ))}
      </div>
    </div>
  );
};
