import { Divider } from "@fluentui/react-components";
import { Skeleton } from "../Skeleton";

const ProfileContentSkeleton = () => {
  return (
    <div className="flex flex-col w-full  gap-y-8">
      <div className="flex flex-row justify-between w-full">
        <Skeleton className="w-40 min-w-10 h-6 bg-brown-100 rounded-2xl" />
        <Skeleton className="w-32 min-w-10 h-9 bg-brown-100 rounded-2xl" />
      </div>
      {[...Array(2)].map((_, index) => (
        <div className="w-3/4 flex md:flex-row flex-col justify-between gap-y-4">
          {[...Array(2)].map((_, index) => (
            <div className="flex flex-col gap-2">
              <div className="flex justify-start gap-4">
                <Skeleton className="rounded-full w-5 h-5 bg-brown-100" />
                <Skeleton className="h-5 w-28 min-w-10 rounded-xl bg-brown-100" />
              </div>
              <Skeleton className=" h-5 w-80 min-w-10 bg-brown-100" />
            </div>
          ))}
        </div>
      ))}
      <div className="pt-8">
        <Divider />
      </div>
      <div className="flex flex-col gap-y-4">
        <Skeleton className="w-52 min-w-10 h-5 rounded-2xl bg-brown-100" />
        <div className="flex flex-col gap-y-1">
          {[...Array(8)].map((_, index) => (
            <Skeleton className=" h-3 bg-brown-100 w-full" />
          ))}
        </div>
        <div className="flex flex-col gap-y-1">
          {[...Array(8)].map((_, index) => (
            <Skeleton className=" h-3 bg-brown-100 w-full" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileContentSkeleton;
