import * as Avatar from "@radix-ui/react-avatar";
import { cn } from "libs/classMerger";
import type React from "react";

// Define the props for the Avatar component
interface AvatarProps {
  imageUrl?: string;
  fallbackText?: string;
  className?: string; // Accept a className prop for custom styling
}

const NavbarAvatar: React.FC<AvatarProps> = ({
  imageUrl,
  fallbackText = "MV",
  className = "", // Default to empty string if no className is provided
}) => {
  return (
    <Avatar.Root className={cn("rounded-full cursor-pointer hidden lg:block", className)}>
      <Avatar.Image
        className="h-full w-full object-cover rounded-full"
        src={imageUrl ?? "/fallback/default-avatar.svg"}
        alt="User Avatar"
      />
    </Avatar.Root>
  );
};

export default NavbarAvatar;
