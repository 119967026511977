import BannerSkeleton from "components/loading/BannerSkeleton";
import useSoundscapesOfTheWeek from "hooks/useSoundscapesOfTheWeek";
import { useEffect, useState } from "react";
import FeaturedListComponent from "../../../components/FeaturedListComponent";

const FeaturedSoundsList = () => {
  const { soundscapesOfTheWeek, loading, error } = useSoundscapesOfTheWeek();
  useEffect(() => {
    if (!loading) {
      setBanner(soundscapesOfTheWeek[0].coverAsset?.url ?? "");
      setSelectedTrackBanner(soundscapesOfTheWeek[0].coverAsset?.url ?? "");
    }
  }, [soundscapesOfTheWeek]);
  const [banner, setBanner] = useState("");
  const [selectedTrackBanner, setSelectedTrackBanner] = useState(banner);
  if (error) return null;

  return (
    <>
      {loading ? (
        <BannerSkeleton circleThumbnail={true} />
      ) : (
        <section className="container">
          <FeaturedListComponent
            title="Soundscapes of the week"
            description="Experience the power of sound to improve well-being and job performance. Our soundscapes offer a moment of escape without leaving your desk."
            backdropImagePath={banner}
            buttonLink="/"
            data={soundscapesOfTheWeek}
            onHover={(image: string) => setBanner(image)}
            isSoundScapeOnly
            onLeave={() => setBanner(selectedTrackBanner)}
            onClick={(image: string) => setSelectedTrackBanner(image)}
          />
        </section>
      )}
    </>
  );
};

export default FeaturedSoundsList;
