import { Skeleton } from "@fluentui/react-components";
import { cn } from "libs/classMerger";

const BannerSkeleton = ({ circleThumbnail = false }: { circleThumbnail?: boolean }) => {
  return (
    <Skeleton className="container flex h-4 w-[250px] bg-brown-50 h-auto md:min-h-[440px] xl:h-[500px] w-full rounded-xl">
      <div className="flex p-12 gap-6 flex-col lg:flex-row overfow-hidden w-full">
        <div className="flex flex-col gap-3 w-full">
          <Skeleton className="h-8 bg-brown-100 h-8 rounded-xl" />
          <Skeleton className="h-8 w-[250px] md:w-[300px] bg-brown-100 h-8 rounded-xl overflow-hidden" />
          <div className="flex gap-1 flex-col w-inherit overflow-hidden">
            {[...Array(3)].map((_, index) => (
              <Skeleton className="h-4 rounded-xl bg-brown-100" />
            ))}
            <Skeleton className="h-4 w-[300px] md:w-[384px] rounded-xl bg-brown-100" />
          </div>
          <Skeleton className="h-10 w-[167px] rounded-xl bg-brown-100 hidden lg:flex" />
        </div>
        <div className="flex shrink-1 overflow-hidden gap-3 w-full">
          {[...Array(4)].map((_, index) => (
            <div className="flex flex-col gap-3 md:w-[419px]">
              <Skeleton
                className={cn("w-40 h-40 w-[167px]  bg-brown-100", circleThumbnail ? "rounded-full" : "rounded-xl")}
              />
              <Skeleton className="w-40 h-3 w-[167px] rounded-xl bg-brown-100" />
              <Skeleton className="w-40 h-2 w-[167px] rounded-xl bg-brown-100" />
              <Skeleton className="w-40 h-2 w-[80px] rounded-xl bg-brown-100" />
            </div>
          ))}
        </div>
        <Skeleton className="h-10 w-[167px] rounded-xl bg-brown-100 flex lg:hidden" />
      </div>
    </Skeleton>
  );
};

export default BannerSkeleton;
