import { Skeleton } from "../Skeleton";

const ProfileCardSkeleton = () => {
  return (
    <div className="lg:flex lg:w-[300px] py-6 px-4 md:px-9 lg:px-6 bg-brown-50 flex flex-col items-center rounded-3xl space-y-6 ">
      <Skeleton className="w-full h-full flex flex-col gap-y-4 ">
        <Skeleton className="rounded-full w-24 h-24 self-center  bg-brown-100" />
        <Skeleton className="w-full h-6 bg-brown-100" />
        <Skeleton className="w-full h-6 bg-brown-100" />
      </Skeleton>
    </div>
  );
};

export default ProfileCardSkeleton;
