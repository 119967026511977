import { Carousel, CarouselContent, CarouselItem } from "components/Carousel";
import FeatureCardSkeleton from "components/loading/FeatureCardSkeleton";
import { QuestCard, cardVariantEnum } from "components/quest/QuestCard";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import useGetPickedForYouQuestIds from "hooks/useGetPickedForYouQuests";
import type { IQuest } from "types/interfaces";

export default function PickedForYouSection() {
  const { pickedForYouRecommendations: questsData, loading: fetchingQuests, error } = useGetPickedForYouQuestIds();
  const { isAuthenticated, userAccessState } = useUserState();
  const quests = questsData || [];

  if (fetchingQuests) return <FeatureCardSkeleton />;
  if (error) return <div className="px-5">Error: {error.message}</div>;
  // Temporary fallback
  if (quests.length === 0) {
    return (
      <div className="px-5">
        <div className="heading-6">Picked for you</div>
        <div className="caption-disclaimer">No quests available at the moment.</div>
      </div>
    );
  }
  return (
    <section className="container">
      <div className="flex flex-col w-full gap-6">
        <div className="flex justify-between">
          <div className="flex flex-col gap-[2px] justify-center">
            <div className="heading-6">Picked for you</div>
            {isAuthenticated && (
              <div className="caption-disclaimer hidden lg:flex">Quests based on your organization’s goals</div>
            )}
          </div>
          {/* <div className="flex gap-1 md:flex lg:hidden px-1">
          <CarouselPrevious onClick={() => carouselApi?.scrollPrev()} />
          <CarouselNext onClick={() => carouselApi?.scrollNext()} />
        </div> */}
        </div>

        <Carousel
          opts={{
            align: "start",
            containScroll: "trimSnaps",
            active: false,
          }}
          orientation="horizontal"
          className="w-full"
        >
          <CarouselContent>
            {quests.map((quest: IQuest) => (
              <CarouselItem
                key={quests.id + quest.name}
                className="py-4 lg:py-6 w-sm md:w-md lg:w-lg basis-full md:basis-1/2 lg:basis-1/4 xl:basis-4/4"
              >
                <QuestCard
                  quest={quest}
                  cardVariant={cardVariantEnum.variant_1}
                  canHover={userAccessState === UserAccessStateEnum.Premium}
                  userAccessState={userAccessState}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </section>
  );
}
