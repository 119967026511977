import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import type { IPage, IQuest } from "types/interfaces";
import { getLessonsStatus } from "./utils";

const getLessonStatusLabel = (page: IPage, quest: IQuest) => {
  const { total } = getLessonsStatus(quest.type, quest.userProgress);
  switch (page?.type) {
    case "intro":
      return `Intro ${page?.position}`;
    case "day":
    case "lesson":
      return `Lesson ${page.position} of ${total}`;
    default:
      return "";
  }
};

const QuestDetails = ({
  quest,
  page,
  onMarkLessonAsComplete,
  onStartProgram,
}: {
  quest: IQuest;
  page?: IPage;
  onMarkLessonAsComplete: (page?: IPage) => void;
  onStartProgram: () => void;
}) => {
  return (
    <div className="space-y-3" data-testid="quest-lesson-intro">
      <div className="space-y-1">
        <h1 className="title-6 text-cool-grey-700">{page?.name}</h1>
        <p className="title-7 text-cool-grey-500 capitalize" data-testid="quest-lesson-sub-header">
          {page && quest && getLessonStatusLabel(page, quest)}
        </p>
      </div>

      <div className="flex items-start">
        <div className="flex gap-3 items-center">
          {!page?.completed &&
            (!page?.completed && page?.position === Math.max(...quest.pages.map((page) => page.position)) ? (
              <Button variant={"primary"} onClick={() => onMarkLessonAsComplete(page)}>
                Complete Quest
              </Button>
            ) : (
              <Button variant={"primary"} onClick={() => onMarkLessonAsComplete(page)}>
                Mark as complete
                <Icons name="chevron-right-filled" />
              </Button>
            ))}
          {page?.completed && (
            <div className="w-fit flex items-center button-text-medium gap-1 bg-green-50 text-green-600 rounded-lg px-3 py-2">
              <Icons name="checklist-circle-filled" className="text-green" />
              Completed
            </div>
          )}
          {page?.tasks && page?.tasks.length > 0 && (
            <div className="flex gap-1.5 items-center text-cool-grey-500">
              <Icons name="edit-outlined" />
              <p className="body-small text-center">
                {page?.tasks?.filter((task) => task.completed).length}/{page?.tasks?.length} tasks completed
              </p>
            </div>
          )}

          {!quest.userProgress?.started && (
            <Button variant={"primary"} className="gap-2 px-10" onClick={onStartProgram}>
              <Icons name="play-filled" />
              Start Programsss
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestDetails;
