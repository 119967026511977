import React, { Dispatch, SetStateAction, useState } from "react";
import { cn } from "libs/classMerger";
import Icons from "../icons/Icons";
import { Button } from "../Button";
import { Tooltip } from "../ui/tooltip";
import MediaPlayerV2SeekBar from "./MediaPlayerV2SeekBar";
import MediaPlayerV2VolumeControl from "./MediaPlayerV2VolumeControl";
import MediaPlayerV2TimerModal from "./MediaPlayerV2TimerModal";
import MediaPlayerV2Mixer from "./MediaPlayerV2Mixer";
import MediaPlayerV2Header from "./MediaPlayerV2Header";

interface MediaPlayerV2ControlsProps {
  seekBarRef: React.LegacyRef<HTMLDivElement>;
  seekBgRef: React.LegacyRef<HTMLDivElement>;
  title: string;
  author: string;
  imagePath: string;
  fullScreenMode: boolean;
  isPlaying: boolean;
  trackState?: any;
  soundscapeMode: boolean;
  onPlayPause: React.MouseEventHandler<HTMLButtonElement>;
  onFullScreen: React.MouseEventHandler<HTMLButtonElement>;
  onRewind: React.MouseEventHandler<HTMLButtonElement>;
  onForward: React.MouseEventHandler<HTMLButtonElement>;
  onSeek: (value: number) => void;
  immersionEnabled: boolean;
  toggleImmersion: React.MouseEventHandler<HTMLButtonElement>;
  audioPlayerRef: any;
}

const MediaPlayerV2Controls = ({
  seekBgRef,
  immersionEnabled,
  soundscapeMode,
  toggleImmersion,
  seekBarRef,
  onSeek,
  trackState,
  imagePath,
  title,
  author,
  onPlayPause,
  onFullScreen,
  onRewind,
  onForward,
  fullScreenMode,
  audioPlayerRef,
  isPlaying,
}: MediaPlayerV2ControlsProps) => {
  const MediaPlayerV2ControlsDesktop = () => {
    const [hideControls, setHideControls] = useState(false);

    let cls = cn(
      "w-full",
      fullScreenMode
        ? "absolute bottom-[34px] lg:bottom-[12px] px-4 lg:px-10"
        : "fixed",
      fullScreenMode && hideControls ? " hidden" : ""
    );

    return (
      <div className={cls}>
        <div
          className="w-full hidden bg-white/25 justify-start items-center"
          ref={seekBgRef}
        >
          <div
            className="w-[448px] h-[3px] bg-[#e85546]"
            ref={seekBarRef}
          ></div>
        </div>
        <MediaPlayerV2SeekBar
          audioPlayerRef={audioPlayerRef}
          soundscapeMode={soundscapeMode}
          fullScreenMode
          showDuration={true}
          progress={trackState?.progress}
          onValueChange={(value: number[]) => {
            onSeek(value[0]);
          }}
          className="py-1"
        />
        <div className="relative w-full h-16 flex justify-between">
          <div className="flex gap-3 items-center z-10">
            {/* <Tooltip delayDuration={0} tooltipContent={<p className="caption-disclaimer">Favorite</p>}>
                            <Button variant="icon" size="icon" className="text-white/70 hover:text-white">
                                <Icons name="bookmark-outlined" width={20} height={20}/>
                            </Button>
                        </Tooltip> */}
            <Button
              variant="icon"
              size="icon"
              className="text-white/70 cursor-default"
            >
              <Icons name="bookmark-outlined" width={20} height={20} />
            </Button>
          </div>
          <div className="flex gap-6 justify-center items-center absolute inset-0 z-9">
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">15 sec rewind</p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className={cn(
                  "text-white/70 hover:text-white",
                  soundscapeMode ? "hidden" : "inline-flex"
                )}
                onClick={onRewind}
              >
                <Icons name="rewind-15-outlined" width={24} height={24} />
              </Button>
            </Tooltip>
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">
                  {isPlaying ? "Pause" : "Play"}
                </p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className="text-white/70 hover:text-white"
                onClick={onPlayPause}
              >
                {isPlaying ? (
                  <Icons name="pause-circle-filled" width={56} height={56} />
                ) : (
                  <Icons name="play-circle-filled" width={56} height={56} />
                )}
              </Button>
            </Tooltip>
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">15 sec forward</p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className={cn(
                  "text-white/70 hover:text-white",
                  soundscapeMode ? "hidden" : "inline-flex"
                )}
                onClick={onForward}
              >
                <Icons name="forward-15-outlined" width={24} height={24} />
              </Button>
            </Tooltip>
          </div>
          <div className="flex items-center gap-3.5 z-10">
            <div className="flex items-center gap-3">
              <Tooltip
                delayDuration={0}
                tooltipContent={
                  <p className="caption-disclaimer">
                    {immersionEnabled
                      ? "Disable immersive"
                      : "Enable immersive"}{" "}
                  </p>
                }
              >
                <Button
                  variant="icon"
                  size="icon"
                  className="text-white/70 hover:text-white "
                  onClick={toggleImmersion}
                >
                  <div className="flex flex-col relative">
                    <Icons
                      name="meditation-outlined"
                      width={20}
                      height={20}
                      className={cn(
                        immersionEnabled ? "svg-icon-feature-enabled" : ""
                      )}
                      fill={immersionEnabled ? "#FF6D5C" : "white"}
                    />
                    {immersionEnabled && (
                      <Icons
                        name="circle-filled"
                        width={4}
                        height={4}
                        fill="#FF6D5C"
                        className="p-0 m-0 absolute justify-center text-center left-[40%]"
                      />
                    )}
                  </div>
                </Button>
              </Tooltip>
              {soundscapeMode ? (
                <Tooltip
                  delayDuration={0}
                  tooltipContent={<p className="caption-disclaimer">Timer</p>}
                >
                  <Button
                    variant="icon"
                    size="icon"
                    className="text-white/70 hover:text-white"
                  >
                    {/* <Icons name='stopwatch-outlined' width={20} height={20}/> */}
                    <MediaPlayerV2TimerModal fullScreenMode={false} />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  delayDuration={0}
                  tooltipContent={<p className="caption-disclaimer">Mixer</p>}
                >
                  <Button
                    variant="icon"
                    size="icon"
                    className="text-white/70 hover:text-white"
                  >
                    {/* <Icons
                                name="sliders-outlined" width={20} height={20}
                            /> */}
                    <MediaPlayerV2Mixer fullScreenMode={false} />
                  </Button>
                </Tooltip>
              )}
              {/* <Tooltip
                            delayDuration={0}
                            tooltipContent={<p className="caption-disclaimer">Collection</p>}
                        >
                            <Button variant="icon" size="icon" className="text-white/70 hover:text-white">
                            <Icons
                                name="list-outlined" width={20} height={20}
                            />
                            </Button>
                        </Tooltip> */}
              {/* <Button variant="icon" size="icon" className="text-white/70 cursor-default">
                            <Icons
                                name="list-outlined" width={20} height={20}
                            />
                            </Button> */}
              <div className="flex items-center gap-2">
                {/* <Tooltip
                                delayDuration={0}
                                tooltipContent={<p className="caption-disclaimer">Mute</p>}
                            >
                                <Button variant="icon" size="icon" className="text-white/70 hover:text-white" onClick={onPlayPause}>
                                    {isPlaying ? (
                                        <Icons
                                        name="volume-2-outlined" width={20} height={20}
                                        />
                                    ) : (
                                        <Icons
                                        name="volume-x-outlined" width={20} height={20}
                                        />
                                    )}
                                </Button>
                            </Tooltip> */}
                <MediaPlayerV2VolumeControl
                  audioPlayerRef={audioPlayerRef}
                  className="w-[100px]"
                />
              </div>
            </div>
            <div className="flex items-center gap-3.5">
              {/* <Tooltip
                            delayDuration={0}
                            tooltipContent={<p className="caption-disclaimer">Enable PiP</p>}
                        >
                            <Button
                            variant="icon"
                            size="icon"
                            className="text-white/70 hover:text-white"
                            >
                            <Icons
                                name="pip-return-outlined" width={16} height={16}
                            />
                            </Button>
                        </Tooltip> */}
              {/* <Button
                            variant="icon"
                            size="icon"
                            className="text-white/70 cursor-default"
                            >
                            <Icons
                                name="pip-return-outlined" width={16} height={16}
                            />
                            </Button> */}
              <Tooltip
                delayDuration={0}
                tooltipContent={
                  <p className="caption-disclaimer">Exit full screen</p>
                }
              >
                <Button
                  variant="icon"
                  size="icon"
                  className="text-white/70 hover:text-white"
                  onClick={onFullScreen}
                >
                  <Icons name="minimize-2-outlined" width={16} height={16} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const MediaPlayerV2ControlsMobile = () => {
    const [hideControls, setHideControls] = useState(false);

    let cls = cn(
      "w-full",
      fullScreenMode
        ? "absolute bottom-[34px] lg:bottom-[12px] px-4 lg:px-10"
        : "fixed",
      fullScreenMode && hideControls ? " hidden" : ""
    );

    return (
      <div className={cls}>
        <div
          className="w-full hidden bg-white/25 justify-start items-center"
          ref={seekBgRef}
        >
          <div
            className="w-[448px] h-[3px] bg-[#e85546]"
            ref={seekBarRef}
          ></div>
        </div>
        <MediaPlayerV2SeekBar
          audioPlayerRef={audioPlayerRef}
          soundscapeMode
          fullScreenMode
          showDuration={true}
          progress={trackState?.progress}
          onValueChange={(value: number[]) => {
            onSeek(value[0]);
          }}
          className="py-1"
        />
        <div className="relative w-full h-16 flex justify-between">
          <div className="flex gap-3 items-center z-10">
            {/* <Tooltip delayDuration={0} tooltipContent={<p className="caption-disclaimer">Favorite</p>}>
                            <Button variant="icon" size="icon" className="text-white/70 hover:text-white">
                                <Icons name="bookmark-outlined" width={20} height={20}/>
                            </Button>
                        </Tooltip> */}
            <Button
              variant="icon"
              size="icon"
              className="text-white/70 cursor-default"
            >
              <Icons name="bookmark-outlined" width={20} height={20} />
            </Button>
          </div>
          <div className="flex gap-6 justify-center items-center absolute inset-0 z-9">
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">15 sec rewind</p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className={cn(
                  "text-white/70 hover:text-white",
                  soundscapeMode ? "hidden" : "inline-flex"
                )}
                onClick={onRewind}
              >
                <Icons name="rewind-15-outlined" width={24} height={24} />
              </Button>
            </Tooltip>
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">
                  {isPlaying ? "Pause" : "Play"}
                </p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className="text-white/70 hover:text-white"
                onClick={onPlayPause}
              >
                {isPlaying ? (
                  <Icons
                    name="pause-circle-filled"
                    className="w-[56px] h-[56px] lg:w-[40px] lg:h-[40px]"
                  />
                ) : (
                  <Icons
                    name="play-circle-filled"
                    className="w-[56px] h-[56px] lg:w-[40px] lg:h-[40px]"
                  />
                )}
              </Button>
            </Tooltip>
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">15 sec forward</p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className={cn(
                  "text-white/70 hover:text-white",
                  soundscapeMode ? "hidden" : "inline-flex"
                )}
                onClick={onForward}
              >
                <Icons name="forward-15-outlined" width={24} height={24} />
              </Button>
            </Tooltip>
          </div>
          <div className="flex items-center gap-3.5 z-10">
            <div className="flex items-center gap-3">
              <Tooltip
                delayDuration={0}
                tooltipContent={
                  <p className="caption-disclaimer">
                    {immersionEnabled
                      ? "Disable immersive"
                      : "Enable immersive"}{" "}
                  </p>
                }
              >
                <Button
                  variant="icon"
                  size="icon"
                  className="text-white/70 hover:text-white"
                  onClick={toggleImmersion}
                >
                  <Icons
                    name="meditation-outlined"
                    width={20}
                    height={20}
                    className={cn(
                      immersionEnabled ? "svg-icon-feature-enabled" : ""
                    )}
                  />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="inline-flex lg:hidden">
          {soundscapeMode ? (
            <MediaPlayerV2TimerModal fullScreenMode={true} />
          ) : (
            <MediaPlayerV2Mixer fullScreenMode={true} />
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="w-full h-[inherit]">
      <div className="w-full flex lg:hidden h-[inherit]">
        <MediaPlayerV2ControlsMobile />
      </div>
      <div className="w-full hidden lg:flex h-[inherit]">
        <MediaPlayerV2ControlsDesktop />
      </div>
    </div>
  );
};

export default MediaPlayerV2Controls;
