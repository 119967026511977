import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import { cn } from "libs/classMerger";
import * as React from "react";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-full button-text-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary: "bg-red-600 hover:bg-gradient-to-b from-red-400 to-red-600 text-white",
        neutral: "bg-cool-grey-700 hover:bg-cool-grey-600 text-white",
        secondary: "bg-cool-grey-300 text-secondary-foreground hover:bg-secondary/80",
        tertiary: "text-red-600 hover:bg-red-4a",
        // destructive:
        //   "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border-2 border-cool-grey-700 bg-background hover:bg-accent hover:text-accent-foreground",
        ghost: "button-text text-cool-grey-700 py-3.5 px-6 rounded-100 cursor-pointer text-center hover:bg-black-4a",
        link: "text-red-600",
        "caption-disclaimer": "caption-disclaimer",
        icon: "",
        roundedIcon: "rounded-full bg-brown-12a",
      },
      size: {
        default: "h-10 px-3.5 py-3",
        sm: "py-1.5 px-3",
        // lg: "h-10 rounded-md px-8",
        icon: "p-0",
        roundedIcon: "p-0 size-9",
        link: "p-0",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
