import type { RefObject } from "react";
import { cn } from "libs/classMerger";
import Icons from "../icons/Icons";
import { Button } from "../Button";
import { Tooltip } from "../ui/tooltip";
import MediaPlayerV2SeekBar from "./MediaPlayerV2SeekBar";
import MediaPlayerV2VolumeControl from "./MediaPlayerV2VolumeControl";
import MediaPlayerV2TimerModal from "./MediaPlayerV2TimerModal";
import MediaPlayerV2Mixer from "./MediaPlayerV2Mixer";

interface MediaMiniPlayerV2ControlsProps {
  seekBarRef: RefObject<HTMLDivElement>;
  seekBgRef: RefObject<HTMLDivElement>;
  title: string;
  author: string;
  imagePath?: string;
  fullScreenMode: boolean;
  trackState?: any;
  isPlaying: boolean;
  soundscapeMode: boolean;
  audioPlayerRef: RefObject<HTMLAudioElement>;
  onPlayPause: React.MouseEventHandler<HTMLButtonElement>;
  onFullScreen: React.MouseEventHandler<HTMLButtonElement>;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onRewind: React.MouseEventHandler<HTMLButtonElement>;
  onForward: React.MouseEventHandler<HTMLButtonElement>;
  onSeek: (value: number) => void;
  onValueChange: (value: number[]) => void;
}

const MediaMiniPlayerV2Controls = ({
  seekBgRef,
  seekBarRef,
  imagePath,
  title,
  author,
  isPlaying,
  soundscapeMode,
  onSeek,
  onPlayPause,
  onFullScreen,
  onClose,
  onRewind,
  onForward,
  trackState,
  fullScreenMode,
  onValueChange,
  audioPlayerRef,
}: MediaMiniPlayerV2ControlsProps) => {
  const MediaMiniPlayerV2Desktop = () => {
    return (
      <div
        className={cn(
          "bg-black w-full h-[inherit]",
          fullScreenMode ? "absolute bottom-[100px]" : "fixed"
        )}
      >
        <MediaPlayerV2SeekBar
          showDuration={false}
          fullScreenMode={false}
          soundscapeMode
          progress={trackState?.progress}
          onValueChange={(value: number[]) => {
            onSeek(value[0]);
          }}
          audioPlayerRef={audioPlayerRef}
        />
        <div className="relative w-full h-[inherit] px-6 py-2 flex justify-between">
          <div className="flex items-center z-10">
            {imagePath ? (
              <div
                className={cn(
                  "size-[56px] object-cover",
                  soundscapeMode ? "rounded-full" : "rounded-lg"
                )}
              >
                <img
                  src={imagePath}
                  className={cn(soundscapeMode ? "rounded-full" : "rounded-lg")}
                  alt=""
                />
              </div>
            ) : null}
            <div
              className={cn(
                "flexflex-col items-start",
                title ? "ml-3 mr-4" : "m-0"
              )}
            >
              <div className="title-9 text-white max-w-[216px] w-full truncate">
                {title}
              </div>
              <div className="caption-disclaimer text-[#b3b8c1]">{author}</div>
            </div>
            {/* <Tooltip
            delayDuration={0}
            tooltipContent={<p className="caption-disclaimer">Favorite</p>}
          >
            <Button variant="icon" size="icon" className="text-white/70 hover:text-white">
              <Icons name="bookmark-outlined" width={20} height={20}/>
            </Button>
          </Tooltip> */}
            {/* <Button variant="icon" size="icon" className="text-white/70 cursor-default">
              <Icons name="bookmark-outlined" width={20} height={20}/>
            </Button> */}
          </div>
          <div className="flex gap-6 justify-center items-center absolute inset-0 z-9">
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">15 sec rewind</p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className={cn(
                  "text-white/70 hover:text-white",
                  soundscapeMode ? "hidden" : "inline-flex"
                )}
                onClick={onRewind}
              >
                <Icons name="rewind-15-outlined" width={24} height={24} />
              </Button>
            </Tooltip>
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">
                  {isPlaying ? "Pause" : "Play"}
                </p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className="text-white/70 hover:text-white"
                onClick={onPlayPause}
              >
                {isPlaying ? (
                  <Icons name="pause-circle-filled" width={40} height={40} />
                ) : (
                  <Icons name="play-circle-filled" width={40} height={40} />
                )}
              </Button>
            </Tooltip>
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">15 sec forward</p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className={cn(
                  "text-white/70 hover:text-white",
                  soundscapeMode ? "hidden" : "inline-flex"
                )}
                onClick={onForward}
              >
                <Icons name="forward-15-outlined" width={24} height={24} />
              </Button>
            </Tooltip>
          </div>
          <div className="flex items-center gap-3.5 z-10">
            <div className="flex items-center gap-3">
              {soundscapeMode ? (
                <Tooltip
                  delayDuration={0}
                  tooltipContent={<p className="caption-disclaimer">Timer</p>}
                >
                  <Button
                    variant="icon"
                    size="icon"
                    className="text-white/70 hover:text-white"
                  >
                    {/* <Icons name='stopwatch-outlined' width={20} height={20}/> */}
                    <MediaPlayerV2TimerModal fullScreenMode={false} />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  delayDuration={0}
                  tooltipContent={<p className="caption-disclaimer">Mixer</p>}
                >
                  <Button
                    variant="icon"
                    size="icon"
                    className="text-white/70 hover:text-white"
                  >
                    {/* <Icons
                name="sliders-outlined" width={20} height={20}
              /> */}
                    <MediaPlayerV2Mixer fullScreenMode={false} />
                  </Button>
                </Tooltip>
              )}
              {/* <Tooltip
            delayDuration={0}
            tooltipContent={<p className="caption-disclaimer">Collection</p>}
          >
            <Button variant="icon" size="icon" className="text-white/70 hover:text-white">
              <Icons
                name="list-outlined" width={20} height={20}
              />
            </Button>
          </Tooltip> */}
              {/* <Button variant="icon" size="icon" className="text-white/70 cursor-default">
              <Icons
                name="list-outlined" width={20} height={20}
              />
            </Button> */}
              <div className="flex items-center gap-2">
                {/* <Tooltip
                delayDuration={0}
                tooltipContent={<p className="caption-disclaimer">{isPlaying? "Mute" : "Unmute"}</p>}
              >
                <Button variant="icon" size="icon" className="text-white/70 hover:text-white" onClick={onPlayPause}>
                  {isPlaying ? (
                    <Icons
                      name="volume-2-outlined" width={20} height={20}
                    />
                  ) : (
                    <Icons
                      name="volume-x-outlined" width={20} height={20}
                    />
                  )}
                </Button>
              </Tooltip> */}
                <MediaPlayerV2VolumeControl
                  audioPlayerRef={audioPlayerRef}
                  className="w-[100px]"
                />
              </div>
            </div>
            <div className="flex items-center gap-3.5">
              {/* <Tooltip
            delayDuration={0}
            tooltipContent={<p className="caption-disclaimer">Enable PiP</p>}
          >
            <Button
              variant="icon"
              size="icon"
              className="text-white/70 hover:text-white"
            >
              <Icons
                name="pip-return-outlined" width={16} height={16}
              />
            </Button>
          </Tooltip> */}
              {/* <Button
              variant="icon"
              size="icon"
              className="text-white/70 cursor-default"
            >
              <Icons
                name="pip-return-outlined" width={16} height={16}
              />
            </Button> */}
              <Tooltip
                delayDuration={0}
                tooltipContent={
                  <p className="caption-disclaimer">Enter full screen</p>
                }
              >
                <Button
                  variant="icon"
                  size="icon"
                  className="text-white/70 hover:text-white"
                  onClick={onFullScreen}
                >
                  <Icons name="maximize-2-outlined" width={16} height={16} />
                </Button>
              </Tooltip>
              <Tooltip
                delayDuration={0}
                tooltipContent={<p className="caption-disclaimer">Close</p>}
              >
                <Button
                  variant="icon"
                  size="icon"
                  className="text-white/70 hover:text-white"
                  onClick={onClose}
                >
                  <Icons name="x-circle-outlined" width={16} height={16} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const MediaMiniPlayerV2Mobile = () => {
    return (
      <div
        className={cn(
          "bg-black w-full h-[inherit]",
          fullScreenMode ? "absolute bottom-[100px]" : "fixed"
        )}
      >
        <MediaPlayerV2SeekBar
          showDuration={false}
          fullScreenMode={false}
          soundscapeMode
          progress={trackState?.progress}
          onValueChange={(value: number[]) => {
            onSeek(value[0]);
          }}
          audioPlayerRef={audioPlayerRef}
        />
        <div className="relative w-full h-[inherit] px-4 md:px-6 py-2 flex justify-between">
          <div className="flex items-center z-10">
            {imagePath ? (
              <div
                className={cn(
                  "size-[56px] object-cover",
                  soundscapeMode ? "rounded-full" : "rounded-lg"
                )}
              >
                <img
                  src={imagePath}
                  className={cn(soundscapeMode ? "rounded-full" : "rounded-lg")}
                  alt=""
                />
              </div>
            ) : null}
            <div
              className={cn(
                "flexflex-col items-start ",
                title ? "ml-2 lg:ml-3 mr-2 lg:mr4" : "m-0"
              )}
            >
              <div className="title-9 text-white max-w-[156px] md:max-w-[268px] lg:max-w-[216px] w-full truncate">
                {title}
              </div>
              <div className="caption-disclaimer text-[#b3b8c1]">{author}</div>
            </div>
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">Enter full screen</p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className="text-white/70 hover:text-white"
                onClick={onFullScreen}
              >
                {/* <Icons name="bookmark-filled" width={20} height={20}/> */}
                <Icons name="chevron-up-outlined" width={20} height={20} />
              </Button>
            </Tooltip>
          </div>
          <div className="flex gap-3 lg:gap-6 justify-center items-center absolute inset-0 left-[auto] right-4 md:right-6 z-9">
            {soundscapeMode ? (
              <Tooltip
                delayDuration={0}
                tooltipContent={<p className="caption-disclaimer">Timer</p>}
              >
                <Button
                  variant="icon"
                  size="icon"
                  className="text-white/70 hover:text-white"
                >
                  {/* <Icons name='stopwatch-outlined' width={20} height={20}/> */}
                  <MediaPlayerV2TimerModal fullScreenMode={false} />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                delayDuration={0}
                tooltipContent={<p className="caption-disclaimer">Mixer</p>}
              >
                <Button
                  variant="icon"
                  size="icon"
                  className="text-white/70 hover:text-white"
                >
                  {/* <Icons
                name="sliders-outlined" width={20} height={20}
              /> */}
                  <MediaPlayerV2Mixer fullScreenMode={false} />
                </Button>
              </Tooltip>
            )}
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">
                  {isPlaying ? "Pause" : "Play"}
                </p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className="text-white/70 hover:text-white"
                onClick={onPlayPause}
              >
                {isPlaying ? (
                  <Icons name="pause-circle-filled" width={40} height={40} />
                ) : (
                  <Icons name="play-circle-filled" width={40} height={40} />
                )}
              </Button>
            </Tooltip>
            <Tooltip
              delayDuration={0}
              tooltipContent={<p className="caption-disclaimer">Close</p>}
            >
              <Button
                variant="icon"
                size="icon"
                className="text-white/70 hover:text-white"
                onClick={onClose}
              >
                <Icons name="x-circle-outlined" width={20} height={20} />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="w-full h-[inherit]">
      <div className="w-full flex lg:hidden h-[inherit]">
        <MediaMiniPlayerV2Mobile />
      </div>
      <div className="w-full hidden lg:flex h-[inherit]">
        <MediaMiniPlayerV2Desktop />
      </div>
    </div>
  );
};

export default MediaMiniPlayerV2Controls;
