import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import gqlQuery from "services/graphql/queries";
import type { ICurrentProfile } from "types/interfaces";

function useUserProfileDetails() {
  const { isAuthenticated } = useUserState();
  const {
    loading,
    error,
    data,
    refetch: refetchProfileData,
  } = useQuery(gqlQuery.getProfile, {
    skip: !isAuthenticated,
  });

  const profileData = data?.currentProfile as ICurrentProfile;

  return { profileData, loading, error, refetchProfileData };
}

export default useUserProfileDetails;
