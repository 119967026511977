import { useQuery } from "@apollo/client";
import gqlQuery from "services/graphql/queries";
import type { ICity, ICityEdge } from "types/interfaces";

function useCityList(first?: number, keyword = "") {
  const { data, loading, error } = useQuery(gqlQuery.getCitiesList, {
    variables: {
      keyword,
      first,
    },
  });
  const cityData: ICity[] = data?.cities?.edges?.map(
    (city: ICityEdge) => city.node
  );
  return { cityData, loading, error };
}

export default useCityList;
