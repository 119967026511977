import { useState } from "react";
import "./QuestCard.css";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@radix-ui/react-hover-card";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "components/Sheet";
import type { UserAccessStateEnum } from "context/userContext";
import { cn } from "libs/classMerger";
import type { ICategoryNode, IQuest } from "/types/interfaces";
import { QuestCardVariant1 } from "./QuestCardVariant1";
import { QuestCardVariant2 } from "./QuestCardVariant2";
import { QuestHoverCard } from "./QuestHoverCard";
import { QuestLandingModal } from "./questLandingModal/QuestLandingModal";

export enum cardVariantEnum {
  variant_1 = "variant_1",
  variant_2 = "variant_2",
}

export const QuestCard = ({
  quest,
  category,
  cardVariant,
  questAssignmentEndDate,
  canHover = false,
  className,
  userAccessState,
}: {
  quest: IQuest;
  category?: ICategoryNode;
  cardVariant?: cardVariantEnum;
  questAssignmentEndDate?: string;
  canHover?: boolean;
  className?: string;
  userAccessState: UserAccessStateEnum;
}) => {
  const [isSheetOpen, setSheetOpen] = useState(false);
  return (
    <HoverCard openDelay={0} closeDelay={0}>
      <Sheet open={isSheetOpen} onOpenChange={setSheetOpen}>
        <HoverCardTrigger asChild>
          <SheetTrigger asChild>
            <div
              onClick={() => setSheetOpen(true)}
              onKeyDown={() => {}}
              onPointerEnter={(e) => e.stopPropagation()}
              className={cn("common-trigger", className)}
            >
              {cardVariant === cardVariantEnum.variant_1 ? (
                <QuestCardVariant1 quest={quest} userAccessState={userAccessState} />
              ) : (
                <QuestCardVariant2
                  quest={quest}
                  questAssignmentEndDate={questAssignmentEndDate}
                  userAccessState={userAccessState}
                />
              )}
            </div>
          </SheetTrigger>
        </HoverCardTrigger>

        {canHover && (
          <>
            <HoverCardContent
              className="z-[40] animate-fade-in shadow-md fixed HoverCardContent"
              side="top"
              align="start"
              avoidCollisions={true}
              hideWhenDetached={true}
              collisionPadding={5}
              // forceMount={true} // Util to force mount the content
            >
              <QuestHoverCard
                key={quest.id}
                questData={quest}
                category={category}
                questAssignmentEndDate={questAssignmentEndDate}
              />
            </HoverCardContent>

            <SheetContent
              side={"bottom"}
              className="bg-white shadow-zinc-700/10 ring-zinc-700/10 top-12 md:top-16 lg:top-20 rounded-2xl  shadow-lg ring-1 transition mx-auto lg:max-w-[1000px] xs:w-full xs:max-w-[1000px] !p-0 !rounded-t-3xl !rounded-b-none !overflow-y-scroll md:max-h-[calc(100dvh-64px)] max-h-[calc(100dvh-48px)] scrollbar-hide"
            >
              <SheetClose asChild>
                <QuestLandingModal questData={quest} />
              </SheetClose>
            </SheetContent>
          </>
        )}
      </Sheet>
    </HoverCard>
  );
};
