import { useQuery } from "@apollo/client";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { GET_QUESTS_BY_CATEGORY } from "services/graphql/queries/index";
import type { IQuestEdge } from "types/interfaces";

function useQuest(categoryId?: string) {
  const { userAccessState } = useUserState();

  const { loading, error, data } = useQuery(GET_QUESTS_BY_CATEGORY, {
    skip: !categoryId,
    variables: {
      category: categoryId || null,
      owned: userAccessState === UserAccessStateEnum.Premium,
    },
  });

  return {
    questData: data?.quests?.edges?.map((edge: IQuestEdge) => edge.node) || [],
    loading,
    error,
  };
}

export default useQuest;
