import { Link } from "react-router-dom";
import type { IResource } from "types/interfaces";
import { durationToMinutes } from "utils/time";
import { Button } from "./Button";
import { useUserState } from "context/userContext";
import MediaCardComponent from "./MediaCardComponent";

interface FeaturedListComponentProps {
  title: string;
  description: string;
  backdropImagePath: string;
  buttonLink: string;
  data: IResource[];
  onHover: (image: string) => void;
  onClick: (image: string) => void;
  onLeave: () => void;
  isSoundScapeOnly?: boolean;
}

const FeaturedListComponent = ({
  title,
  description,
  backdropImagePath,
  buttonLink,
  data,
  onHover,
  onLeave,
  onClick,
  isSoundScapeOnly = false,
}: FeaturedListComponentProps) => {
  const { userAccessState } = useUserState();

  const contentItems = data.map((item) => (
    <MediaCardComponent
      key={item.id}
      file={item.mediaAsset?.url ?? ""}
      isSoundScapeOnly={isSoundScapeOnly}
      circleThumbnail={isSoundScapeOnly}
      id={item.id}
      title={item.title ?? ""}
      author={item.author.name}
      imagePath={item.coverAsset?.url ?? ""}
      duration={durationToMinutes(item.mediaAsset?.duration)}
      // imageOverlayText={!soundScape ? durationToMinutes(item.mediaAsset?.duration) : ""}
      onHover={onHover}
      onLeave={onLeave}
      onClick={onClick}
      userAccessState={userAccessState}
    />
  ));

  return (
    <div
      className="relative px-6 py-10 md:px-10 md:py-12 h-auto md:min-h-[440px] xl:h-[500px] lg:px-10 lg:py-16 bg-black/30 rounded-3xl bg-cover bg-no-repeat bg-bottom"
      style={{ backgroundImage: `url('${backdropImagePath}')` }}
    >
      <div className="absolute inset-x-0 bottom-0 h-[75%] bg-gradient-to-t from-black to-transparent rounded-3xl" />
      <div className="absolute inset-y-0 right-0 w-[75%] bg-gradient-to-l from-black to-transparent rounded-3xl" />
      <div className="relative flex flex-col z-0 h-full justify-end md:gap-10 overflow-hidden">
        <div className="rounded-2xl justify-between items-start gap-4 inline-flex flex-col lg:flex-row w-full align-end">
          <div className="w-[350px] md:w-[400px] h-[100%] flex-col justify-end items-start gap-1 flex">
            <div className="self-stretch text-white text-4xl md:text-5xl font-medium font-['Sharp Grotesk Cyr Medium'] leading-[45px] tracking-tight">
              {title}
            </div>
            <div className="text-[#ebedef] flex">{description}</div>
            <div className="flex-col justify-start items-start hidden lg:flex mt-3">
              <Link to={buttonLink} className="w-full">
                <Button
                  variant="neutral"
                  className="bg-white text-cool-grey-700 hover:bg-white-90a"
                >
                  Discover more
                </Button>
              </Link>
            </div>
          </div>
          <div className="mt-10 md:mt-0 h-[243px] justify-start items-center gap-8 flex">
            {contentItems}
          </div>
        </div>
        <div className="flex-col justify-start items-start flex lg:hidden">
          <Link to={buttonLink} className="w-full">
            <Button
              variant="neutral"
              className="bg-white text-cool-grey-700 hover:bg-white-90a w-full md:w-auto"
            >
              Discover more
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturedListComponent;
