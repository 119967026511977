export enum NavigationEnum {
  Home = "/",
  Configure = "/configure",
  Programs = "/programs",
  Meditations = "/meditations",
  StageView = "/stageView",
  AppInMeeting = "/appInMeeting",
  Library = "/library",
  Quest = "/quests",
  Account = "/account",
  Soundscape = "/soundscape",
  CompanyForm = "/companyForm",
  InterestForm = "/interestForm",
  QuestConsumption = "/quests",
}
