import { useQuery } from "@apollo/client";
import { GET_FREE_QUESTS } from "services/graphql/queries/index";
import type { IQuest, IQuestEdge } from "types/interfaces";

function useFreeQuests() {
  const { data, loading, error } = useQuery(GET_FREE_QUESTS, {
    variables: {
      first: 10,
      owned: true,
      language: ["en"],
    },
  });

  const questData: IQuest[] = data?.b2bQuests?.edges?.map((edge: IQuestEdge) => edge.node) || [];

  return { questData, loading, error };
}

export default useFreeQuests;
