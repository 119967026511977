import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import { GET_LIBRARY } from "services/graphql/queries/index";
import { type IQuest, type IQuestEdge, QuestStatus } from "types/interfaces";

function useLibrary({ first = 100, status = QuestStatus.InProgress, membership = "ALL" }) {
  const { isAuthenticated } = useUserState();
  const { loading, error, data } = useQuery(GET_LIBRARY, {
    skip: !isAuthenticated,
    variables: {
      first,
      status,
      membership,
    },
  });

  const questData: IQuest[] = data?.quests?.edges?.map((edge: IQuestEdge) => edge.node) || [];

  return { questData, loading, error };
}

export default useLibrary;
