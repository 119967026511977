import { Skeleton } from "../Skeleton";

const SidebarSkeleton = () => {
  return (
    <div className=" flex flex-col space-y-4 w-full h-full">
      {[...Array(4)].map((_, index) => (
        <Skeleton className="h-4 rounded-lg bg-brown-100" />
      ))}
    </div>
  );
};

export default SidebarSkeleton;
