import LockedModal from "components/LockedModal";
import Icons from "components/icons/Icons";
import { useUserState } from "context/userContext";
import { useToast } from "hooks/use-toast";
import useQuestConsumption from "hooks/useQuestConsumption";
import { cn } from "libs/classMerger";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StartProgramModal } from "tabs/Quest/QuestLanding/StartProgramModal";
import type { IGroup, IPage, IQuest } from "types/interfaces";
import { convertSecondsToReadableFormat } from "utils/time";

const Lesson = ({
  page,
  handleOnClick,
}: {
  page: IPage;
  handleOnClick: () => void;
}) => {
  return (
    <div
      onKeyUp={() => {}}
      onClick={handleOnClick}
      className="flex items-center justify-between transition border-l-transparent p-4 md:px-9 lg:mx-4 lg:my-1 lg:p-6 gap-3 lg:gap-6 lg:rounded-2xl hover:bg-brown-50 active:bg-brown-100 cursor-pointer"
    >
      <div className="flex flex-1 items-center gap-4">
        <div className="flex justify-center">
          <img
            className="w-[128px] h-[72px] md:w-[213px] md:h-[120px] max-w-[213px] rounded-md"
            src={page.coverAsset?.url}
            alt={page.name}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <p className="title-10 text-cool-grey-450 uppercase">{`${page.type === "intro" ? "INTRO " : "LESSON "} ${
              page.position
            }`}</p>
            <p className="title-7 text-cool-grey-700">{page.name}</p>
            <p className="caption-disclaimer text-cool-grey-400">{convertSecondsToReadableFormat(page.duration)}</p>
          </div>

          {page.preview && (
            <p className="w-fit text-center text-white bg-cool-grey-700 rounded-[4px] button-text-small px-2 py-1">
              Preview
            </p>
          )}
        </div>
      </div>
      {page.locked && !page.preview ? (
        <Icons name="lock-filled" fill="#595E67" height={24} width={24} />
      ) : page.completed ? (
        <Icons name="checklist-circle-filled" fill="#159F65" height={24} width={24} />
      ) : (
        <Icons name="chevron-right-filled" fill="#595E67" height={24} width={24} />
      )}
    </div>
  );
};

export const Lessons = (quest: IQuest) => {
  type GroupDetail = {
    groupName: string;
    totalPages: number;
    completedPages: number;
    pages: IPage[] | [];
    id: string;
    type: string;
  };
  const { toast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [isLockedModalOpen, setIsLockedModalOpen] = useState(false);
  const navigate = useNavigate();
  const { userAccessState } = useUserState();

  const handleLessonClick = (isLocked: boolean, url?: string) => {
    if (isLocked) setShowModal(true);
    else {
      const urlPath = new URL(url ?? "").pathname;
      navigate(urlPath);
    }
  };
  const handleOnClose = () => setShowModal(false);

  const [collapsedSections, setCollapsedSections] = useState<{
    [key: string]: boolean;
  }>(
    quest.groups
      ? quest.groups.reduce((acc: { [key: string]: boolean }, item: IGroup) => {
          acc[item.id] = false;
          return acc;
        }, {})
      : {},
  );

  const toggleSection = (section: string) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const getPagesForGroup = (groupName: string) => quest.pages.filter((page) => page.groupName === groupName);

  const [groupDetails, setGroupDetails] = useState<GroupDetail[]>([]);
  useEffect(() => {
    const enrichedGroups: GroupDetail[] = (quest?.groups ?? []).map((group) => {
      const pages = getPagesForGroup(group.name);
      const totalPages = pages.length;
      const completedPages = pages.filter((page) => page.completed).length;

      return {
        groupName: group.name,
        totalPages,
        completedPages,
        pages,
        id: group.id,
        type: group.type,
      };
    });
    setGroupDetails(enrichedGroups);
  }, [quest]);

  const { enrollUser } = useQuestConsumption().enrollUser;
  async function handleStartProgram() {
    const releaseId = quest?.releases ? quest?.releases[0].id : "";
    if (!releaseId) return;
    try {
      const { data } = await enrollUser({
        variables: {
          input: {
            releaseId,
          },
        },
      });

      if (!data || !data.enrollUser.successful) {
        throw data.enrollUser.messages[0]?.code;
      }
    } catch (err) {
      if (err === "unauthorized") {
        setIsLockedModalOpen(true);
      } else {
        toast({
          variant: "destructive",
          description: "Failed to enroll user. Please try again!",
        });
      }

      setShowModal(false);
    }
  }

  return (
    <>
      {groupDetails && (
        <div className="flex flex-col">
          {groupDetails.map((group) => {
            if (group.groupName !== "Info")
              return (
                <div className="flex flex-col mb-8" key={group.id}>
                  <button
                    onClick={() => toggleSection(group.id)}
                    type="button"
                    className="flex justify-start items-center cursor-pointer flex-row-reverse lg:flex-row px-4 md:px-9 lg:px-10 py-4 border-b border-black-12a gap-4"
                  >
                    <div
                      className={cn(
                        "transition-transform duration-200",
                        collapsedSections[group.id] ? "rotate-180" : "",
                      )}
                    >
                      <Icons name="chevron-down-filled" fill="#71767F" height={24} width={24} />
                    </div>
                    <div className="flex flex-col gap-y-1 flex-1 text-left">
                      <h4 className="heading-6 text-cool-grey-600">{group.groupName}</h4>
                      {quest?.userProgress?.enrolledAt && (
                        <p className={"title-9 text-cool-grey-500 "}>
                          {group.completedPages} / {group.totalPages} completed
                        </p>
                      )}
                    </div>
                  </button>
                  <div
                    className={cn(
                      "flex flex-col",
                      collapsedSections[group.id] ? "hidden animate-accordion-up" : "block animate-accordion-down",
                    )}
                  >
                    {group.pages.map((page) => (
                      <Lesson
                        key={page.id}
                        page={page}
                        handleOnClick={() => handleLessonClick(page.locked, page.url)}
                      />
                    ))}
                  </div>
                </div>
              );
          })}
          {showModal && (
            <StartProgramModal quest={quest} onClose={handleOnClose} handleStartProgram={handleStartProgram} />
          )}
          <LockedModal
            onCancel={() => setIsLockedModalOpen(false)}
            open={isLockedModalOpen}
            userAccessState={userAccessState}
          />
        </div>
      )}
    </>
  );
};
