import NavbarAvatar from "components/Avatar";
import LockedModal from "components/LockedModal";
import Icons from "components/icons/Icons";
import { useUserState } from "context/userContext";
import useUserProfile from "hooks/useUserProfile";
import { cn } from "libs/classMerger";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NavDropdownMenu from "../components/NavDropdownMenu";
import NavDropdownMenuTabletMobile from "../components/NavDropdownMenuTabletMobile";
// import SearchIcon from "../components/icons/SearchIcon";
import MicrosoftLogo from "../components/logo/MicrosoftLogo";
import { WorkwayLogoFull } from "../components/logo/WorkwayLogo";
import WorkwaySmallLogo from "../components/logo/WorkwaySmallLogo";

enum NavLinks {
  Home = "Home",
  Programs = "Programs",
  Meditations = "Meditations",
}

interface IUserdata {
  name: string;
  id: string;
  email: string;
  profilePhoto: string;
  smallLogo: string;
  role: string;
  isMicrosoftUser: boolean;
  isAdmin: boolean;
  adminPanelURL: string;
  profileURL: string;
  firstName: string;
  lastName: string;
}

const Navbar = () => {
  const { userAccessState, isAuthenticated } = useUserState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const { profileData } = useUserProfileDetails();

  const [userData, setUserData] = useState<IUserdata>();
  const { loading, data } = useUserProfile();

  useEffect(() => {
    if (!data || loading) return;
    //TODO: Fix mock data
    setUserData({
      name: `${data.currentProfile.firstName} ${data.currentProfile.lastName}`,
      id: "",
      email: data.currentProfile.email,
      profilePhoto: data.currentProfile.avatarUrl,
      smallLogo: "https://via.placeholder.com/150/0000FF",
      role: data.currentProfile.role,
      isMicrosoftUser: false,
      isAdmin: false,
      adminPanelURL: "/",
      profileURL: "/",
      firstName: data.currentProfile.firstName,
      lastName: data.currentProfile.lastName,
    });
  }, [data]);

  const location = useLocation();

  const menuHandler = (value = false) => {
    setIsMenuOpen(value);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setIsVisible(false);
      } else {
        // if scroll up show the navbar
        setIsVisible(true);
      }
      menuHandler(false);
      setLastScrollY(window.scrollY);
    }
  };
  const menuRender = () => {
    return [
      { label: NavLinks.Home, link: "/" },
      { label: NavLinks.Programs, link: `/${NavLinks.Programs}` },
      { label: NavLinks.Meditations, link: `/${NavLinks.Meditations}` },
    ];
  };

  const handleClickTabNavigation = ({
    e,
    link,
  }: {
    e: React.MouseEvent<HTMLAnchorElement>;
    link: string;
  }) => {
    if (!isAuthenticated && link !== "/" && link !== "/Programs") {
      setIsModalOpen(true);
      e.preventDefault();
    }
  };

  return (
    <div className="fixed z-[49] top-0 w-screen">
      <div className={cn("navbar", isVisible ? "visible" : "hidden")}>
        <div className="fixed z-10 bg-white/90 w-full flex items-center justify-between py-4 px-5 border-b lg:border-b-0  border-cool-grey-300 backdrop-blur">
          <div className="flex items-center gap-12">
            <div className="relative flex items-center gap-3">
              <div className="hidden md:block">
                <WorkwayLogoFull width="147.88px" />
              </div>
              <div className="md:hidden">
                <WorkwaySmallLogo />
              </div>
              <div className="w-0.5 py-3 bg-cool-grey-300" />
              <div>
                <MicrosoftLogo />
              </div>
            </div>
            <div className="items-center gap-9 hidden lg:flex">
              {menuRender().map((item) => (
                <Link
                  key={item.label}
                  className={cn(
                    "title-7 transition-colors hover:text-cool-grey-700 data-[selected]:text-cool-grey-700",
                    location.pathname === item.link ? "text-cool-grey-700" : "text-cool-grey-450",
                  )}
                  onClick={(e) => handleClickTabNavigation({ e, link: item.link })}
                  to={item.link}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center">
            {/* <div>
            <SearchIcon />
          </div> */}
            <div className="relative">
              {loading ? (
                <div> Loading </div>
              ) : (
                <div
                  onMouseEnter={() => menuHandler(true)}
                  onClick={() => menuHandler(!isMenuOpen)}
                  onKeyUp={() => menuHandler(false)}
                  onKeyDown={() => menuHandler(false)}
                  onKeyPress={() => menuHandler(!isMenuOpen)}
                >
                  {isAuthenticated && (
                    <NavbarAvatar
                      imageUrl={userData?.profilePhoto}
                      fallbackText={`${userData?.firstName.charAt(0)}${userData?.lastName.charAt(0)}`}
                      className="h-8 w-8"
                    />
                  )}
                </div>
              )}
              {isAuthenticated && (
                <div
                  className="lg:hidden"
                  onClick={() => menuHandler(!isMenuOpen)}
                  onKeyUp={() => menuHandler(false)}
                  onKeyDown={() => menuHandler(false)}
                  onKeyPress={() => menuHandler(!isMenuOpen)}
                >
                  <Icons name={!isMenuOpen ? "menu-filled" : "x-filled"} height={24} width={24} fill="#595E67" />
                </div>
              )}
              {isMenuOpen && (
                <div className="hidden lg:block" onMouseLeave={() => menuHandler(false)}>
                  <NavDropdownMenu
                    userData={userData}
                    setIsMenuOpen={() => setIsMenuOpen(false)}
                    userAccessState={userAccessState}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="w-full lg:hidden">
            <NavDropdownMenuTabletMobile
              menuRender={menuRender()}
              userData={userData}
              setIsMenuOpen={() => menuHandler(false)}
            />
          </div>
        )}
      </div>
      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal onCancel={() => setIsModalOpen(false)} open={isModalOpen} userAccessState={userAccessState} />
      </div>
    </div>
  );
};
export default Navbar;
