import FeaturedListComponent from "components/FeaturedListComponent";
import BannerSkeleton from "components/loading/BannerSkeleton";
import useMeditationsOfTheDay from "hooks/useMeditationsOfTheDay";
import { useEffect, useState } from "react";

const FeaturedMeditationList = () => {
  const { meditationsOfTheDay, loading, error } = useMeditationsOfTheDay();

  useEffect(() => {
    if (!loading) {
      setBanner(meditationsOfTheDay[0].coverAsset?.url ?? "");
      setSelectedTrackBanner(meditationsOfTheDay[0].coverAsset?.url ?? "");
    }
  }, [meditationsOfTheDay]);

  const [banner, setBanner] = useState("");
  const [selectedTrackBanner, setSelectedTrackBanner] = useState(banner);
  if (error) return null;

  return (
    <>
      {loading ? (
        <BannerSkeleton />
      ) : (
        <section className="container">
          <FeaturedListComponent
            title="Meditations of the day"
            description="Discover individual meditations designed to help you focus, relax, and recharge whenever you need a moment of mindfulness."
            backdropImagePath={banner}
            buttonLink="/"
            data={meditationsOfTheDay}
            onHover={(image: string) => setBanner(image)}
            onLeave={() => setBanner(selectedTrackBanner)}
            onClick={(image: string) => setSelectedTrackBanner(image)}
          />
        </section>
      )}
    </>
  );
};

export default FeaturedMeditationList;
