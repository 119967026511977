import { useQuery } from "@apollo/client";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { GET_TRENDING_GLOBALLY_QUESTS } from "services/graphql/queries/index";
import type { IQuest } from "types/interfaces";

function useTrendingGloballyQuests() {
  const { userAccessState, isAuthenticated } = useUserState();
  const { data, loading, error } = useQuery(GET_TRENDING_GLOBALLY_QUESTS, {
    skip: isAuthenticated && userAccessState === UserAccessStateEnum.Premium,
    variables: {
      limit: 5,
    },
  });

  const trendingGloballyQuestsData: IQuest[] = data?.trendingGloballyRecommendations || [];

  return { trendingGloballyQuestsData, loading, error };
}

export default useTrendingGloballyQuests;
