import React, { useEffect, useState } from 'react'
import MediaPlayerV2Thumbnail from './MediaPlayerV2Thumbnail'
import MediaPlayerV2Mixer from './MediaPlayerV2Mixer';
import MediaPlayerV2ContentInfoModal from './MediaPlayerV2ContentInfoModal';
import MediaPlayerV2TimerModal from './MediaPlayerV2TimerModal';
import { cn } from "libs/classMerger";

interface MediaPlayerV2InfoBarProps {
    title: string;
    subtitle: string;
    imagePath: string;
    soundscapeMode?: boolean;
}

const MediaPlayerV2InfoBar = ({ title, subtitle, imagePath, soundscapeMode }: MediaPlayerV2InfoBarProps) => {

    const [animated, setAnimated] = useState(false);

    useEffect(() => {
        window.setTimeout(() => {
            setAnimated(true);
        }, 1000 * 2);
    })
    
    return (
        <div className={cn("absolute z-50 bottom-[150px] lg:bottom-[116px] text-white t-6 px-4 lg:px-10 w-full grid gap-6 transition-transform duration-700",
            animated ? "scale-100 opacity-100" : "scale-150 translate-x-[400px] translate-y-[-50px] opacity-50"
        )}>
            <div className="flex items-center lg:items-end gap-4">
                <div className={cn("size-[64px] object-cover", soundscapeMode? "rounded-full" : "rounded-lg")}>
                    <img src={imagePath} className={cn(soundscapeMode? "rounded-full" : "rounded-lg"
                    )} />
                </div>
                <div className="flex-col items-start gap-4">
                    <div className='hidden lg:inline-flex'>
                        {soundscapeMode ? <MediaPlayerV2TimerModal fullScreenMode={true} /> : <MediaPlayerV2Mixer fullScreenMode={true} />
                        }
                    </div>
                    <div className="heading-5 text-white">
                        <MediaPlayerV2ContentInfoModal title={title}/>
                    </div>
                    <div className="body-small text-[#ebedef]">{subtitle}</div>
                </div>
            </div>
        </div>
    )
}

export default MediaPlayerV2InfoBar