import { gql } from "@apollo/client";
import { QueriesOperations } from "services/graphql/queries/queriesOperations";

export const GET_CURRENT_ORGANIZATION = gql`
  query ${QueriesOperations.GET_CURRENT_ORGANIZATION} {
    currentOrganization {
      name
      division
      logoFullUrl
      logoSquareUrl
    }
  }
`;

export const GET_QUESTS_BY_CATEGORY = gql`
  query ${QueriesOperations.GET_QUESTS_BY_CATEGORY} (
    $category: ID
    $membershipFilter: QuestMembershipFilterValues = ALL
    $first: Int = 6
    $owned: Boolean
  ) {
    quests(category: $category, membership: $membershipFilter, first: $first, owned: $owned) {
      edges {
        node {
          id
          description
          name
          type
          url
          slug
          language
          trailerAsset {
            url
          }
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
          coverAsset {
            url
            thumbnailUrl
          }
          resources {
            mediaAsset {
              url
              edgeUrl
              contentType
            }
          }
          releases {
            id
            status
            perpetual
            publishedAt
          }
          authors {
            name
          }
          userProgress {
            completed
            totalDays
            totalDaysCompleted
            daysCompleted
            totalLessonsCompleted
            totalLessons
            started
            resumePage {
              id
              name
              duration
              position
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_QUEST_BY_SLUG_AND_LANGUAGE_B2B = gql`
  query ${QueriesOperations.GET_QUEST_BY_SLUG_AND_LANGUAGE_B2B}($slugAndLanguage: QuestSlugAndLanguage!) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      name
      description
      slug
      language
      currentAssignment {
        startDate
        endDate
      }
      type
      enrollmentsCount
      publishedAt
      duration
      authors {
        name
        bioTitle
        headline
        avatarAsset {
          url
        }
        bannerAsset {
          url
        }
        metadataTitle
        description
        headline
      }
      categories {
        name
      }
      groups {
        id
        locked
        name
        position
        type
        unlockAfterDays
      }
      userProgress {
        completed
        totalDays
        totalDaysCompleted
        daysCompleted
        totalLessonsCompleted
        totalLessons
        started
        resumePage {
          id
          name
          duration
          position
          url
        }
      }
      wordmarkAsset {
        url
      }
      owned
      pages {
        id
        name
        type
        groupName
        position
        locked
        completed
        duration
        preview
        nextPage {
          id
        }
        url
        coverAsset {
          thumbnailUrl
          url
        }
        tasks {
          id
          type
          name
          description
          position
          required
          imageUrl
          completed
          coverAsset {
            url
          }
        }
        sections {
          info {
            body
            title
          }
          type
          position
          media {
            type
            author {
              name
            }
            coverAsset {
              url
              edgeUrl
            }
            id
            description
            mediaAsset {
              url
              contentType
              captions {
                id
                language
                label
                default
                isDefault
                url
              }
            }
            title
          }
          primaryAsset {
            contentType
            url
            duration
            sizeOfFile
            edgeUrl
            filesize
            id
            captions {
              id
              language
              label
              default
              isDefault
              url
            }
            markers {
              id
              name
              status
              time
            }
            name
            status
            thumbnailUrl
            userProgress {
              watchProgress
              watchTime
              watched
            }
            renditions {
              url
              contentType
            }
          }
        }
      }
      outcomes {
        id
        name
      }
      daysCount
      lessonsCount
      coverAsset {
        url
      }
      trailerAsset {
        thumbnailUrl
        url
        renditions {
          id
          contentType
          url
        }
        captions {
          id
          language
          label
          default
          isDefault
          url
        }
      }
      trailerCoverAsset {
        url
      }
      releases {
        id
        status
        perpetual
        publishedAt
      }
      nextRelease {
        id
        status
        perpetual
        publishedAt
      }
      url
      productId
      owned
      language
      resources {
        isPartOfMembership
        type
        id
        title
        author {
          name
        }
        mediaAsset {
          contentType
          url
          duration
          sizeOfFile
        }
        totalDuration
        description
        coverAsset {
          thumbnailUrl
          url
        }
      }
    }
  }
`;

export const GET_QUEST_BY_SLUG_AND_LANGUAGE = gql`
  query ${QueriesOperations.GET_QUEST_BY_SLUG_AND_LANGUAGE}($slugAndLanguage: QuestSlugAndLanguage!) {
    questBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      name
      description
      slug
      language
      type
      enrollmentsCount
      publishedAt
      duration
      authors {
        name
        bioTitle
        headline
        avatarAsset {
          url
        }
        bannerAsset {
          url
        }
        metadataTitle
        description
        headline
      }
      categories {
        name
      }
      groups {
        id
        locked
        name
        position
        type
        unlockAfterDays
      }
      wordmarkAsset {
        url
      }
      pages {
        id
        name
        type
        groupName
        position
        locked
        completed
        duration
        preview
        nextPage {
          id
        }
        url
        coverAsset {
          thumbnailUrl
          url
        }
        tasks {
          id
          type
          name
          description
          position
          required
          imageUrl
          completed
          coverAsset {
            url
          }
        }
        sections {
          info {
            body
            title
          }
          type
          position
          media {
            type
            author {
              name
            }
            coverAsset {
              url
              edgeUrl
            }
            id
            description
            mediaAsset {
              url
              contentType
              captions {
                id
                language
                label
                default
                isDefault
                url
              }
            }
            title
          }
          primaryAsset {
            contentType
            url
            duration
            sizeOfFile
            edgeUrl
            filesize
            id
            captions {
              id
              language
              label
              default
              isDefault
              url
            }
            markers {
              id
              name
              status
              time
            }
            name
            status
            thumbnailUrl
            userProgress {
              watchProgress
              watchTime
              watched
            }
            renditions {
              url
              contentType
            }
          }
        }
      }
      outcomes {
        id
        name
      }
      daysCount
      lessonsCount
      coverAsset {
        url
      }
      trailerAsset {
        thumbnailUrl
        url
        renditions {
          id
          contentType
          url
        }
        captions {
          id
          language
          label
          default
          isDefault
          url
        }
      }
      trailerCoverAsset {
        url
      }
      releases {
        id
        status
        perpetual
        publishedAt
      }
      nextRelease {
        id
        status
        perpetual
        publishedAt
      }
      url
      productId
      owned
      language
      resources {
        isPartOfMembership
        type
        id
        title
        author {
          name
        }
        mediaAsset {
          contentType
          url
          duration
          sizeOfFile
        }
        totalDuration
        description
        coverAsset {
          thumbnailUrl
          url
        }
      }
    }
  }
`;

export const GET_CURRENT_PROFILE = gql`
  query ${QueriesOperations.GET_CURRENT_PROFILE} {
    currentProfile {
      firstName
      lastName
      email
      role
      spokenLanguages {
        displayName
        iso2Code
      }
      country
      location
      industry
      avatarUrl
      tags
      bio
      userLevel {
        level
      }
    }
  }
`;

// export const GET_PICKED_FOR_YOU_QUESTS = gql`
//   query ${QueriesOperations.GET_PICKED_FOR_YOU_QUESTS}($first: Int!) {
//     recommendedB2bQuestsPickedForYou(first: $first) {
//       edges {
//         node {
//           quest {
//             authors {
//               name
//             }
//             coverAsset {
//               thumbnailUrl
//               url
//             }
//             headshotCoverAsset {
//               url
//             }
//             language
//             slug
//             description
//             id
//             name
//             pages {
//               position
//               sections {
//                 primaryAsset {
//                   contentType
//                   edgeUrl
//                   url
//                 }
//               }
//             }
//             type
//             url
//             categories {
//               name
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const GET_RECOMMENDED_QUESTS = gql`
  query ${QueriesOperations.GET_RECOMMENDED_QUESTS}($first: Int, $language: String = "en") {
    recommendedB2bQuests(first: $first, language: $language) {
      edges {
        node {
          quest {
            url
            slug
            description
            language
            authors {
              name
            }
            coverAsset {
              thumbnailUrl
              url
            }
            trailerAsset {
              thumbnailUrl
              url
              renditions {
                id
                contentType
                url
              }
              captions {
                id
                language
                label
                default
                isDefault
                url
              }
            }
            id
            name
            wordmarkAsset {
              url
            }
            releases {
              id
              status
              perpetual
              publishedAt
            }
          }
        }
      }
    }
  }
`;

export const GET_LIBRARY = gql`
query ${QueriesOperations.GET_LIBRARY} (
  $first: Int, 
  $status: String, 
  $membership: QuestMembershipFilterValues
  ) {
    quests(first: $first, progress: $status, membership: $membership) {
      edges {
        node {
          id
          type
          name
          description
          enrollmentStartedAt
          courseStartedAt
          courseEndedAt
          slug
          language
          userProgress {
            totalDays
            totalDaysCompleted
            totalLessons
            totalLessonsCompleted
            started
            endedAt
            completed
            resumePage {
              id
              name
              duration
              position
              url
            }
          }
          url
          authors {
            name
          }
          coverAsset {
            url
          }
          resources {
            mediaAsset {
              url
              thumbnailUrl
              duration
            }
          }
          groups {
            id
            locked
            name
            position
            type
            unlockAfterDays
          }
          trailerAsset {
            url
            thumbnailUrl
          }
          wordmarkAsset {
            url
          }
        }
      }
    }
  }
`;

export const GET_LANGUAGES = gql`
  query ${QueriesOperations.GET_LANGUAGES} ($first: Int) {
    languages(first: $first) {
      edges {
        node {
          id
          iso2Code
          displayName
        }
      }
      totalCount
    }
  }
`;

export const GET_CATEGORIES = gql`
  query ${QueriesOperations.GET_CATEGORIES} {
    categories(first: 100, type: "b2b") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_PICKED_FOR_YOU_QUESTS_PUBLIC = gql`
  query ${QueriesOperations.GET_PICKED_FOR_YOU_QUESTS_PUBLIC}($limit: Int) {
    pickedForYouRecommendations(limit: $limit)
      @rest(
        type: "RecommendationPayload"
        path: "/quests/picked-for-you-quest-public?limit={args.limit}"
      ) {
        id
        name
        description
        slug
        language
        authors @type(name: "Author") {
          name
          avatarAsset {
            url
          }
        }
        coverAsset {
          url
        }
        headshotCoverAsset {
          url
        }
        wordmarkAsset {
          url
        }
        trailerAsset {
          contentType
          thumbnailUrl
          url
        }
        releases {
          id
          status
          perpetual
          publishedAt
        }
        introUrl
        url
        outcomes @type(name: "Outcome") {
          name
        }
        pages @type(name: "Page") {
          position
          type
          sections @type(name: "Section") {
            primaryAsset {
              url
            }
            type
          }
        }
        categories @type(name: "Category") {
          name
        }
        resources @type(name: "Resource") {
          mediaAsset {
            url
          }
        }
    }
  }
`;

export const GET_PICKED_FOR_YOU_QUESTS = gql`
  query ${QueriesOperations.GET_PICKED_FOR_YOU_QUESTS}($first: Int) {
    recommendedB2bQuestsPickedForYou(first: $first) {
      edges {
        node {
          quest {
            slug
            language
            authors {
              name
            }
            coverAsset {
              thumbnailUrl
              url
            }
            headshotCoverAsset {
              url
            }  
            trailerAsset {
              contentType
              thumbnailUrl
              url
            }
            description
            id
            name
            type
            url
            wordmarkAsset {
              url
            }
            categories {
              name
            }
            releases {
              id
              status
              perpetual
              publishedAt
            }
          }
        }
      }
    }
  }
`;

export const GET_TRENDING_GLOBALLY_QUESTS = gql`
  query ${QueriesOperations.GET_TRENDING_GLOBALLY_QUESTS}($limit: Int) {
    trendingGloballyRecommendations(limit: $limit)
      @rest(
        type: "RecommendationPayload"
        path: "/quests/trending-globally-quests-public?limit={args.limit}"
      ) {
        id
        name
        description
        slug
        type
        duration
        daysCount
        lessonsCount
        averageRating
        freeQuest
        authors @type(name: "Author") {
          name
          avatarAsset {
            url
          }
        }
        coverAsset {
          url
        }
        headshotCoverAsset {
          url
        }
        wordmarkAsset {
          url
        }
        trailerAsset {
          contentType
          thumbnailUrl
          url
        }
        introUrl
        url
        outcomes @type(name: "Outcome") {
          name
        }
        pages @type(name: "Page") {
          position
          type
          sections @type(name: "Section") {
            primaryAsset {
              url
            }
            type
          }
        }
        categories @type(name: "Category") {
          name
        }
        resources @type(name: "Resource") {
          mediaAsset {
            url
          }
        }
    }
  }
`;

export const GET_QUEST_BY_ID = gql`
  query ${QueriesOperations.GET_QUEST_BY_ID}($id: ID!) {
    quest(id: $id) {
      id
      description
      name
      type
      url
      slug
      authors {
        name
        avatarAsset {
          url
        }
      }
      headshotCoverAsset {
        url
      }
      outcomes {
        name
      }
      trailerAsset {
        url
        thumbnailUrl
        contentType
      }
      wordmarkAsset {
        url
      }
      type
      daysCount
      lessonsCount
      duration
      averageRating
      coverAsset {
        url
      }
      resources {
        mediaAsset {
          url
          contentType
        }
      }
      categories {
        name
      }
      pages {
        position
        type
        sections {
          type
          primaryAsset {
            url
          }
        }
      }
    }
  }
`;

export const GET_FREE_QUESTS = gql`
  query ${QueriesOperations.GET_FREE_QUESTS} ($first: Int, $owned: Boolean, $language: [String]) {
    b2bQuests(first: $first, owned: $owned, language: $language) {
      edges {
        node {
          type
          name
          description
          enrollmentStartedAt
          courseStartedAt
          courseEndedAt
          slug
          language
          userProgress {
            totalDays
            totalDaysCompleted
            totalLessons
            totalLessonsCompleted
            started
            endedAt
            completed
            resumePage {
              id
              name
              duration
              position
              url
            }
          }
          url
          authors {
            name
          }
          coverAsset {
            url
          }
          resources {
            mediaAsset {
              url
              thumbnailUrl
              duration
            }
          }
          groups {
            id
            locked
            name
            position
            type
            unlockAfterDays
          }
          trailerAsset {
            url
            thumbnailUrl
          }
          wordmarkAsset {
            url
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_SOUNDSCAPES_OF_THE_WEEK = gql`
  query ${QueriesOperations.GET_SOUNDSCAPES_OF_THE_WEEK}($limit: Int) {
    soundscapesOfTheWeek(limit: $limit)
    @rest(
      type: "RecommendationPayload"
      path: "/meditations/soundscapes-of-the-week?limit={args.limit}"
    ) {
      id
      title
      author {
        name
      }
      mediaAsset {
        duration
        url
      }
      coverAsset {
        url
      }
      url
    }
  }
`;

export const GET_MEDITATION_OF_THE_DAY = gql`
  query ${QueriesOperations.GET_MEDITATION_OF_THE_DAY}($limit: Int) {
    meditationOfTheDay(limit: $limit)
    @rest(
      type: "RecommendationPayload"
      path: "/meditations/meditations-of-the-day?limit={args.limit}"
    ) {
      id
      title
      author {
        name
      }
      mediaAsset {
        duration
        url
      }
      coverAsset {
        url
      }
      url
    }
  }
`;

export const GET_QUESTS_ASSIGMENTS = gql`
  query ${QueriesOperations.GET_QUESTS_ASSIGMENTS}($first: Int) {
    currentWorkwayQuestAssignments(first: $first) {
      edges {
        node {
          startDate
          endDate
          createdAt
          quest {
            authors {
              name
            }
            description
            url
            slug
            language
            enrollmentStartedAt
            courseStartedAt
            courseEndedAt
            type
            coverAsset {
              thumbnailUrl
              url
            }
            id
            name
            wordmarkAsset {
              url
            }
            userProgress {
              resumePage {
                id
                name
                duration
                position
                url
              }
              started
              totalDays
              totalDaysCompleted
              totalLessons
              totalLessonsCompleted
              endedAt
            }
          }
        }
      }
    }
  }
`;
