import { QuestStatus } from "types/interfaces";
import AssignedToMe from "./sections/AssignedToMe";
import Completed from "./sections/Completed";
import InProgress from "./sections/InProgress";

export default function MyLibrary({ section }: { section: string }) {
  return (
    <>
      {section === QuestStatus.InProgress && <InProgress />}
      {section === QuestStatus.AssignedToMe && <AssignedToMe />}
      {section === QuestStatus.Completed && <Completed />}
    </>
  );
}
