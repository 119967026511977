import useMeditation from "hooks/useMeditation";
import type { PrimaryAssetI } from "types/interfaces";
import MeditationCardSidePanelView from "../components/MeditationCardSidePanelView";

export default function MeditationsListView(props: {
  shareSpecificPart: (mediaUrl: PrimaryAssetI, thumbnailUrl: string) => void;
  frameContext: string | null;
}) {
  const { meditationData } = useMeditation();

  return (
    <div className="grid space-y-6 mt-4">
      {meditationData.map((meditation) => (
        <MeditationCardSidePanelView
          key={meditation.resource.id}
          handleClick={() =>
            props.shareSpecificPart(
              meditation.resource.mediaAsset ?? {
                url: "",
                contentType: "",
                edgeUrl: "",
              }, // TODO:: Temporary fix since we going to change the api so deos not matter much
              meditation.resource.coverAsset?.url ?? "",
            )
          }
          meditation={meditation}
          frameContext={props.frameContext}
        />
      ))}
    </div>
  );
}
