import { useQuery } from "@apollo/client";
import { GET_SOUNDSCAPES_OF_THE_WEEK } from "services/graphql/queries/index";
import type { IResource } from "types/interfaces";

function useSoundscapesOfTheWeek() {
  const { data, loading, error } = useQuery(GET_SOUNDSCAPES_OF_THE_WEEK, {
    variables: { limit: 4 },
  });
  const soundscapesOfTheWeek = data?.soundscapesOfTheWeek as IResource[];
  return { soundscapesOfTheWeek, loading, error };
}

export default useSoundscapesOfTheWeek;
