import { Skeleton } from "../Skeleton";

export const ProgramsWallSkeleton = () => {
  return (
    <div className="flex  flex-col gap-y-5">
      <Skeleton className="m-auto w-[1280] h-[500] bg-brown-100" />
      <div className="flex flex-col gap-y-8 w-full max-w-[1280] m-auto">
        <div className="flex justify-between ">
          <Skeleton className="w-[650] h-[40] bg-brown-100 rounded-full" />
          <Skeleton className="w-[240] h-[40] bg-brown-100 rounded-full" />
        </div>
        <Skeleton className="bg-brown-100 h-32 w-full" />
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-y-2">
            <Skeleton className="w-40 h-7 bg-brown-100 rounded-full" />
            <Skeleton className="w-32 h-7 bg-brown-100 rounded-full" />
          </div>
          <div className="flex flex-col gap-y-1 w-3/4">
            {[...Array(4)].map((_, index) => (
              <Skeleton className=" h-3 bg-brown-100 " />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProgramsQuestSkeleton = () => {
  return (
    <div>
      <div>
        <Skeleton className="bg-brown-100 w-40 h-5 rounded-full" />
      </div>
      <div className="flex flex-row flex-wrap gap-4">
        {[...Array(15)].map((_, index) => (
          <div className="lex flex-col space-y-3">
            <Skeleton className="h-[170px] w-[302px] rounded-xl bg-brown-100" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px] bg-brown-100" />
              <Skeleton className="h-4 w-[200px] bg-brown-100" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
