import LockedModal from "components/LockedModal";
import Icons from "components/icons/Icons";
import { useUserState } from "context/userContext";
import { useToast } from "hooks/use-toast";
import useQuestConsumption from "hooks/useQuestConsumption";
import { cn } from "libs/classMerger";
import { useState } from "react";
import type { IPage, IQuest } from "types/interfaces";
import { StartProgramModal } from "../QuestLanding/StartProgramModal";

const Lesson = ({
  page,
  currentPage,
  handleLessonClick,
}: {
  page: IPage;
  currentPage?: IPage;
  handleLessonClick: (page: IPage) => void;
}) => {
  const isPlaying = page.id === currentPage?.id;
  return (
    <button
      onClick={() => handleLessonClick(page)}
      type="button"
      className={cn(
        "w-full flex items-center justify-between transition border-l-red-500 px-6 py-4 gap-3 hover:bg-brown-100",
        isPlaying ? "bg-brown-200 border-l-4" : "border-l-0",
      )}
    >
      <div className="flex flex-1 items-center gap-3">
        <div className="flex justify-center">
          <img
            className="w-[128px] h-[72px] md:w-[213px] md:h-[120px] max-w-[213px] rounded-lg"
            src={page.coverAsset?.url}
            alt={page.name}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-0.5 items-start">
            <p className="title-10 text-cool-grey-450 uppercase">{`${page.type === "intro" ? "INTRO " : "LESSON "} ${
              page.position
            }`}</p>
            <p className="title-8 text-cool-grey-700 text-left">{page.name}</p>
            <p className="caption-disclaimer text-cool-grey-400">{Math.round(page.duration / 60)} mins</p>
          </div>
          {page.preview && (
            <p className="w-fit text-center text-white bg-cool-grey-700 rounded title-10 px-1.5 py-1">Preview</p>
          )}
        </div>
      </div>
      {page.completed ? (
        <Icons className="text-green-600" name="checklist-circle-filled" />
      ) : page.locked && !page.preview ? (
        <Icons name="lock-filled" className="text-cool-grey-600" />
      ) : (
        <Icons name="chevron-right-filled" className="text-cool-grey-600" />
      )}
    </button>
  );
};

export const Lessons = ({
  quest,
  currentPage,
  onLessonSelect,
}: {
  quest?: IQuest;
  currentPage?: IPage;
  onLessonSelect: (page: IPage) => void;
}) => {
  const { toast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [isLockedModalOpen, setIsLockedModalOpen] = useState(false);
  const handleOnClose = () => setShowModal(false);
  const { userAccessState } = useUserState();

  const handleLessonClick = (pageSelected: IPage) => {
    const playLesson = pageSelected.preview || !pageSelected.locked;
    setShowModal(!playLesson);
    playLesson && currentPage?.id !== pageSelected.id && onLessonSelect(pageSelected);
  };

  const [collapsedSections, setCollapsedSections] = useState<{
    [key: string]: boolean;
  }>(() => {
    const initialCollapsedSections: { [key: string]: boolean } = {};
    quest?.groups?.forEach((group) => {
      initialCollapsedSections[group.id] = false;
    });
    return initialCollapsedSections;
  });

  const toggleSection = (section: string) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const { enrollUser, loading, response, error } = useQuestConsumption().enrollUser;

  async function handleStartProgram() {
    const releaseId = quest?.releases ? quest?.releases[0].id : "";
    if (!releaseId) return;
    try {
      const { data } = await enrollUser({
        variables: {
          input: {
            releaseId,
          },
        },
      });

      if (!data || !data.enrollUser.successful) {
        throw data.enrollUser.messages[0]?.code;
      }
    } catch (err) {
      if (err === "unauthorized") {
        setIsLockedModalOpen(true);
      } else {
        toast({
          variant: "destructive",
          description: "Failed to enroll user. Please try again!",
        });
      }

      setShowModal(false);
    }
  }

  return (
    <div className="flex flex-col">
      {quest?.groups
        ?.filter((group) => group.type !== "info")
        .map((group) => (
          <div key={group.id} className="flex flex-col">
            <button
              onClick={() => toggleSection(group.id)}
              type="button"
              className="flex items-center cursor-pointer px-6 py-3 bg-black-4a gap-2 text-left"
            >
              <div className={cn("transition-transform duration-600", collapsedSections[group.id] ? "rotate-180" : "")}>
                <Icons name="chevron-up-filled" fill="#71767F" />
              </div>
              <div className="flex flex-col gap-y-0.5 flex-1">
                <h4 className="heading-9 text-cool-grey-700">{group.name}</h4>
                {quest.pages.filter((page) => page.groupName === group.name)[0].type !== "intro" && (
                  <p className={"title-9 text-cool-grey-500 visible"}>
                    {quest.pages.filter((page) => page.groupName === group.name && page.completed).length} /{" "}
                    {quest.pages.filter((page) => page.groupName === group.name).length} completed
                  </p>
                )}
              </div>
            </button>
            <div
              className={cn(
                "flex flex-col",
                collapsedSections[group.id] ? "hidden animate-accordion-up" : "block animate-accordion-down",
              )}
            >
              {quest.pages
                .filter((page) => page.groupName === group.name)
                .sort((page1, page2) => page1.position - page2.position)
                .map((val) => (
                  <Lesson key={val.id} page={val} handleLessonClick={handleLessonClick} currentPage={currentPage} />
                ))}
            </div>
          </div>
        ))}
      {showModal && quest && (
        <StartProgramModal quest={quest} onClose={handleOnClose} handleStartProgram={handleStartProgram} />
      )}
      <LockedModal
        onCancel={() => setIsLockedModalOpen(false)}
        open={isLockedModalOpen}
        userAccessState={userAccessState}
      />
    </div>
  );
};
