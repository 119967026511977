import type React from "react";

interface MediaPlayerV2AudioControllerProps {
  audioPlayerRef: React.LegacyRef<HTMLAudioElement>;
  src: string;
  loop: boolean;
}

const MediaPlayerV2AudioController = ({
  audioPlayerRef,
  src,
  loop,
}: MediaPlayerV2AudioControllerProps) => {
  // @ts-ignore
  return (
    <audio ref={audioPlayerRef} src={src} loop={loop}>
      <track kind="captions" />
    </audio>
  );
};

export default MediaPlayerV2AudioController;
