import React from "react";
import Preloader from "components/loading/Preloader";

const FormSubmitLoadingScreen = () => {
  return (
    <div>
      <Preloader />
    </div>
  );
};

export default FormSubmitLoadingScreen;
