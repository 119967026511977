import { SpeakerLoudIcon, SpeakerOffIcon } from "@radix-ui/react-icons";
import { Button } from "components/Button";
import { MediaPlayer } from "components/MediaPlayer";
import ProgressBar from "components/ProgressBar";
import { Tooltip } from "components/Tooltip";
import Icons from "components/icons/Icons";
import { QuestLandingPage } from "tabs/Quest/QuestLanding/QuestLandingPage";
import type { IAssignedQuestNode, IQuest } from "types/interfaces";
import { formatDate } from "utils/date";

export function ContinueLearningBigCard({
  item,
  questsAssigned,
  isMuted,
  setMuted,
  screenType,
  handleContinueLearning,
}: {
  item: IQuest;
  questsAssigned?: IAssignedQuestNode[];
  isMuted: boolean;
  setMuted: (value: boolean) => void;
  screenType: { isMobile: boolean; isTablet: boolean; isDesktop: boolean };
  handleContinueLearning: () => void;
}) {
  const questAssigned = questsAssigned?.find((quest) => quest.quest.id === item.id);

  return (
    <div className="w-full bg-black rounded-3xl overflow-hidden animation-block relative z-30">
      <div className="relative h-[224px] sm:h-[324px] md:h-[354px] w-full overflow-hidden">
        {item.trailerAsset ? (
          <div className="relative">
            <MediaPlayer
              mediaUrl={item.trailerAsset.url}
              autoplay={!screenType.isMobile}
              muted={isMuted}
              thumbnailUrl={item.coverAsset?.url}
              autoplayVisible={true}
              looped={true}
              playerClasses="aspect-auto relative"
              showVControls={false}
            />
            <Button
              variant={"icon"}
              className="rounded-[50px] p-[10px] border-[1px] border-white flex justify-center align-center absolute top-4 right-4"
              onClick={() => setMuted(!isMuted)}
            >
              <div className="overlay-blur">
                {!isMuted && <SpeakerLoudIcon width={20} height={20} color="white" />}
                {isMuted && <SpeakerOffIcon width={20} height={20} color="white" />}
              </div>
            </Button>
          </div>
        ) : (
          <img src={item?.coverAsset?.url} alt={item.name} className="w-full h-full object-cover" />
        )}
        {questAssigned && item?.userProgress && (
          <div className="absolute bottom-0 bg-black/40 w-full text-white py-1 px-4 flex justify-between">
            <div className="flex items-center gap-2">
              <div className="w-[14px] h-[14px]">
                <Icons name="calendar-reminder-outlined" width={14} height={14} fill="white" />
              </div>
              <div className="title-10">Assigned to You</div>
            </div>
            <div className="body-2xs caption-disclaimer">Complete by {formatDate(item.courseEndedAt ?? "")}</div>
          </div>
        )}
      </div>
      <div className="p-6 bg-black flex flex-col gap-2">
        <div className="flex flex-col gap-2 h-20">
          {item?.wordmarkAsset && (
            <div className="max-h-20">
              <img
                src={item?.wordmarkAsset.url}
                alt={item.name}
                className="object-cover max-h-14 max-w-[280px] lg:max-h-16 lg:max-w-[300px]"
              />
            </div>
          )}
          {item?.name && <div className="text-cool-grey-300 title-10">with {item?.authors[0].name}</div>}
        </div>
        <div className="flex flex-col md:flex-row items-end justify-between w-full gap-4">
          <div className="w-full flex flex-col gap-[6px]">
            <ProgressBar
              progressColor="bg-yellow-500"
              value={
                item.type === "daily" ? item.userProgress?.totalDaysCompleted : item.userProgress?.totalLessonsCompleted
              }
              maxValue={item.type === "daily" ? item.userProgress?.totalDays : item.userProgress?.totalLessons}
            />
            <div className="text-white/90 title-10">{`${
              item.type === "daily" ? item.userProgress?.totalDaysCompleted : item.userProgress?.totalLessonsCompleted
            } / ${
              item.type === "daily" ? item.userProgress?.totalDays : item.userProgress?.totalLessons
            } completed`}</div>
          </div>

          <div className="flex justify-end gap-1 items-center">
            {/* <Link to={item?.url} className="w-full flex justify-end"> */}
            <Button onClick={handleContinueLearning} variant={"primary"} className="gap-2 w-full md:w-auto">
              {/* <PlayWhiteIcon /> */}
              <Icons name="play-filled" width={14} height={14} fill="white" />

              <div className="button-text-medium">
                Continue lesson{" "}
                {((item.type === "daily"
                  ? item.userProgress?.totalDaysCompleted
                  : item.userProgress?.totalLessonsCompleted) ?? 0) + 1}
              </div>
            </Button>
            <QuestLandingPage
              questData={item}
              trigger={
                <button type="button" className="rounded-lg">
                  <Tooltip tooltipContent={<p className="caption-disclaimer">Learn more</p>}>
                    <div className="p-2 rounded-[128px] border border-black/10 justify-start items-center gap-2.5 inline-flex">
                      <div className="justify-center items-center flex">
                        <Icons name="list-filled" width={20} height={21} fill="#FFFFFFE5" />
                      </div>
                    </div>
                  </Tooltip>
                </button>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
