// Im not a graphql expert not sure this is standard but hey its works. I only got couple of hours to make this work

import { MutationsOperations } from "./mutations/mutationsOperations";
import { QueriesOperations } from "./queries/queriesOperations";

export enum AuthRequirement {
  REQUIRED = "REQUIRED",
  OPTIONAL = "OPTIONAL",
  NONE = "NONE",
}

export type GraphQLOperation = {
  operationName: QueriesOperations | MutationsOperations;
  authRequirement: AuthRequirement;
};

const authOptionalOperations: GraphQLOperation[] = [
  // QUERIES
  {
    operationName: QueriesOperations.GET_PICKED_FOR_YOU_QUESTS,
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: QueriesOperations.GET_QUEST_BY_ID,
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: QueriesOperations.GET_TRENDING_GLOBALLY_QUESTS,
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: QueriesOperations.GET_QUEST_BY_SLUG_AND_LANGUAGE,
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: QueriesOperations.GET_PICKED_FOR_YOU_QUESTS_PUBLIC,
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: QueriesOperations.GET_SOUNDSCAPES_OF_THE_WEEK,
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: QueriesOperations.GET_MEDITATION_OF_THE_DAY,
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: QueriesOperations.GET_QUESTS_BY_CATEGORY,
    authRequirement: AuthRequirement.OPTIONAL,
  },

  //  MUTATIONS
  {
    operationName: MutationsOperations.ENROLL_USER,
    authRequirement: AuthRequirement.OPTIONAL,
  },
];
const getOperation = (operationName: string | null): GraphQLOperation | undefined =>
  operationName ? authOptionalOperations.find((op) => op.operationName === operationName) : undefined;

/**
 * Retrieves the auth requirement status for an operation.
 * @returns The auth requirement: REQUIRED, OPTIONAL, or NONE.
 */
export const getAuthRequirement = (operationName: string | null): AuthRequirement => {
  return getOperation(operationName)?.authRequirement ?? AuthRequirement.NONE;
};
