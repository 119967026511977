import { Skeleton } from "../Skeleton";

const FeatureCardSkeleton = () => {
  return (
    <div className="flex flex-col gap-6 w-full px-20 py-10 ">
      <div className="w-full flex justify-between">
        <Skeleton className="h-9 w-[285px] rounded-2xl bg-brown-100" />
        <Skeleton className="h-9 w-[160px] rounded-2xl bg-brown-100" />
      </div>
      <div className="flex w-full gap-x-6 overflow-x-scroll no-scrollbar">
        {[...Array(4)].map((_, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div key={index} className="lex flex-col space-y-3">
            <Skeleton className="h-[460px] w-[410px] rounded-xl bg-brown-100" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureCardSkeleton;
