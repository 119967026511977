import { useState } from "react";
import Icons from "./icons/Icons";
import { useMediaPlayerContext } from "context/MediaPlayerV2Context";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import LockedModal from "components/LockedModal";
import { UserAccessStateEnum } from "context/userContext";

interface LinkButtonComponentProps {
  id: string;
  file: string;
  title: string;
  author: string;
  duration?: string;
  imagePath: string;
  imageOverlayText?: string;
  circleThumbnail?: boolean;
  isSoundScapeOnly?: boolean;
  onHover: (image: string) => void;
  onLeave: () => void;
  onClick: (image: string) => void;
  userAccessState: UserAccessStateEnum;
}

const MediaCardComponent = ({
  file,
  id,
  title,
  author,
  duration,
  imagePath,
  imageOverlayText,
  circleThumbnail,
  onHover,
  onLeave,
  onClick,
  isSoundScapeOnly = false,
  userAccessState,
}: LinkButtonComponentProps) => {
  const {
    // playTrack,
    isPlaying,
    track,
    setTrack,
    setSoundscapeMode,
  } = useMediaPlayerContext();

  const { play, pause, playTrack } = useMediaPlayerHook();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handlePlayPause = async () => {
    if (userAccessState !== UserAccessStateEnum.Premium) {
      return setIsModalOpen(true);
    }

    setSoundscapeMode(isSoundScapeOnly);

    const isCurrentTrack = track?.id === id;
    if (isCurrentTrack) {
      isPlaying ? pause() : play();
    } else {
      const newTrack = {
        file,
        id,
        author,
        title,
        imagePath,
      };
      setTrack(newTrack);
      await playTrack(newTrack);
    }
  };

  return (
    <>
      <div
        className="flex-col justify-start items-start gap-3 inline-flex"
        onMouseEnter={() => onHover(imagePath)}
        onMouseLeave={() => onLeave()}
        onClick={() => onClick(imagePath)}
        onKeyDown={() => {}}
      >
        <div
          className={`w-40 h-40 ${
            circleThumbnail ? "rounded-[128px]" : "rounded-2xl"
          } flex-col justify-end items-start flex bg-cover bg-no-repeat group cursor-pointer`}
          style={{
            backgroundImage: `url('${imagePath}')`,
          }}
          onClick={handlePlayPause}
          onKeyDown={() => {}}
        >
          <div className="hidden h-10 w-10 p-2.5 mr-[8px] mb-[-10px] z-10 bg-[#0f131a] rounded-[128px] justify-start self-end items-center gap-2.5 group-hover:inline-flex">
            {track?.id === id ? (
              !isPlaying ? (
                <Icons name="play-filled" fill="white" height={20} width={20} />
              ) : (
                <Icons
                  name="pause-filled"
                  fill="white"
                  height={20}
                  width={20}
                />
              )
            ) : (
              <Icons name="play-filled" fill="white" height={20} width={20} />
            )}
          </div>
          {imageOverlayText ? (
            <div className="w-full px-3 py-1 rounded-b-2xl bg-black/40 backdrop-blur-md justify-start items-start inline-flex">
              <div className="grow shrink basis-0 h-[15px] justify-start items-center flex">
                <div className="justify-start items-center gap-1.5 flex">
                  <div className="scale-75 relative">
                    <Icons
                      name="past-outlined"
                      height={24}
                      width={24}
                      fill="white"
                      className="pointer-events-none"
                    />
                  </div>
                  <div className="text-white text-[10px] body-2xs leading-[15px] tracking-wide">
                    {imageOverlayText}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="self-stretch h-[62px] flex-col justify-start items-start gap-1 flex w-40">
          <div className="self-stretch text-white title-8 leading-[18px] tracking-tight text-ellipsis line-clamp-1">
            {title}
          </div>
          <div className="text-[#b3b8c1] body-small leading-tight tracking-tight">
            {author}
          </div>
          {duration ? (
            <div className="text-[#b3b8c1] caption-disclaimer leading-none tracking-wide hidden md:flex">
              {duration}
            </div>
          ) : null}
        </div>
      </div>
      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          userAccessState={userAccessState}
        />
      </div>
    </>
  );
};

export default MediaCardComponent;
