import { cn } from "libs/classMerger";
import { MicrosoftIcon } from "./icons/BrandsIcon";

export function LoginWithMicrosoftButton({
  handleClick,
  iconSize = 16,
  customClass,
}: {
  handleClick: () => void;
  iconSize?: number;
  customClass?: string;
}) {
  return (
    <button
      type="button"
      onClick={handleClick}
      className={cn(
        "flex w-[420px] justify-center items-center px-4 py-3 gap-2 border border-cool-grey-300 rounded-lg",
        customClass,
      )}
    >
      <MicrosoftIcon size={iconSize} />
      <p className="text-cool-grey-500 button-text-medium">Log in with Microsoft</p>
    </button>
  );
}
