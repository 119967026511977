import { Button } from "components/Button";
import { ProgramsWallSkeleton } from "components/loading/ProgramsWallSkeleton";
import type { ICategoryNode } from "types/interfaces";

const ProgramsButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: JSX.Element[];
}) => {
  return (
    <Button
      onClick={onClick}
      className="px-0 py-0 flex-1 h-full overflow-hidden rounded-xl sm:rounded-2xl md:rounded-lg lg:rounded-2xl"
      data-testid="career-collage-item-desktop"
    >
      {children}
    </Button>
  );
};

export const ProgramsHeroContainer = ({
  categoriesData,
  onCategoryClick,
  loading,
}: {
  categoriesData: ICategoryNode[];
  onCategoryClick: (category: ICategoryNode) => void;
  loading?: boolean;
}) => {
  if (loading) return <ProgramsWallSkeleton />;

  return (
    <div
      className="px-2 md:px-5 flex gap-x-2 sm:gap-x-4 md:gap-x-2 lg:gap-x-4 lg:pt-3 lg:pb-10 pb-6 lg:max-w-full"
      data-testid="category-collage-section"
    >
      <div className="hidden md:flex basis-2/5 items-stretch" data-testid="collage-column-1">
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[0])}>
          <img
            alt={categoriesData[0].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-01-8e9123fea6648b8840a4f1b72e6aa305.jpg?vsn=d"
          />
          <img
            alt={categoriesData[0].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-01-m-883c0d0e4369160eb48e1d2f1b369590.jpg?vsn=d"
          />
        </ProgramsButton>
      </div>

      <div
        className="hidden md:flex basis-[32.7%] md:flex-col md:gap-y-2 lg:gap-y-4 items-stretch"
        data-testid="collage-column-2"
      >
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[1])}>
          <img
            alt={categoriesData[1].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-02-e737cc43d884ee4289378786fe66f876.jpg?vsn=d"
          />
          <img
            alt={categoriesData[1].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-02-m-c2ccaa54248a211430e9fe130bf64be9.jpg?vsn=d"
          />
        </ProgramsButton>
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[2])}>
          <img
            alt={categoriesData[2].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-03-048a1488a64275306fc1b4e469c8289f.jpg?vsn=d"
          />
          <img
            alt={categoriesData[2].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-03-m-c8ff397b649bf637f15e7701e004f057.jpg?vsn=d"
          />
        </ProgramsButton>
      </div>

      <div
        className="hidden md:flex basis-[27.2%] md:flex-col md:gap-y-2 lg:gap-y-4 items-stretch"
        data-testid="collage-column-3"
      >
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[3])}>
          <img
            alt={categoriesData[3].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-04-8ccc181fedacc11a546b230aff95ce81.jpg?vsn=d"
          />
          <img
            alt={categoriesData[3].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-04-m-25c3212f889ec15a13b949becc8f4b9e.jpg?vsn=d"
          />
        </ProgramsButton>
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[4])}>
          <img
            alt={categoriesData[4].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-05-5bf5b14b9ebad1b71dd69df15a9a2e61.jpg?vsn=d"
          />
          <img
            alt={categoriesData[4].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-05-m-2df163bc7f105f647cd12c3a256a7475.jpg?vsn=d"
          />
        </ProgramsButton>
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[5])}>
          <img
            alt={categoriesData[5].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-06-6900207fbc5f03cc87dad777b63730f5.jpg?vsn=d"
          />
          <img
            alt={categoriesData[5].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-06-m-357f0d9874c02f0a61a45159377fbd1d.jpg?vsn=d"
          />
        </ProgramsButton>
      </div>

      <div
        className="flex md:hidden basis-1/2 flex-col gap-y-2 sm:gap-y-4 items-stretch"
        data-testid="mobile-collage-column-1"
      >
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[0])}>
          <img
            alt={categoriesData[0].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-01-8e9123fea6648b8840a4f1b72e6aa305.jpg?vsn=d"
          />
          <img
            alt={categoriesData[0].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-01-m-883c0d0e4369160eb48e1d2f1b369590.jpg?vsn=d"
          />
        </ProgramsButton>

        <ProgramsButton onClick={() => onCategoryClick(categoriesData[2])}>
          <img
            alt={categoriesData[2].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-03-048a1488a64275306fc1b4e469c8289f.jpg?vsn=d"
          />
          <img
            alt={categoriesData[2].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-03-m-c8ff397b649bf637f15e7701e004f057.jpg?vsn=d"
          />
        </ProgramsButton>

        <ProgramsButton onClick={() => onCategoryClick(categoriesData[4])}>
          <img
            alt={categoriesData[4].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-05-5bf5b14b9ebad1b71dd69df15a9a2e61.jpg?vsn=d"
          />
          <img
            alt={categoriesData[4].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-05-m-2df163bc7f105f647cd12c3a256a7475.jpg?vsn=d"
          />
        </ProgramsButton>
      </div>
      <div
        className="flex md:hidden basis-1/2 flex-col gap-y-2 sm:gap-y-4 items-stretch"
        data-testid="mobile-collage-column-2"
      >
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[1])}>
          <img
            alt={categoriesData[1].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-02-e737cc43d884ee4289378786fe66f876.jpg?vsn=d"
          />
          <img
            alt={categoriesData[1].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-02-m-c2ccaa54248a211430e9fe130bf64be9.jpg?vsn=d"
          />
        </ProgramsButton>
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[3])}>
          <img
            alt={categoriesData[3].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-04-8ccc181fedacc11a546b230aff95ce81.jpg?vsn=d"
          />
          <img
            alt={categoriesData[3].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-04-m-25c3212f889ec15a13b949becc8f4b9e.jpg?vsn=d"
          />
        </ProgramsButton>
        <ProgramsButton onClick={() => onCategoryClick(categoriesData[5])}>
          <img
            alt={categoriesData[5].name}
            className="hidden md:block w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/cat-06-6900207fbc5f03cc87dad777b63730f5.jpg?vsn=d"
          />
          <img
            alt={categoriesData[5].name}
            className="md:hidden w-full h-full object-cover transition-all ease-in-out duration-500 hover:scale-105"
            src="https://cdn-assets.workway.app/images/programs/collage/mobile/cat-06-m-357f0d9874c02f0a61a45159377fbd1d.jpg?vsn=d"
          />
        </ProgramsButton>
      </div>
    </div>
  );
};
