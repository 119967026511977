import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../Dialog";
import { cn } from "libs/classMerger";
import "components/MediaPlayer.css";
import { Button } from "../Button";
import Icons from "../icons/Icons";
import MediaPlayerV2TimerModal from "./MediaPlayerV2TimerModal";
import MediaPlayerV2Mixer from "./MediaPlayerV2Mixer";
import {
  MediaPlayerV2Context,
  useMediaPlayerContext,
} from "context/MediaPlayerV2Context";

interface MediaPlayerV2ContentInfoModalProps {
  title?: string;
  className?: string;
  soundscapeMode?: boolean;
}

const MediaPlayerV2ContentInfoModal = ({
  title,
  soundscapeMode,
}: MediaPlayerV2ContentInfoModalProps) => {
  const playerContext = useMediaPlayerContext();
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, [title]);

  return (
    <Dialog>
      <DialogTrigger className={cn("overflow-hidden whitespace-nowrap")}>
        <p
          ref={textRef}
          className={cn(
            "max-w-[560px] truncate hover:underline",
            isOverflowing
              ? "hover:animate-marquee-text hover:overflow-visible hover:whitespace-nowrap"
              : ""
          )}
        >
          {title}
        </p>
      </DialogTrigger>
      <DialogContent className="bg-black/80 max-w-[398px] md:max-w-[480px] px-6 py-4 gap-y-6 backdrop-blur border-0 z-100">
        <DialogHeader className="relative">
          <DialogTitle className="!heading-9 text-white text-left">
            {playerContext?.soundscapeMode
              ? "About this soundscape"
              : "About this meditaion"}
          </DialogTitle>
          <DialogClose asChild>
            <Button
              variant="icon"
              size="icon"
              className="text-white/70 hover:text-white absolute right-0 top-0 bottom-0 !mt-0"
            >
              <Icons name="x-outlined" width={16} height={16} />
            </Button>
          </DialogClose>
        </DialogHeader>
        <div className="relative flex flex-col gap-y-4">
          <div className="flex items-start gap-4">
            <div className="size-[100px] md:size-[140px] object-cover rounded-lg">
              <img
                src="/img/featured-meditation-1.jpeg"
                className={
                  playerContext?.soundscapeMode ? "rounded-full" : "rounded-lg"
                }
                alt="featured-meditation"
              />
            </div>
            <div className="flex-col items-start gap-4 self-center">
              <div className="heading-8 text-white">{title}</div>
              <div className="caption-disclaimer text-white-90a content-secondary">
                Vishen
              </div>
              <div className="flex items-center pt-1">
                <div className="heading-8 text-[#B3B8C1] ">4.6</div>
                <Icons
                  name="star-filled"
                  width={12}
                  height={12}
                  fill="#B3B8C1"
                  className="pl-0.5"
                />
              </div>
            </div>
          </div>
          <div className="body-small text-white">
            {!playerContext?.soundscapeMode
              ? "Witness the birth of a new world with A New Planet is Born, a cosmic soundscape that captures the awe and majesty of creation"
              : "Being happy now is important, but so is thinking about where you want to go. Both are imperative for living a fulfilling life. In this meditation, you’ll uncover your vision for the next three years and experience it with all five senses."}
          </div>
          <div className="flex gap-1">
            <div className="bg-white-12a p-1 rounded-[100px] border-[1px] border-cool-grey-500">
              <span className="body-2-xs text-content-secondary text-white-90a px-1">
                Achieving goals
              </span>
            </div>
            <div className="bg-white-12a p-1 rounded-[100px] border border-cool-grey-500">
              <span className="body-2-xs text-content-secondary text-white-90a px-1">
                Clarity on life vision
              </span>
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-white-12a" />
        <div>
          <div className="bg-white-8a rounded-lg border border-content-secondary hover:bg-content-secondary px-2 py-3 cursor-pointer">
            <div className="relative px-2">
              {playerContext?.soundscapeMode ? (
                <MediaPlayerV2TimerModal fullScreenMode={true} />
              ) : (
                <MediaPlayerV2Mixer fullScreenMode={true} />
              )}
              <Button
                variant="icon"
                size="icon"
                className="text-white/70 hover:text-white absolute inset-0 left-auto"
              >
                <Icons name="chevron-right-outlined" width={16} height={16} />
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MediaPlayerV2ContentInfoModal;
