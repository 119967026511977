import { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/helper.css";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useLogout } from "hooks/useLogout";
import { NavigationEnum } from "router/navigationType";
import NavbarAvatar from "./Avatar";
import LockedModal from "./LockedModal";
import Icons from "./icons/Icons";

enum DropdownSections {
  profile = "profile",
  logout = "logout",
}

enum DropdownLabels {
  profile = "My profile",
  support = "Support",
  logout = "Log out",
}

const NavDropdownMenuTabletMobile = ({
  userData,
  setIsMenuOpen,
  menuRender,
}: {
  menuRender: Array<any>;
  userData: any;
  setIsMenuOpen: () => void;
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [activeAnimation, setActiveAnimation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const links = menuRender;
  const { userAccessState } = useUserState();
  const logout = useLogout();

  function Logout() {
    setIsModalOpen(false);
    console.log("logout");
    logout();
  }

  const linksPage2 = () => {
    return [
      {
        icon: <Icons name="user-outlined" height={20} width={20} fill="#292D38" />,
        label: DropdownLabels.profile,
        section: DropdownSections.profile,
        link: "/account/profile",
        onClick: () => console.log("usericon"),
      },
      {
        icon: <Icons name="life-buoy-help-outlined" height={20} width={20} fill="#292D38" />,
        label: DropdownLabels.support,
        section: DropdownSections.profile,
        link: "/",
        onClick: () => console.log("supporticon"),
      },
      {
        icon: <Icons name="log-out-filled" height={20} width={20} fill="#292D38" />,
        label: DropdownLabels.logout,
        section: DropdownSections.logout,
        link: "/",
        onClick: () => {
          Logout();
        },
      },
    ];
  };

  const freemiumLinks = () => {
    return [
      {
        icon: <Icons name="life-buoy-help-outlined" height={20} width={20} fill="#292D38" />,
        label: DropdownLabels.support,
        section: DropdownSections.profile,
        link: "/",
        onClick: () => {
          console.log("support");
        },
      },
      {
        icon: <Icons name="log-out-filled" height={20} width={20} fill="#292D38" />,
        label: DropdownLabels.logout,
        section: DropdownSections.logout,
        link: "/",
        onClick: () => {
          Logout();
        },
      },
    ];
  };

  const pageNumberHandler = (value: number) => {
    setActiveAnimation(true);
    setPageNumber(value);
  };

  const userDetails = () => {
    return (
      <div className="flex gap-4 items-center justify-center py-3 ">
        <div className="relative">
          <img src={userData.profilePhoto} alt="user" className="w-10 h-10 rounded-full" />
          <NavbarAvatar
            imageUrl={userData?.profilePhoto}
            fallbackText={`${userData?.firstName.charAt(0)}${userData?.lastName.charAt(0)}`}
            className="h-8 w-8"
          />
          <img src={userData.smallLogo} alt="user" className="absolute bottom-0 right-0 w-4 h-4" />
        </div>
        <div className="flex flex-col px-4 py-2">
          <div className="text-sm font-semibold sm:title-9 text-cool-grey-600">{userData.name}</div>
          <div className="text-xs body-2-xs text-cool-grey-500">{userData.email}</div>
        </div>
      </div>
    );
  };

  const adminPanel = () => {
    return (
      <div className="px-4 md:px-8 pb-4">
        <div className="py-2 w-full text-center bg-cool-grey-200 rounded-lg">
          <Link className="text-cool-grey-600 body-sm font-semibold cursor-pointer" to={userData.adminPanelURL}>
            Go to admin panel
          </Link>
        </div>
      </div>
    );
  };

  const handleClickTabNavigation = ({
    e,
    link,
  }: {
    e: React.MouseEvent<HTMLAnchorElement>;
    link: string;
  }) => {
    if (
      userAccessState !== UserAccessStateEnum.Premium &&
      link.toLocaleLowerCase() !== NavigationEnum.Home.toLocaleLowerCase() &&
      link.toLocaleLowerCase() !== NavigationEnum.Programs.toLocaleLowerCase()
    ) {
      setIsModalOpen(true);
      e.preventDefault();
    } else {
      console.log("else", link);
      setIsMenuOpen();
    }
  };
  //Freemium state dropdown menu for tablet
  const NavDropdownTabletFreemium = () => {
    return (
      <div className="fixed md:absolute h-[95vh] md:h-auto w-full top-12 z-[50] bg-white border-cool-grey-300 animate-fade-in  ">
        <div className="sm:flex sm:flex-col  sm:h-full sm:gap-y-3">
          <div className="flex flex-col border-b border-cool-grey-300 px-4 md:px-8 ">
            {links.map((el) => (
              <Link
                to={el.link}
                key={el.id}
                className="py-4 text-cool-grey-600 title-6 px-2"
                // onClick={setIsMenuOpen}
                onClick={(e) => handleClickTabNavigation({ e, link: el.link })}
              >
                {el.label}
              </Link>
            ))}
          </div>
          <div className=" md:px-8 py-4 flex flex-col gap-4 border-b px-4 border-cool-grey-300">
            {freemiumLinks().map((el) => (
              <Link
                to={el.link}
                key={el.label}
                className="flex gap-3 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600  px-2 rounded-lg"
                onClick={el.onClick}
              >
                <div className="">{el.icon}</div>
                <div className="title-6">{el.label}</div>
              </Link>
            ))}
          </div>
        </div>

        <div className="m-auto flex max-w-[480px] rounded-2xl">
          <LockedModal onCancel={() => setIsModalOpen(false)} open={isModalOpen} userAccessState={userAccessState} />
        </div>
      </div>
    );
  };

  const NavdropDownTablet = () => {
    return (
      <div className="fixed md:absolute h-[95vh] md:h-auto w-full top-12 z-[50] bg-white border-cool-grey-300 animate-fade-in  ">
        {pageNumber === 1 ? (
          <div
            className={`${
              activeAnimation ? "fade-in-15 animation-right-left " : "sm:flex sm:flex-col  sm:h-full sm:gap-y-3"
            }`}
          >
            <div className="flex flex-col border-b border-cool-grey-300 px-4 md:px-8 pb-3 ">
              {links.map((el) => (
                <Link
                  to={el.link}
                  key={el.label}
                  className="py-4 px-2 text-cool-grey-600  title-6 "
                  onClick={setIsMenuOpen}
                >
                  {el.label}
                </Link>
              ))}
            </div>
            <div
              className="px-4 flex justify-between items-center gap-4 md:px-8"
              onClick={() => pageNumberHandler(2)}
              onKeyDown={() => pageNumberHandler(2)}
              onKeyPress={() => pageNumberHandler(2)}
              onKeyUp={() => pageNumberHandler(2)}
              role="button"
            >
              <Icons name="chevron-left-filled" width={24} height={24} fill="#979CA5" />
              {userDetails()}
              <div>
                <Icons name="chevron-right-filled" width={24} height={24} fill="#979CA5" />
              </div>
            </div>
            {userData.isAdmin && adminPanel()}
          </div>
        ) : (
          <div className="fade-in-animation-left-right">
            <div className="border-b border-cool-grey-300">
              <div
                className="flex gap-4 items-center px-4 md:px-8"
                onClick={() => pageNumberHandler(1)}
                onKeyDown={() => pageNumberHandler(1)}
                onKeyPress={() => pageNumberHandler(1)}
                onKeyUp={() => pageNumberHandler(1)}
                role="button"
              >
                <div className="rotate-180">
                  <Icons name="chevron-right-filled" width={24} height={24} fill="#979CA5" />
                </div>
                {userDetails()}
              </div>
              {userData.isAdmin && adminPanel()}
            </div>
            <div className="px-4 md:px-8 py-4 flex flex-col gap-4 border-b border-cool-grey-300">
              {linksPage2()
                .filter(({ section }) => section === DropdownSections.profile)
                .map((el) => (
                  <Link
                    onClick={el.onClick}
                    to={el.link}
                    key={el.label}
                    className="flex gap-3 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-2 rounded-lg"
                  >
                    <div>{el.icon}</div>
                    <div className=" title-6 ">{el.label}</div>
                  </Link>
                ))}
            </div>
            <div className="px-4 md:px-8 py-4 flex flex-col gap-4 ">
              {linksPage2()
                .filter(({ section }) => section === DropdownSections.logout)
                .map((el) => (
                  <Link
                    to={el.link}
                    onClick={el.onClick}
                    key={el.label}
                    className="flex gap-3 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-2 rounded-lg"
                  >
                    <div>{el.icon}</div>
                    <div className="title-6">{el.label}</div>
                  </Link>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {userAccessState === UserAccessStateEnum.Freemium && <NavDropdownTabletFreemium />}
      {userAccessState === UserAccessStateEnum.Premium && <NavdropDownTablet />}
      {userAccessState === UserAccessStateEnum.Unauthenticated && <></>}
    </>
  );
};

export default NavDropdownMenuTabletMobile;
