import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Button } from "components/Button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/Tab";
import Icons from "components/icons/Icons";
import type { IMedia, IPage, IQuest, ISection, ITask } from "types/interfaces";
import { QuestLandingPage } from "../QuestLanding/QuestLandingPage";
import { Lessons } from "./Lessons";
import "./GuideContent.css";
import ProgressBar from "components/ProgressBar";
import { Tooltip } from "components/Tooltip";
// @ts-ignore
import DOMPurify from "dompurify";
import { cn } from "libs/classMerger";
import { useState } from "react";

const QuestConsumptionHeader = ({
  quest,
  onStartProgram,
}: {
  quest: IQuest;
  onStartProgram: () => void;
}) => {
  const completedLessons =
    quest.type === "weekly" ? quest.userProgress?.totalLessonsCompleted : quest.userProgress?.totalDaysCompleted;
  const totalLessons = quest.type === "weekly" ? quest.userProgress?.totalLessons : quest.userProgress?.totalDays;

  return (
    <section className="px-4 pt-2 lg:pl-4 lg:pr-9 hidden lg:flex lg:flex-col w-full gap-1">
      <QuestLandingPage
        questData={quest}
        trigger={
          <button
            type="button"
            className="w-full flex gap-3 items-center justify-between rounded-lg p-2 transition-colors ease-in hover:bg-brown-200"
          >
            <img className="rounded-md w-28" src={quest.coverAsset?.url} alt={quest.name} />
            <div className="flex flex-col flex-1 text-left">
              <h4 className="heading-9 text-cool-grey-700">{quest.name}</h4>
              <p className="title-10 text-cool-grey-450">{quest.authors[0].name}</p>
            </div>
            <ChevronRightIcon />
          </button>
        }
      />
      {!quest.userProgress?.started && (
        <Button variant={"primary"} className="gap-2" onClick={onStartProgram}>
          <Icons name="play-filled" />
          Start Program
        </Button>
      )}
      {quest.userProgress?.started && (
        <div className="flex flex-col items-center gap-2 self-stretch justify-center mt-0.5 mx-2">
          <ProgressBar progressClasses="bg-yellow-500" value={completedLessons} maxValue={totalLessons} />
          <div className="title-9 text-cool-grey-500 text-ellipsis line-clamp-1 self-stretch">
            {completedLessons} of {totalLessons} lessons completed
          </div>
        </div>
      )}
    </section>
  );
};

const QuestTabs = ({
  quest,
  page,
  onLessonSelect,
  onMarkTaskAsCompleted,
}: {
  quest: IQuest;
  page?: IPage;
  onLessonSelect: (page: IPage) => void;
  onMarkTaskAsCompleted: (task: ITask) => void;
}) => {
  return (
    <div className="flex items-center gap-6 lg:pt-6 lg:bg-transparent border-b border-cool-grey-300">
      <Tabs defaultValue="guide" className="w-full">
        <TabsList className="flex flex-row gap-x-6 border-b border-cool-grey-300 justify-start px-4 pb-0 sticky top-0 bg-white lg:bg-brown-50 h-11.5 rounded-none">
          <TabsTrigger
            className="rounded-none border-x-0 flex w-1/2 lg:w-auto gap-x-2 flex-row items-center pb-4 justify-center lg:justify-start text-cool-grey-500 border-0 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 data-[state=active]:text-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
            value="guide"
          >
            <div className="flex gap-[6px] justify-between items-center">
              <Icons name="compass-outlined" />
              <div className="title-8">Guide</div>
            </div>
          </TabsTrigger>
          <TabsTrigger
            className="rounded-none border-x-0 flex w-1/2 lg:w-auto gap-x-2 flex-row items-center pb-4 justify-center lg:justify-start text-cool-grey-500 border-0 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 data-[state=active]:text-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
            value="lessons"
          >
            <div className="flex gap-[6px] items-center">
              <Icons name="book-open-outlined" />
              <div className="title-8">Lessons</div>
            </div>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="guide">
          <div className="lg:h-dvh lg:overflow-y-auto">
            <GuideTabContent page={page} onMarkTaskAsCompleted={onMarkTaskAsCompleted} />
          </div>
        </TabsContent>
        <TabsContent value="lessons">
          <div className="lg:h-dvh lg:overflow-y-auto">
            <Lessons quest={quest as unknown as IQuest} currentPage={page} onLessonSelect={onLessonSelect} />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

const RenderFileContentType = ({
  section,
  owned = false,
}: {
  section: ISection;
  owned: boolean;
}) => {
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  return (
    <div key={section.id} className="bg-brown-200 rounded-2xl p-3 flex flex-col gap-3">
      <div className="flex items-center space-x-4">
        {section.media && (
          <img
            className="rounded-lg object-cover w-[100px] h-40"
            src={section.media?.coverAsset.url}
            alt={section.media?.title}
          />
        )}
        {section.primaryAsset && (
          <img
            alt={section.primaryAsset?.name}
            className="rounded-lg object-cover"
            src="https://cdn-assets.workway.app/images/quests/doc-4601e1025fd4b39d8aec5b684e6634ba.svg?vsn=d"
          />
        )}
        <div className="flex flex-col space-y-1">
          <span className="flex items-center gap-1">
            <Icons name="file-text-outlined" height={12} width={12} />
            <p className="title-10 text-teal-600">Document</p>
          </span>
          <div className="title-8">{section.info.title || section.media?.title}</div>
          <div className="caption-disclaimer">{section.media?.author.name}</div>
        </div>
        {!owned && <Icons name="lock-filled" className="text-black" />}
      </div>
      <div className="flex items-end justify-between w-full">
        {section.media?.description &&
          (!openMoreInfo ? (
            <button
              className="flex items-end text-cool-grey-350 gap-1"
              type="button"
              onClick={() => setOpenMoreInfo(true)}
            >
              <Icons name="info-outlined" className={"text-cool-grey-350 !text-cool-grey-500"} />
              <div className="title-10 text-cool-grey-350 !text-cool-grey-500">More info</div>
            </button>
          ) : (
            <button
              className="flex items-end text-cool-grey-350 gap-1"
              type="button"
              onClick={() => setOpenMoreInfo(false)}
            >
              <Icons name="minus-circle-outlined" className={"text-cool-grey-350 !text-cool-grey-500"} />
              <div id="more-info-text" className="title-10 text-cool-grey-350 !text-cool-grey-500">
                Minimize
              </div>
            </button>
          ))}
        <Button variant={"ghost"} className="px-2 py-2">
          <a
            href={section.primaryAsset?.url || section.media?.mediaAsset?.url}
            target="_blank"
            className="no-underline"
            rel="noreferrer"
          >
            Download
          </a>
        </Button>
      </div>
      {openMoreInfo && <hr className="text-black mt-2" />}
      {section.media?.description && (
        <div className={cn("mt-2 body-small text-cool-grey-350 !text-cool-grey-500", openMoreInfo ? "" : "hidden")}>
          {section.media.description}
        </div>
      )}
    </div>
  );
};

const RenderMediaType = ({
  section,
  owned = false,
}: {
  section: ISection;
  owned: boolean;
}) => {
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const handlePlayButtonClick = (media: IMedia, type: string) => {
    // TODO: Play the media once Meditations and soundscapes are completed
  };

  switch (section.media?.type) {
    case "file":
      return <RenderFileContentType section={section} owned={owned} />;
    case "video":
    case "audio":
    case "meditation":
      return (
        <div key={section.id} className="audio-player-gradient flex flex-col gap-3 p-3 rounded-2xl text-white">
          <div className="flex space-x-4 w-full">
            <img
              className={cn(
                "rounded-lg object-cover",
                section.media.type === "video" ? "w-[140px] h-[80px]" : "w-[90px] h-[90px]",
              )}
              src={section.media.coverAsset.edgeUrl}
              alt={section.media.title}
            />
            <div className="flex justify-between items-center w-full">
              <div className="flex flex-col space-y-1 justify-center">
                <span className="flex items-center">
                  <Icons
                    name={
                      section.media.type === "meditation"
                        ? "spiritual-filled"
                        : section.media.type === "video"
                          ? "film-filled"
                          : "music-outlined"
                    }
                    height={12}
                    width={12}
                    className={"text-teal-300 mr-0.5"}
                  />
                  <p className="title-10 text-teal-300">
                    {section.media.type === "meditation"
                      ? "Meditation"
                      : section.media.type === "video"
                        ? "Video"
                        : "Audio"}
                  </p>
                </span>
                <div className="title-8 text-white">{section.media.title}</div>
                <div className="caption-disclaimer">{section.media.author.name}</div>
              </div>
              {!owned && <Icons name="lock-filled" className="text-white" />}
            </div>
          </div>
          <div className="flex items-end justify-between">
            {section.media.description && !openMoreInfo ? (
              <button
                className="flex items-end text-cool-grey-350 gap-1"
                type="button"
                onClick={() => setOpenMoreInfo(true)}
              >
                <Icons name="info-outlined" className={"text-white"} />
                <div className="title-10 text-white">More info</div>
              </button>
            ) : (
              <button
                className="flex items-end text-cool-grey-350 gap-1"
                type="button"
                onClick={() => setOpenMoreInfo(false)}
              >
                <Icons name="minus-circle-outlined" className={"text-white"} />
                <div id="more-info-text" className="title-10 text-white">
                  Minimize
                </div>
              </button>
            )}
            <div className="flex gap-1">
              {/* <Tooltip tooltipContent={<p className="caption-disclaimer">Favorite</p>}>
                <button
                  type="button"
                  className="bg-black-4a button-text-medium rounded-[100px]"
                  disabled={!owned}
                >
                  <Icons name="bookmark-outlined" className={"h-6 w-6 text-white mr-1"} />
                </button>
              </Tooltip> */}
              <Tooltip tooltipContent={<p className="caption-disclaimer">Play</p>}>
                <button
                  type="button"
                  onClick={() => handlePlayButtonClick(section.media, "video")}
                  className="bg-black-4a button-text-medium rounded-[100px]"
                  disabled={!owned}
                >
                  <Icons name="play-circle-filled" height={40} width={40} className={"text-white mr-1"} />
                </button>
              </Tooltip>
            </div>
          </div>
          {openMoreInfo && <hr className="text-black mt-2" />}
          <div className={cn("mt-2 body-small text-white", openMoreInfo ? "" : "hidden")}>
            {section.media.description}
          </div>
        </div>
      );
    default:
      return null;
  }
};

const GuideContent = ({
  section,
  owned = true,
}: {
  section: ISection;
  owned: boolean;
}) => {
  switch (section.type) {
    case "text":
      return (
        <div
          key={section.id}
          className="quest-guide-text-content"
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(section.info.body),
          }}
        />
      );
    case "file":
      return <RenderFileContentType section={section} owned={owned} />;
    case "audio":
    case "video":
    case "media":
      return <RenderMediaType section={section} owned={owned} />;
    default:
      return <></>;
  }
};

const GuideTabContent = ({
  page,
  onMarkTaskAsCompleted,
}: {
  page?: IPage;
  onMarkTaskAsCompleted: (task: ITask) => void;
}) => {
  return (
    <div className="pt-4 px-4 lg:pl-6 lg:pr-9 space-y-6 quest-guide-text-content">
      {page?.sections
        .filter((section) => section.position !== 1)
        ?.map((section) => {
          return <GuideContent key={section.id} section={section} owned={page.owned ?? true} />;
        })}
      {page?.tasks && page?.tasks?.length > 0 && (
        <div>
          <p className="title-7">
            {page.tasks?.filter((task) => task.completed).length}/{page.tasks?.length} tasks completed
          </p>
          <ul className="!pl-0">
            {page.tasks?.map((task) => (
              <li
                key={task.id}
                className="mt-4 border border-cool-grey-300 rounded-lg flex space-x-2 items-center py-4 px-6"
              >
                {task.completed && (
                  <div className="text-brown-700">
                    <Icons name="checklist-circle-filled" height={24} width={24} />
                  </div>
                )}
                {!task.completed && (
                  <button
                    type="button"
                    className="h-6 w-6 pr-1 cursor-pointer"
                    onClick={() => onMarkTaskAsCompleted(task)}
                  >
                    <span className="text-black-18a">
                      <Icons name="circle-filled" height={24} width={24} />
                    </span>
                  </button>
                )}
                <p className="body">{task.name}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="min-h-[20px]"> </div>
    </div>
  );
};

const QuestSideBar = ({
  quest,
  page,
  onLessonSelect,
  onStartProgram,
  onMarkTaskAsCompleted,
}: {
  quest: IQuest;
  page?: IPage;
  onLessonSelect: (page: IPage) => void;
  onStartProgram: () => void;
  onMarkTaskAsCompleted: (task: ITask) => void;
}) => {
  return (
    <aside className="xl:w-[520px] lg:w-[460px] md:w-[360px] lg:bg-brown-50 overflow-y-auto relative flex-1 lg:flex-none pb-[50px] lg:pb-0">
      <QuestConsumptionHeader quest={quest} onStartProgram={onStartProgram} />
      <QuestTabs
        quest={quest}
        page={page}
        onLessonSelect={onLessonSelect}
        onMarkTaskAsCompleted={onMarkTaskAsCompleted}
      />
    </aside>
  );
};

export default QuestSideBar;
