import type React from "react";
import { useEffect, useState } from "react";
import { cn } from "libs/classMerger";
import { useMediaPlayerContext } from "context/MediaPlayerV2Context";

interface MediaPlayerV2VideoControllerProps {
  videoPlayerRef: React.LegacyRef<HTMLVideoElement>;
  src: string;
  poster: string;
  muted: boolean;
  loop: boolean;
  autoPlay: boolean;
  mimeType: string;
  cssClass?: string;
  onPlay: React.ReactEventHandler<HTMLVideoElement>;
  onPause: React.ReactEventHandler<HTMLVideoElement>;
  onClick: React.ReactEventHandler<HTMLVideoElement>;
  immersionSrc?: any;
}
// [ ] rename -> not a controller
const MediaPlayerV2VideoController = ({
  videoPlayerRef,
  src,
  poster,
  muted,
  loop,
  autoPlay,
  mimeType,
  cssClass,
  onPlay,
  onPause,
  onClick,
  immersionSrc,
}: MediaPlayerV2VideoControllerProps) => {
  const [isImmersionMode, setIsImmersionMode] = useState<boolean | undefined>(
    false
  );
  const { immersionEnabled } = useMediaPlayerContext();

  useEffect(() => {
    setIsImmersionMode(immersionEnabled);
  }, [immersionEnabled]);

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <video
      ref={videoPlayerRef}
      src={isImmersionMode ? immersionSrc : src}
      poster={!isImmersionMode ? poster : undefined}
      muted={muted}
      loop={loop}
      className={cn("w-full h-full object-cover", cssClass)}
      autoPlay={autoPlay}
      onPlay={onPlay}
      onPause={onPause}
      onClick={onClick}
      controlsList="nodownload"
      controls={false}
      aria-label="Video Player"
      onError={() => console.error("Failed to load video.")}
    >
      Your browser does not support the video element.
      <source src={src} type={mimeType} />
    </video>
  );
};

export default MediaPlayerV2VideoController;
