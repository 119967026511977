import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import { GET_QUESTS_ASSIGMENTS } from "services/graphql/queries/index";
import type { IAssignedQuestEdge, IAssignedQuestNode } from "types/interfaces";

function useAssignedQuests(first = 5) {
  const { isAuthenticated } = useUserState();
  const { loading, error, data } = useQuery(GET_QUESTS_ASSIGMENTS, {
    skip: !isAuthenticated,
    variables: {
      first,
    },
  });

  const questData: IAssignedQuestNode[] =
    data?.currentWorkwayQuestAssignments?.edges?.map((edge: IAssignedQuestEdge) => edge.node) || [];

  return { questData, loading, error };
}

export default useAssignedQuests;
