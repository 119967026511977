import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import { GET_PICKED_FOR_YOU_QUESTS, GET_PICKED_FOR_YOU_QUESTS_PUBLIC } from "services/graphql/queries/index";

function useGetPickedForYouQuests() {
  const { isAuthenticated } = useUserState();

  const query = isAuthenticated ? GET_PICKED_FOR_YOU_QUESTS : GET_PICKED_FOR_YOU_QUESTS_PUBLIC;

  const { data, loading, error } = useQuery(query, {
    variables: { first: 4 },
  });

  const pickedForYouRecommendations = isAuthenticated
    ? data?.recommendedB2bQuestsPickedForYou.edges.map((edge: any) => edge.node.quest)
    : data?.pickedForYouRecommendations;
  return { pickedForYouRecommendations, loading, error };
}

export default useGetPickedForYouQuests;
