import { type CSSProperties, useEffect, useState } from "react";
import { Slider } from "../ui/slider";
import { cn } from "libs/classMerger";
import Icons from "../icons/Icons";
import { useMediaPlayerContext } from "context/MediaPlayerV2Context";

interface MediaPlayerV2SeekBarProps {
  min?: number;
  max?: number;
  step?: number;
  progress?: number;
  showDuration?: boolean;
  onValueChange: (value: number[]) => void;
  className?: string;
  currentDuration?: string;
  totalDuration?: string;
  customStyle?: CSSProperties;
  soundscapeMode: boolean;
  fullScreenMode: boolean;
  audioPlayerRef: any;
}

const MediaPlayerV2SeekBar = ({
  showDuration,
  min = 0,
  max = 100,
  step = 1,
  progress = 0,
  className,
  currentDuration = "00:00",
  totalDuration = "00:00",
  onValueChange,
  soundscapeMode,
  fullScreenMode,
  audioPlayerRef,
}: MediaPlayerV2SeekBarProps) => {
  const { isTimerSet, time } = useMediaPlayerContext();
  const [lTime, setLTime] = useState({
    progress: 0,
    currentTime: {
      second: 0,
      minute: 0,
    },
    totalTime: {
      second: 0,
      minute: 0,
    },
  });

  // Update the progress bar as the media plays
  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioPlayerRef.current?.duration) {
        const progress = Math.floor(
          (audioPlayerRef.current.currentTime /
            audioPlayerRef.current.duration) *
            100
        );

        setLTime({
          progress: progress,
          currentTime: {
            second: Math.floor(audioPlayerRef.current.currentTime % 60),
            minute: Math.floor(audioPlayerRef.current.currentTime / 60),
          },
          totalTime: {
            second: Math.floor(audioPlayerRef.current.duration % 60),
            minute: Math.floor(audioPlayerRef.current.duration / 60),
          },
        });
      }
    };

    if (audioPlayerRef.current) {
      audioPlayerRef.current.ontimeupdate = handleTimeUpdate;
    }

    return () => {
      if (audioPlayerRef.current) {
        audioPlayerRef.current.ontimeupdate = null;
      }
    };
  }, [audioPlayerRef]);

  // Function to handle seeking (forward/backward)
  const handleSeek = (value: number[]) => {
    if (!audioPlayerRef.current) return;

    // Calculate the new time based on slider value
    const newTime = (audioPlayerRef.current.duration * value[0]) / 100;
    audioPlayerRef.current.currentTime = newTime;

    // Update the progress state to reflect the new time
    setLTime((prevTime) => ({
      ...prevTime,
      progress: value[0],
    }));
  };

  return (
    <div
      className={cn(
        "w-full flex items-center gap-4 z-20",
        fullScreenMode ? "" : "absolute t-0"
      )}
    >
      {showDuration ? (
        <div
          className={cn(
            "text-white caption-disclaimer w-[40px]",
            soundscapeMode ? "hidden" : "inline-flex"
          )}
        >
          {`${lTime.currentTime.minute}:${lTime.currentTime.second}`}
        </div>
      ) : null}
      <Slider
        min={min}
        max={max}
        step={step}
        onValueChange={handleSeek}
        value={[lTime.progress]}
        trackClassName=" bg-white/25 h-[3px]"
        className={cn("w-full h-[3px] group/volume", className)}
        rangeClassName="bg-red-500"
        thumbClassName="hidden group-hover/volume:block group-hover/volume:bg-red-500 w-2.5 h-2.5 border-0 hover:border-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
      />
      {showDuration ? (
        <div
          className={cn(
            "text-white caption-disclaimer text-right",
            soundscapeMode ? "w-4" : "w-[40px]"
          )}
        >
          {soundscapeMode ? (
            isTimerSet ? (
              `${time.totalTime.minute}:${time.totalTime.second}`
            ) : (
              <Icons
                name="infinity-filled"
                width={16}
                height={16}
                fill="white"
              />
            )
          ) : (
            `${lTime.totalTime.minute}:${lTime.totalTime.second}`
          )}
        </div>
      ) : null}
    </div>
  );
};

export default MediaPlayerV2SeekBar;
