import { useCallback, useEffect, useRef, useState } from "react";
import { useMediaPlayerContext } from "context/MediaPlayerV2Context";
import type { Track } from "/@types/player";
import debounce from "lodash/debounce";

export function useMediaPlayerHook() {
  const {
    soundscapeMode,
    audioPlayerRef,
    videoPlayerRef,
    mixerAudioPlayerRef,
    setIsPlaying,
    setShow,
    setToggleImmersion,
    immersionEnabled,
    setTime,
    time,
    volume,
    mixerVolume,
  } = useMediaPlayerContext();

  const waitForMediaReady = useCallback(
    async (mediaRef: React.RefObject<HTMLMediaElement>) => {
      return new Promise<void>((resolve, reject) => {
        const mediaElement = mediaRef.current;
        if (!mediaElement) {
          console.warn("Media element not found.");
          reject(new Error("Media element not found"));
          return;
        }

        const handleError = (e: Event) => {
          console.log("Media error occurred.");
          mediaElement.removeEventListener("canplay", handleCanPlay);
          mediaElement.removeEventListener("error", handleError);
          reject(
            new Error(`Media loading failed: ${(e as ErrorEvent).message}`)
          );
        };

        const handleCanPlay = () => {
          mediaElement.removeEventListener("canplay", handleCanPlay);
          mediaElement.removeEventListener("error", handleError);
          resolve();
        };

        mediaElement.addEventListener("canplay", handleCanPlay);
        mediaElement.addEventListener("error", handleError);

        // If media is already ready, resolve immediately
        if (mediaElement.readyState >= 3) {
          handleCanPlay();
        }
      });
    },
    []
  );

  const play = useCallback(async () => {
    try {
      // Wait for the audio player to be ready
      await waitForMediaReady(audioPlayerRef);

      // disable because mixer dont have api
      // If not in soundscape mode, wait for the mixer audio player to be ready
      //   if (soundscapeMode) {
      //     await waitForMediaReady(mixerAudioPlayerRef);
      //   }

      // Now play the media
      await audioPlayerRef.current?.play();
      //   if (soundscapeMode) {
      //     await mixerAudioPlayerRef.current?.play();
      //   }

      setIsPlaying(true);
      setShow(true);
    } catch (error) {
      console.error("Error playing media:", error);
    }
  }, [soundscapeMode, waitForMediaReady]);

  const pause = useCallback(async () => {
    try {
      audioPlayerRef.current?.pause();
      mixerAudioPlayerRef.current?.pause();
      setIsPlaying(false);
    } catch (error) {
      console.error("Error pausing media:", error);
    }
  }, []);

  const playTrack = useCallback(
    async (trackToPlay: Track) => {
      try {
        const audioElement = audioPlayerRef.current;
        if (!audioElement) {
          console.warn("Audio element not available.");
          return;
        }

        // Pause any currently playing media
        audioElement.pause();

        // Set the new source
        audioElement.src = trackToPlay.file;

        // Load the new media
        audioElement.load();

        // Wait for the media to be ready

        await waitForMediaReady(audioPlayerRef);

        // Now play the media
        await audioElement.play();

        setIsPlaying(true);
        setShow(true);
      } catch (error) {
        console.error("Error playing track:", error);
      }
    },
    [soundscapeMode, waitForMediaReady]
  );

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.querySelector("body")?.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const toggleImmersion = useCallback(() => {
    console.log("immersionEnabled", immersionEnabled);
    setToggleImmersion(!immersionEnabled);
    if (immersionEnabled) {
      videoPlayerRef.current?.play();
    } else {
      videoPlayerRef.current?.pause();
    }
  }, [immersionEnabled]);

  const handleRewind = useCallback(() => {
    const SECONDS_TO_REWIND = 15;
    const audioElement = audioPlayerRef.current;
    const mixerAudioPlayer = mixerAudioPlayerRef.current;

    if (
      audioElement &&
      Number.isFinite(audioElement.currentTime) &&
      Number.isFinite(audioElement.duration)
    ) {
      audioElement.currentTime = Math.max(
        audioElement.currentTime - SECONDS_TO_REWIND,
        0
      );
    }
    if (
      mixerAudioPlayer &&
      Number.isFinite(mixerAudioPlayer.currentTime) &&
      Number.isFinite(mixerAudioPlayer.duration)
    ) {
      mixerAudioPlayer.currentTime = Math.max(
        mixerAudioPlayer.currentTime - SECONDS_TO_REWIND,
        0
      );
    }
  }, []);

  const handleForward = useCallback(() => {
    const SECONDS_TO_FORWARD = 15;
    const audioElement = audioPlayerRef.current;
    const mixerAudioPlayer = mixerAudioPlayerRef.current;

    if (
      audioElement &&
      Number.isFinite(audioElement.currentTime) &&
      Number.isFinite(audioElement.duration)
    ) {
      audioElement.currentTime = Math.min(
        audioElement.currentTime + SECONDS_TO_FORWARD,
        audioElement.duration
      );
    }
    if (
      mixerAudioPlayer &&
      Number.isFinite(mixerAudioPlayer.currentTime) &&
      Number.isFinite(mixerAudioPlayer.duration)
    ) {
      mixerAudioPlayer.currentTime = Math.min(
        mixerAudioPlayer.currentTime + SECONDS_TO_FORWARD,
        mixerAudioPlayer.duration
      );
    }
  }, []);

  const handleSeek = useCallback(
    (value: number) => {
      if (!audioPlayerRef.current) return;

      const newTime = (audioPlayerRef.current.duration || 0) * (value / 100);
      audioPlayerRef.current.currentTime = newTime;

      if (mixerAudioPlayerRef.current) {
        const newMixerTime =
          (mixerAudioPlayerRef.current.duration || 0) * (value / 100);
        mixerAudioPlayerRef.current.currentTime = newMixerTime;
      }
      setTime({
        progress: value,
        currentTime: {
          second: Math.floor(newTime % 60),
          minute: Math.floor(newTime / 60),
        },
        totalTime: {
          second: Math.floor(audioPlayerRef.current.duration % 60),
          minute: Math.floor(audioPlayerRef.current.duration / 60),
        },
      });
    },
    [time]
  );

  const load = () => {
    audioPlayerRef.current?.load();
    mixerAudioPlayerRef.current?.load();
    setIsPlaying(false);
  };

  const debouncedPlay = useCallback(debounce(play, 100), [play]);
  const debouncedPause = useCallback(debounce(pause, 100), [pause]);

  useEffect(() => {
    return () => {
      debouncedPlay.cancel();
      debouncedPause.cancel();
    };
  }, [debouncedPlay, debouncedPause]);

  useEffect(() => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.volume = volume / 100;
    }
    if (mixerAudioPlayerRef.current) {
      mixerAudioPlayerRef.current.volume = mixerVolume / 100;
    }
  }, [volume, mixerVolume]);

  return {
    play,
    pause,
    playTrack,
    toggleFullScreen,
    toggleImmersion,
    handleRewind,
    handleForward,
    handleSeek,
    load,
  };
}
