import LockedModal from "components/LockedModal";
import { UserAccessStateEnum } from "context/userContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuestLandingPage } from "tabs/Quest/QuestLanding/QuestLandingPage";
import type { IQuest } from "types/interfaces";

export function QuestCardVariant1({
  quest,
  userAccessState,
}: {
  quest: IQuest;
  userAccessState: UserAccessStateEnum;
}) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClickQuestCard = (e: { preventDefault: () => void }) => {
    const url = quest?.url ? new URL(quest?.url).pathname : "/";
    e.preventDefault();
    if (userAccessState !== UserAccessStateEnum.Premium || UserAccessStateEnum.Freemium) {
      return setIsModalOpen(true);
    }

    navigate(url);
  };

  return (
    <>
      <QuestLandingPage
        questData={quest}
        trigger={
          <button type="button" className="size-full" onClick={handleClickQuestCard} onKeyDown={() => {}}>
            <div className="flex rounded-2xl relative overflow-hidden">
              <img
                alt={quest?.name}
                src={quest.headshotCoverAsset?.url}
                className="overflow-hidden transition ease-in-out duration-500 hover:scale-105 object-center rounded-3xl w-full aspect-[4/3] bg-brown-100"
              />

              <div
                className="rounded-2xl absolute bottom-0 left-0 w-full"
                style={{
                  background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)",
                }}
              >
                <div className="flex flex-col pt-4 px-6 pb-4 w-full gap-2 text-left">
                  <div className="heading-8 lowercase text-white/70">{quest.categories[0]?.name}</div>
                  <div className="w-full h-full">
                    <img
                      src={quest.wordmarkAsset?.url}
                      alt={quest.name}
                      className="object-cover max-h-14 max-w-[280px] lg:max-h-16 lg:max-w-[300px]"
                    />
                  </div>
                  <div className="title-10 text-cool-grey-300/90">with {quest.authors[0]?.name}</div>
                </div>
              </div>
            </div>
          </button>
        }
      />

      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal onCancel={() => setIsModalOpen(false)} open={isModalOpen} userAccessState={userAccessState} />
      </div>
    </>
  );
}
