import { Skeleton } from "@fluentui/react-components";
import { cn } from "libs/classMerger";

const FreeQuestSkeleton = () => {
    return (
        <Skeleton className="flex h-4 w-[250px] bg-brown-50 h-auto md:min-h-[480px] xl:h-[550px] w-full rounded-xl">
            <div className="flex p-20 gap-20 flex-col lg:flex-row overfow-hidden w-full">
                <div className="flex flex-col gap-3 w-2/4">
                    <Skeleton className="h-[25px] w-[110px] rounded-xl bg-brown-100 flex " />
                    <Skeleton className="h-8 bg-brown-100 h-8 rounded-xl" />
                    <Skeleton className="h-8 bg-brown-100 h-8 rounded-xl w-3/4" />
                    <Skeleton className="h-8 w-1/4 bg-brown-100 h-8 rounded-xl overflow-hidden" />
                    <div className="flex gap-1 flex-col w-inherit overflow-hidden">
                        <Skeleton className="h-4 rounded-xl bg-brown-100" />
                    </div>
                    <Skeleton className="h-10 w-[167px] rounded-full mt-4 bg-brown-100 hidden lg:flex" />
                </div>
                <div className="flex shrink-1 overflow-hidden gap-3 w-full">
                    {[...Array(2)].map((_, index) => (
                        <div className="flex flex-col gap-3 md:w-[419px]">
                            <Skeleton
                                className={cn("w-[395px] h-[222px] bg-brown-100 rounded-xl")}
                            />
                            <Skeleton className="h-3 w-3/4 rounded-xl bg-brown-100" />
                            <Skeleton className="h-2 w-2/4 rounded-xl bg-brown-100" />
                        </div>
                    ))}
                </div>
                <Skeleton className="h-10 w-[167px] rounded-xl bg-brown-100 flex lg:hidden" />
            </div>
        </Skeleton>
    );
};

export default FreeQuestSkeleton;
