import { cn } from "libs/classMerger";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import type { ICaption } from "types/interfaces";

interface MediaPlayerProps {
  mediaUrl?: string;
  thumbnailUrl?: string;
  autoplay?: boolean;
  muted?: boolean;
  looped?: boolean;
  showVControls?: boolean;
  showOpacity?: boolean;
  playerClasses?: string;
  videoClasses?: string;
  playOnHover?: boolean;
  pauseOnLeave?: boolean;
  autoplayVisible?: boolean;
  mediaType?: "video" | "audio";
  captions?: ICaption[];
}
export interface MediaPlayerHandle {
  play: () => Promise<void>;
  pause: () => void;
  mute: (isMuted: boolean) => void;
  toggleCaptions: (showCaptions: boolean) => void;
  enterFullScreen: () => void;
  getVideoElement: () => HTMLVideoElement | HTMLAudioElement | null;
}
export const MediaPlayer = forwardRef<MediaPlayerHandle, MediaPlayerProps>(
  (
    {
      mediaUrl,
      thumbnailUrl,
      autoplay = false,
      muted = false,
      looped = false,
      showVControls = true,
      showOpacity = false,
      playerClasses,
      videoClasses,
      pauseOnLeave = false,
      playOnHover = false,
      autoplayVisible = false,
      mediaType = "video",
      captions = [],
    }: MediaPlayerProps,
    ref,
  ) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mediaUrlFromParams = queryParams.get("mediaUrl");
    const thumbnailUrlFromParams = queryParams.get("thumbnailUrl");
    const mimeTypeFromParams = queryParams.get("mimeType");
    const mediaRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(autoplay && !autoplayVisible);

    // Expose play, pause, mute, caption toggle, and fullscreen functions
    useImperativeHandle(ref, () => ({
      play: async () => {
        if (mediaRef.current) {
          await mediaRef.current.load();
          await mediaRef.current.play();
        }
      },
      pause: () => mediaRef.current?.pause(),
      mute: (isMuted: boolean) => {
        if (mediaRef.current) {
          mediaRef.current.muted = isMuted;
        }
      },
      toggleCaptions: (showCaptions: boolean) => {
        const tracks = mediaRef.current?.textTracks;
        if (tracks?.[0]) {
          tracks[0].mode = showCaptions ? "showing" : "hidden";
        }
      },
      enterPipMode: () => {
        if (mediaRef.current?.requestFullscreen) {
          mediaRef.current.requestPictureInPicture().then(() => {});
        }
      },
      enterFullScreen: () => {
        if (mediaRef.current?.requestFullscreen) {
          mediaRef.current.requestFullscreen().then(() => {});
        }
      },
      exitFullScreen: () => {
        if (mediaRef.current?.requestFullscreen) {
          document.exitFullscreen().then();
        }
      },
      getVideoElement: () => mediaRef.current,
    }));

    const handleMouseLeave = () => {
      if (mediaRef.current && pauseOnLeave) {
        mediaRef.current.pause();
        setIsPlaying(false);
        mediaRef.current.load();
      }
    };

    const handleMouseEnter = () => {
      if (playOnHover && mediaRef.current) {
        mediaRef.current.play().then(() => {});
        setIsPlaying(true);
      }
    };

    useEffect(() => {
      const media = mediaRef.current;

      // Handle regular autoplay when autoplayVisible is false
      if (autoplay && !autoplayVisible && media) {
        media.play().catch(() => {});
        setIsPlaying(true);
      }

      // Handle autoplay when video is visible
      if (autoplayVisible && media) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              media.play().catch(() => {});
              setIsPlaying(true);
            } else {
              media.pause();
              setIsPlaying(false);
            }
          },
          { threshold: 0.5 },
        );

        observer.observe(media);

        return () => observer.unobserve(media);
      }
    }, [autoplay, autoplayVisible]);

    const extractedMediaType = mimeTypeFromParams ? mimeTypeFromParams.split("/")[0] : mediaType;
    const getCaptionUrl = (caption: ICaption) => {
      const urlObject = new URL(caption.url);
      return `/assets${urlObject.pathname}${urlObject.search}`;
    };

    return (
      <button
        className={cn("relative aspect-video mx-auto w-full", playerClasses)}
        type="button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        key={mediaUrlFromParams || mediaUrl}
      >
        <video
          ref={mediaRef}
          preload="none"
          src={mediaUrlFromParams || mediaUrl}
          poster={thumbnailUrlFromParams || thumbnailUrl}
          muted={muted}
          loop={looped}
          controls={showVControls}
          className={cn("w-full h-auto", videoClasses)}
          controlsList="nodownload"
          autoPlay={autoplay && !autoplayVisible} // Autoplay if not handling via visibility
        >
          Your browser does not support the {extractedMediaType} element.
          <source src={mediaUrlFromParams || mediaUrl} type={mimeTypeFromParams || "video/mp4"} />
          {captions.length > 0 &&
            captions.map((caption) => (
              <track
                key={caption.id}
                src={getCaptionUrl(caption)}
                kind="captions"
                srcLang={caption.language}
                label={caption.label}
              />
            ))}
        </video>
        {isPlaying && showOpacity && <div className="absolute inset-0 bg-[#0F131A] opacity-50 pointer-events-none" />}
      </button>
    );
  },
);
