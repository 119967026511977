import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import { GET_RECOMMENDED_QUESTS } from "services/graphql/queries/index";
import type { IQuest, IRecommendedQuestEdge } from "types/interfaces";

function useRecommendedQuests(first = 5) {
  const { isAuthenticated } = useUserState();
  const { loading, error, data } = useQuery(GET_RECOMMENDED_QUESTS, {
    skip: !isAuthenticated,
    variables: {
      first,
    },
  });

  const recommendedQuestsData: IQuest[] =
    data?.recommendedB2bQuests?.edges?.map((edge: IRecommendedQuestEdge) => edge.node.quest) || [];

  return { recommendedQuestsData, loading, error };
}

export default useRecommendedQuests;
