import ProgressBar from "components/ProgressBar";
import { Tooltip } from "components/Tooltip";
import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import { QuestLandingPage } from "tabs/Quest/QuestLanding/QuestLandingPage";
import type { IAssignedQuestNode, IQuest } from "types/interfaces";
import { formatDate } from "utils/date";

export function ContinueLearningSmallCard({
  item,
  questsAssigned,
  currentItem,
  // handleCardClick,
  index,
  handleContinueLearning,
}: {
  item: IQuest;
  questsAssigned?: IAssignedQuestNode[];
  currentItem: number;
  // handleCardClick: (item: IQuestNode) => void;
  index: number;
  handleContinueLearning: () => void;
}) {
  const questAssigned = questsAssigned?.find((questNode) => questNode.quest.id === item.id);

  return (
    <div
      className={cn(
        "flex-none w-[302px] xl:w-[354px] rounded-3xl overflow-hidden bg-black",
        index <= currentItem ? "opacity-0" : "",
      )}
    >
      <div className="relative">
        <img src={item.coverAsset?.url} alt="bg" className="object-cover" />
        {questAssigned && item?.userProgress && (
          <div className="absolute bottom-0 bg-black/40 w-full text-white py-1 px-4 flex justify-between">
            <div className="flex items-center gap-2">
              <div className="w-[14px] h-[14px]">
                {/* <CalenderReminder /> */}
                <Icons name="calendar-reminder-outlined" width={14} height={14} fill="black" />
              </div>
              <div className="title-10">Assigned to You</div>
            </div>
            <div className="body-2xs caption-disclaimer">Complete by {formatDate(item.courseEndedAt ?? "")}</div>
          </div>
        )}
      </div>

      <div className="flex items-center w-full p-4 gap-4">
        <div className=" w-full flex justify-end flex-col gap-[6px]">
          <ProgressBar
            containerClasses="mt-1 h-[3px]"
            progressColor="bg-yellow-500"
            value={
              item.type === "daily" ? item.userProgress?.totalDaysCompleted : item.userProgress?.totalLessonsCompleted
            }
            maxValue={item.type === "daily" ? item.userProgress?.totalDays : item.userProgress?.totalLessons}
          />
          <div className={"text-white/90 title-10"}>{`${
            item.type === "daily" ? item.userProgress?.totalDaysCompleted : item.userProgress?.totalLessonsCompleted
          } / ${
            item.type === "daily" ? item.userProgress?.totalDays : item.userProgress?.totalLessons
          } completed`}</div>
        </div>

        <div className="w-auto flex justify-end gap-1 items-center">
          <button
            type="button"
            onClick={handleContinueLearning}
            className="btn btn-primary whitespace-nowrap bg-red-600 rounded-full p-2 flex items-center gap-2 text-white  enable-click"
          >
            <Icons name="play-filled" width={14} height={14} fill="white" />
          </button>
          {/* <Link
                className="rounded-full border p-2 enable-click flex items-center justify-center"
                type="button"
                to={item?.url}
              >
                <Icons name="list-filled" width={14} height={14} fill="white" />
              </Link> */}
          <QuestLandingPage
            questData={item}
            trigger={
              <button type="button" className="rounded-lg enable-click">
                <Tooltip tooltipContent={<p className="caption-disclaimer">Learn more</p>}>
                  <div className="p-2 rounded-[128px] border border-black/10 justify-start items-center gap-2.5 inline-flex">
                    <div className="justify-center items-center flex">
                      <Icons name="list-filled" width={20} height={21} fill="#FFFFFFE5" />
                    </div>
                  </div>
                </Tooltip>
              </button>
            }
          />
        </div>
      </div>
    </div>
  );
}
