import { WorkwayLogoFull } from "components/logo/WorkwayLogo";
import { useNavigate } from "react-router-dom";

const ThankYouScreen: React.FC = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/"); // Replace '/' with the path to your homepage
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 text-gray-700">
      <div className="flex flex-col mt-40 gap-4  max-w-[680px] self-center">
        <div className="  flex flex-col items-center gap-y-4">
          <WorkwayLogoFull width="160" height="26" />
        </div>
        <p className="title-8">Thank you for contacting us. One of our team members will be in touch with you soon.</p>
        <button
          type="button"
          onClick={handleBackToHome}
          className="px-6 py-2 bg-black text-white w-fit self-center rounded-full button-text-medium hover:bg-gray-800"
        >
          Back to home
        </button>
        <p className="text-center caption-disclaimer text-[#979CA5]">© 2024 Workway by Mindvalley</p>
      </div>
    </div>
  );
};

export default ThankYouScreen;
