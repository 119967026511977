import { UserAccessStateEnum, useUserState } from "context/userContext";
import useAssignedQuests from "hooks/useAssignedQuests";
import useLibrary from "hooks/useLibrary";
import useUserProfileDetails from "hooks/useUserProfileDetails";
import { QuestStatus } from "types/interfaces";
import AssignedToYouSection from "./sections/AssignedToYouSection";
import ContinueLearningSection from "./sections/ContinueLearningSection";
import FeaturedMeditationList from "./sections/FeaturedMeditationList";
import FeaturedSoundsList from "./sections/FeaturedSoundsList";
import { FreeQuestsSection } from "./sections/FreeQuestsSection";
import { HeroSection } from "./sections/HeroSection";
import PickedForYouSection from "./sections/PickedForYouSection";
import RecommendedForYou from "./sections/RecommendedForYou";

export default function HomeTab() {
  const { userAccessState } = useUserState();

  const { profileData, loading: profileDataLoading } = useUserProfileDetails();
  const { questData: questsInProgress, loading: questsInProgressLoading } =
    useLibrary({
      first: 4,
      status: QuestStatus.InProgress,
    });
  const { questData: assignedQuests, loading: assignedQuestsLoading } =
    useAssignedQuests();
  return (
    <div className="flex flex-col space-y-16 pt-20">
      {!(
        questsInProgress.length > 0 &&
        userAccessState === UserAccessStateEnum.Premium
      ) && <HeroSection />}
      {userAccessState === UserAccessStateEnum.Premium && (
        <ContinueLearningSection
          loading={
            profileDataLoading ||
            questsInProgressLoading ||
            assignedQuestsLoading
          }
          profile={profileData}
          questsAssigned={assignedQuests}
          questsInProgress={questsInProgress}
        />
      )}
      {userAccessState === UserAccessStateEnum.Premium &&
        assignedQuests.length > 0 && (
          <AssignedToYouSection questsAssigned={assignedQuests} />
        )}
      <PickedForYouSection />
      {userAccessState === UserAccessStateEnum.Freemium && (
        <FreeQuestsSection />
      )}
      <FeaturedMeditationList />
      <FeaturedSoundsList />
      <RecommendedForYou />
    </div>
  );
}
