import { useEffect } from "react";
import { Slider } from "../ui/slider";
import { cn } from "libs/classMerger";
import Icons from "../icons/Icons";
import { Button } from "../Button";
import { Tooltip } from "../ui/tooltip";
import "components/MediaPlayer.css";
import {
  MediaPlayerV2Context,
  useMediaPlayerContext,
} from "context/MediaPlayerV2Context";

interface MediaPlayerV2VolumeControlProps {
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  customStyle?: React.CSSProperties;
  audioPlayerRef?: any;
}

const MediaPlayerV2VolumeControl = ({
  min = 0,
  max = 100,
  step = 1,
  className,
  audioPlayerRef,
}: MediaPlayerV2VolumeControlProps) => {
  // Access volume and mute functionality from the MediaPlayerV2Context
  const { volume, setVolume, isMuted, toggleMute } = useMediaPlayerContext();

  // Sync volume with the audio player whenever it changes
  useEffect(() => {
    if (audioPlayerRef?.current) {
      audioPlayerRef.current.volume = volume / 100;
    }
  }, [volume, audioPlayerRef]);

  // Handler for slider value change with drag functionality
  const onSliderValueChange = (value: number[]) => {
    const newVolume = value[0];
    setVolume(newVolume);

    // Adjust audio volume in real-time
    if (audioPlayerRef?.current) {
      audioPlayerRef.current.volume = newVolume / 100;
    }

    // Automatically handle mute/unmute during dragging
    if (newVolume > 0 && isMuted) {
      toggleMute(); // Unmute if volume is increased
    } else if (newVolume === 0 && !isMuted) {
      toggleMute(); // Mute if volume is set to 0
    }
  };

  return (
    <div className="w-full flex items-center gap-4">
      <Tooltip
        delayDuration={0}
        tooltipContent={
          <p className="caption-disclaimer">{isMuted ? "Unmute" : "Mute"}</p>
        }
      >
        <Button
          variant="icon"
          size="icon"
          className="text-white/70 hover:text-white"
          onClick={toggleMute}
        >
          {isMuted ? (
            <Icons name="volume-x-outlined" width={20} height={20} />
          ) : (
            <Icons name="volume-2-outlined" width={20} height={20} />
          )}
        </Button>
      </Tooltip>
      <Slider
        defaultValue={[volume]}
        min={min}
        max={max}
        step={step}
        onValueChange={onSliderValueChange} // Handles drag and drop updates
        value={[volume]}
        trackClassName="bg-white/25 h-[3px]"
        className={cn("w-full h-[3px] group/volume", className)}
        rangeClassName="bg-white group-hover/volume:bg-red-500"
        thumbClassName="hidden group-hover/volume:block group-hover/volume:bg-red-500 w-2.5 h-2.5 border-0 hover:border-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
      />
    </div>
  );
};

export default MediaPlayerV2VolumeControl;
