import { Carousel, CarouselContent, CarouselItem } from "components/Carousel";
import Icons from "components/icons/Icons";
import ContinueLearningSkeleton from "components/loading/ContinueLearningSkeleton";
import type { UseEmblaCarouselType } from "embla-carousel-react";
import useScreenType from "hooks/useScreenType";
import { useCallback, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import type { IAssignedQuestNode, ICurrentProfile, IQuest } from "types/interfaces";
import { cn } from "../../../libs/classMerger";
import { ContinueLearningBigCard } from "../components/continueLearning/ContinueLearningBigCard";
import { ContinueLearningNavigation } from "../components/continueLearning/ContinueLearningNavigation";
import { ContinueLearningSmallCard } from "../components/continueLearning/ContinueLearningSmallCard";

export interface ContinueLearningSectionProps {
  loading?: boolean;
  profile?: ICurrentProfile;
  questsAssigned?: IAssignedQuestNode[];
  questsInProgress?: IQuest[];
}

type CarouselApi = UseEmblaCarouselType[1];

const ContinueLearningSection = ({
  loading,
  profile,
  questsAssigned,
  questsInProgress = [],
}: ContinueLearningSectionProps) => {
  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState<number>(0);
  const carouselRef = useRef<any>(null);
  const setCarouselApi = useCallback((api: CarouselApi) => {
    carouselRef.current = api || null;
  }, []);

  const screenType = useScreenType();
  const [isMuted, setMuted] = useState(true);

  // function handleCardClick(clickedCourse: IQuestNode): void {
  //   const clickedIndex = questsInProgress.findIndex(
  //     (course: IQuestNode) => course.id === clickedCourse.id
  //   );

  //   if (clickedIndex === -1 || clickedIndex === currentItem) {
  //     return;
  //   }
  //   // carouselRef.current?.scrollTo(clickedIndex);
  //   setCurrentItem(clickedIndex);
  // }

  function nextPreviousArrowHandler(value: number) {
    const newIndex = Math.max(0, Math.min(currentItem + value, questsInProgress.length - 1)); // Ensure index stays within bounds

    if (value > 0 && newIndex > currentItem) {
      carouselRef.current?.scrollNext();
    } else if (value < 0 && newIndex < currentItem) {
      carouselRef.current?.scrollPrev();
    }

    setCurrentItem(newIndex);
  }

  const hasMultipleCourses = questsInProgress.length > 1;

  function handleContinue(item: IQuest) {
    navigate(`/quests/${item.language}/${item.slug}`);
  }

  if (loading) return <ContinueLearningSkeleton />;
  if (!questsInProgress || questsInProgress.length === 0) return <></>;

  return (
    <div className="container">
      <img src="/patterns/pattern-hero.svg" alt="" className="absolute right-0" />
      <div className="overflow-unset w-full">
        <div className="relative w-full flex flex-nowrap flex-col gap-6 md:pr-0">
          <div className="heading-3">Welcome {`${profile?.firstName}`}</div>
          <div className="flex w-full ">
            <div className={"flex flex-col gap-6 w-full"}>
              <div className="flex gap-1 md:gap-3 lg:gap-5">
                <div className="flex w-[398] md:w-[576px] lg:w-[628px] flex-shrink-0">
                  <ContinueLearningBigCard
                    item={questsInProgress[currentItem]}
                    questsAssigned={questsAssigned}
                    isMuted={isMuted}
                    setMuted={setMuted}
                    screenType={screenType}
                    handleContinueLearning={() => handleContinue(questsInProgress[currentItem])}
                  />
                </div>
                <div className="flex flex-col">
                  {hasMultipleCourses && (
                    <Carousel
                      className="disable-click"
                      opts={{
                        align: "start",
                        dragFree: false,
                      }}
                      setApi={setCarouselApi}
                    >
                      <CarouselContent ref={carouselRef} className="ml-0">
                        {questsInProgress
                          .map((item: IQuest, index) => (
                            <CarouselItem
                              key={item.id + item.name}
                              className="basis-auto max-w-md pl-2 md:pl-4 lg:pl-6"
                            >
                              <ContinueLearningSmallCard
                                item={item}
                                questsAssigned={questsAssigned}
                                currentItem={currentItem}
                                handleContinueLearning={() => handleContinue(item)}
                                index={index}
                              />
                            </CarouselItem>
                          ))
                          .slice(1)}
                        {questsInProgress.length === 2 && (
                          <Link to={NavigationEnum.Programs} className="enable-click cursor-pointer">
                            <div className="bg-white/60 rounded-2xl overflow-hidden relative  min-w-[392px] h-full flex flex-col items-start justify-between ">
                              <div className="px-4 flex flex-col items-start justify-center h-1/2">
                                <div className="flex items-center gap-4">
                                  <div className="text-2xl font-semibold">Discover more</div>
                                  <div className="border-2 border-black rounded-full mt-1">
                                    <Icons name="arrow-right" width={14} height={14} fill="black" />
                                  </div>
                                </div>
                                <div className="text-sm">programs to take your skills to the next level</div>
                                <div className="text-sm">programs to take your skills to the next level</div>
                              </div>
                              <div className="shape-image absolute right-0 w-1/3 h-full" />
                              <div className="w-full h-1/2 absolute bottom-0 card-background" />
                            </div>
                          </Link>
                        )}
                        {questsInProgress.length >= 4 && (
                          <CarouselItem>
                            <Link to={`${NavigationEnum.Library}/in-progress`} className="enable-click cursor-pointer">
                              <div className="bg-white/60 rounded-2xl overflow-hidden end-card relative max-w-[400px] min-w-[340px] h-full" />
                            </Link>
                          </CarouselItem>
                        )}
                        {/* for UI purposes */}
                        <CarouselItem>
                          <div className="min-w-[372px] py-10" />
                        </CarouselItem>
                        <CarouselItem>
                          <div className="min-w-[372px] py-10" />
                        </CarouselItem>
                      </CarouselContent>
                    </Carousel>
                  )}
                  <div
                    className={cn(
                      hasMultipleCourses ? "" : "flex items-center h-full justify-center",
                      "hidden lg:flex pl-9",
                    )}
                  >
                    <ContinueLearningNavigation
                      nextPerviousArrowHandler={nextPreviousArrowHandler}
                      currentItem={currentItem}
                      courses={questsInProgress}
                    />
                  </div>
                </div>
              </div>
              <div className="flex lg:hidden col-span-12">
                <ContinueLearningNavigation
                  nextPerviousArrowHandler={nextPreviousArrowHandler}
                  currentItem={currentItem}
                  courses={questsInProgress}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueLearningSection;
