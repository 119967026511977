import { microsoftAuthUrl } from "config/authConfig";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useNavigate } from "react-router-dom";
import { authLoginPopUp } from "utils/authHelper";

export function useMicrosoftLogin() {
  const { userAccessState } = useUserState();
  const navigate = useNavigate();

  async function handleLogin() {
    try {
      const accessToken = await authLoginPopUp(microsoftAuthUrl);

      if (!accessToken) return;

      // TODO:: THIS NOT WORKING DUE TO USER CONTEXT NOT INIITIALIZED YET
      userAccessState !== UserAccessStateEnum.Premium ? navigate("/companyForm") : navigate("/");

      window.location.reload();
    } catch (error) {
      console.warn("Error during login:", error);
    }
  }

  return handleLogin;
}
