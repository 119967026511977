export enum QueriesOperations {
  GET_CURRENT_ORGANIZATION = "getCurrentOrganization",
  GET_QUESTS_BY_CATEGORY = "getQuestsByCategory",
  GET_QUEST_BY_SLUG_AND_LANGUAGE_B2B = "getQuestBySlugAndLanguageB2b",
  GET_QUEST_BY_SLUG_AND_LANGUAGE = "getQuestBySlugAndLanguage",
  GET_QUEST_BY_ID = "getQuestById",
  GET_FREE_QUESTS = "getFreeQuests",
  GET_RECOMMEND_FOR_YOU = "getRecommendedForYou",
  GET_CURRENT_PROFILE = "getCurrentProfile",
  GET_PICKED_FOR_YOU_QUESTS = "getPickedForYouQuest",
  GET_PICKED_FOR_YOU_QUESTS_PUBLIC = "getPickedForYouQuestPublic",
  GET_TRENDING_GLOBALLY_QUESTS = "getTrendingGloballyQuests",
  GET_RECOMMENDED_QUESTS = "getRecommendedQuests",
  GET_LIBRARY = "getLibrary",
  GET_LANGUAGES = "getLanguages",
  GET_CATEGORIES = "getCategories",
  GET_QUESTS_ASSIGMENTS = "getQuestAssignments",
  GET_SOUNDSCAPES_OF_THE_WEEK = "getSoundscapesOfTheWeekPublic",
  GET_MEDITATION_OF_THE_DAY = "getMeditationOfTheDayPublic",
}
