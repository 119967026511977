import * as React from "react";
import {
  createContext,
  type ReactNode,
  useReducer,
  useRef,
  useCallback,
  useEffect,
} from "react";
import type {
  PlayerContextType,
  SoundscapeDuration,
  Time,
} from "/@types/player";

export const MediaPlayerV2Context = createContext<PlayerContextType | null>(
  null
);

type Track = {
  id: string;
  imagePath: string;
  title: string;
  author: string;
  file: string;
};

const initialState = {
  show: false,
  hide: false,
  fullScreenMode: false,
  soundscapeMode: false,
  isTimerSet: true,
  isPlaying: false,
  immersionEnabled: false,
  mixerEnabled: false,
  volume: 100,
  mixerVolume: 50,
  isMuted: false,
  soundscapeDuration: {
    hour: "",
    minutes: "",
  } as SoundscapeDuration,
  track: null as Track | null,
  mixerTrack: null as Track | null,
  time: {
    progress: 0,
    currentTime: {
      second: 0,
      minute: 0,
    },
    totalTime: {
      second: 0,
      minute: 0,
    },
  } as Time,
};

// Define action types
type Action =
  | { type: "SET_SHOW"; payload: boolean }
  | { type: "SET_HIDE"; payload: boolean }
  | { type: "SET_FULLSCREEN_MODE"; payload: boolean }
  | { type: "SET_SOUNDSCAPE_MODE"; payload: boolean }
  | { type: "SET_IS_TIMER_SET"; payload: boolean }
  | { type: "SET_IS_PLAYING"; payload: boolean }
  | { type: "SET_IMMERSION_ENABLED"; payload: boolean }
  | { type: "SET_MIXER_ENABLED"; payload: boolean }
  | { type: "SET_VOLUME"; payload: number }
  | { type: "SET_MIXER_VOLUME"; payload: number }
  | { type: "SET_IS_MUTED"; payload: boolean }
  | { type: "SET_SOUNDSCAPE_DURATION"; payload: SoundscapeDuration }
  | { type: "SET_TRACK"; payload: Track | null }
  | { type: "SET_MIXER_TRACK"; payload: Track | null }
  | { type: "SET_TIME"; payload: Time }
  | { type: "TOGGLE_IMMERSION"; payload: boolean }
  | { type: "TOGGLE_MUTE" };

const playerReducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case "SET_SHOW":
      return { ...state, show: action.payload };
    case "SET_HIDE":
      return { ...state, hide: action.payload };
    case "SET_FULLSCREEN_MODE":
      return { ...state, fullScreenMode: action.payload };
    case "SET_SOUNDSCAPE_MODE":
      return { ...state, soundscapeMode: action.payload };
    case "SET_IS_TIMER_SET":
      return { ...state, isTimerSet: action.payload };
    case "SET_IS_PLAYING":
      return { ...state, isPlaying: action.payload };
    case "SET_IMMERSION_ENABLED":
      return { ...state, immersionEnabled: action.payload };
    case "SET_MIXER_ENABLED":
      return { ...state, mixerEnabled: action.payload };
    case "SET_VOLUME":
      return { ...state, volume: action.payload };
    case "SET_MIXER_VOLUME":
      return { ...state, mixerVolume: action.payload };
    case "SET_IS_MUTED":
      return { ...state, isMuted: action.payload };
    case "SET_SOUNDSCAPE_DURATION":
      return { ...state, soundscapeDuration: action.payload };
    case "SET_TRACK":
      return { ...state, track: action.payload };
    case "SET_MIXER_TRACK":
      return { ...state, mixerTrack: action.payload };
    case "SET_TIME":
      return { ...state, time: action.payload };
    case "TOGGLE_IMMERSION":
      return { ...state, immersionEnabled: action.payload };
    case "TOGGLE_MUTE": {
      const isMuted = !state.isMuted;
      return {
        ...state,
        isMuted,
        volume: isMuted ? 0 : state.volume || 100,
        mixerVolume: isMuted ? 0 : state.mixerVolume || 50,
      };
    }
    default:
      return state;
  }
};

const PlayerContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(playerReducer, initialState);

  // Refs
  const audioPlayerRef = useRef<HTMLAudioElement>(null);
  const mixerAudioPlayerRef = useRef<HTMLAudioElement>(null);
  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const fullScreenPlayerRef = useRef<HTMLDivElement>(null);
  const seekBarRef = useRef<HTMLDivElement>(null);
  const seekBgRef = useRef<HTMLDivElement>(null);

  const toggleMute = useCallback(() => {
    dispatch({ type: "TOGGLE_MUTE" });
  }, []);

  const close = () => {
    audioPlayerRef.current?.pause();
    mixerAudioPlayerRef.current?.pause();
    videoPlayerRef.current?.pause();
    dispatch({ type: "SET_IS_PLAYING", payload: false });
    dispatch({ type: "SET_SHOW", payload: false });
    dispatch({ type: "SET_IMMERSION_ENABLED", payload: false });
    dispatch({ type: "SET_IS_TIMER_SET", payload: false });
  };

  useEffect(() => {
    const handleChange = () => {
      dispatch({
        type: "SET_FULLSCREEN_MODE",
        payload: !!document.fullscreenElement,
      });
    };
    document.body.addEventListener("fullscreenchange", handleChange);
    return () => {
      document.body.removeEventListener("fullscreenchange", handleChange);
    };
  }, []);

  useEffect(() => {
    const handleChange = () => {
      dispatch({
        type: "SET_FULLSCREEN_MODE",
        payload: !!document.fullscreenElement,
      });
    };
    document.body.addEventListener("fullscreenchange", handleChange);
    return () => {
      document.body.removeEventListener("fullscreenchange", handleChange);
    };
  }, []);

  const value: PlayerContextType = {
    ...state,
    audioPlayerRef,
    mixerAudioPlayerRef,
    videoPlayerRef,
    fullScreenPlayerRef,
    seekBarRef,
    seekBgRef,
    close,
    toggleMute,
    setTrack: (track: Track | null) =>
      dispatch({ type: "SET_TRACK", payload: track }),
    setMixerTrack: (track: Track | null) =>
      dispatch({ type: "SET_MIXER_TRACK", payload: track }),
    setVolume: (volume: number) =>
      dispatch({ type: "SET_VOLUME", payload: volume }),
    setMixerVolume: (volume: number) =>
      dispatch({ type: "SET_MIXER_VOLUME", payload: volume }),
    setSoundscapeMode: (mode: boolean) =>
      dispatch({ type: "SET_SOUNDSCAPE_MODE", payload: mode }),
    setTime: (time: Time) => dispatch({ type: "SET_TIME", payload: time }),
    setSoundscapeDuration: (duration: SoundscapeDuration) =>
      dispatch({ type: "SET_SOUNDSCAPE_DURATION", payload: duration }),
    setIsTimerSet: (isSet: boolean) =>
      dispatch({ type: "SET_IS_TIMER_SET", payload: isSet }),
    setMixerEnabled: (enabled: boolean) =>
      dispatch({ type: "SET_MIXER_ENABLED", payload: enabled }),
    setImmersionEnabled: (enabled: boolean) =>
      dispatch({ type: "SET_IMMERSION_ENABLED", payload: enabled }),
    setIsPlaying: (isPlaying: boolean) =>
      dispatch({ type: "SET_IS_PLAYING", payload: isPlaying }),
    setFullScreenMode: (fullScreen: boolean) =>
      dispatch({ type: "SET_FULLSCREEN_MODE", payload: fullScreen }),
    setShow: (show: boolean) => dispatch({ type: "SET_SHOW", payload: show }),
    setToggleImmersion: (enable) =>
      dispatch({ type: "TOGGLE_IMMERSION", payload: enable }),
  };

  return (
    <MediaPlayerV2Context.Provider value={value}>
      {children}
    </MediaPlayerV2Context.Provider>
  );
};

export const useMediaPlayerContext = (): PlayerContextType => {
  const context = React.useContext(MediaPlayerV2Context);
  if (!context) {
    throw new Error(
      "useMediaPlayer must be used within a MediaPlayerV2Provider"
    );
  }
  return context;
};

export default PlayerContextProvider;
